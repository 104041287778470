// Angular
import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

// SyncFusion
import { ColumnModel, ExcelFilterBase, GridComponent, PredicateModel } from '@syncfusion/ej2-angular-grids';

// Cerium
import { GridDesign } from './cer-grid.component';
import { CerGridCommandService, UiActionTypeEnum, UiCommandEvent, UiCommandSourceEnum } from './cer-grid-command.service';
import { Dialog } from '@syncfusion/ej2-angular-popups';
import { FieldMetadata } from '../cer-data/cer-data.service';

@Injectable()
export class CerGridFilterService implements OnDestroy {
  // Subscriptions
  private subscriptionManager: Subscription = new Subscription();

  // Grid edit
  private grid: GridComponent;
  private design: GridDesign;

  // Constructor
  constructor(command: CerGridCommandService) {
    this.subscriptionManager.add(command.commandPre$.subscribe(cmd => this.onCommandPre(cmd)));
  }

  ngOnDestroy(): void {
    this.subscriptionManager.unsubscribe();
  }

  public init(grid: GridComponent, design: GridDesign) {
    this.grid = grid;
    this.design = design;
    this.grid.filterSettings = { type: 'Excel' };
  }

  private onCommandPre(event: UiCommandEvent) {
    if (event.cancel) {
      return;
    }
    if (event.source == UiCommandSourceEnum.ActionBegin) {
      switch (event.actionType) {
        case UiActionTypeEnum.FilterChoiceRequest:
          this.onFilterChoiceRequest(event.args);
          break;
        case UiActionTypeEnum.FilterSearchBegin:
          this.onFilterSearchBegin(event.args);
          break;
        case UiActionTypeEnum.Filtering:
          this.onFiltering(event.args);
          break;
        case UiActionTypeEnum.BeforeOpenColumnChooser:
          this.onBeforeOpenColumnChooser(event.args);
          break;
        case UiActionTypeEnum.BeforeCloseColumnChooser:
          this.onBeforeCloseColumnChooser(event.args);
          break;
      }
    }
  }

  private onFilterSearchBegin(args: any) {
    this.filterSettingsSetup(args);
  }

  private onFiltering(args: any) {
  }

  private onFilterChoiceRequest(args: any) {
    var filterfields = [];
    var objFilter = Object.keys(args.filterModel.existingPredicate);
    for (var i = 0; i < objFilter.length; i++) {
      filterfields.push(objFilter[i]);
    }
    filterfields.push(args.filterModel.options.field);
    args.query.distincts = [];
    args.query.select(filterfields); // Created the select query
    if (args.filterModel && args.filterModel instanceof ExcelFilterBase) {
      var excel: ExcelFilterBase = <ExcelFilterBase>args.filterModel;
      this.filterSettingsSetup(args);
    }
  }

  private filterSettingsSetup(args: any) {
    if (args) {
      args.filterChoiceCount = 3000;  // TODO: Take from environment
      //console.log(args);
    }
  }

  public filterColumnSetOnClient(column: ColumnModel, meta: FieldMetadata, predicate = 'and') {
    if (meta.filterOperator && meta.filterValue != undefined && meta.filterOnClient == true) {
      //{ field: 'OrderDate', matchCase: false, operator: 'equal', predicate: 'or', value: new Date() }]
      if (meta.filterOperator == 'in') {
        if (meta.filterValue.length && meta.filterValue.length > 0) {
          var valueArray: any[] = meta.filterValue;
          valueArray.forEach(v => {
            var metaIn: FieldMetadata = Object.assign({}, meta);
            metaIn.filterOperator = 'equal';
            metaIn.filterValue = v;
            this.filterColumnSetOnClient(column, metaIn, 'or');
          });
        }
      }
      else {
        var p: PredicateModel =
        {
          field: meta.name,
          operator: meta.filterOperator,
          value: meta.filterValue,
          predicate: predicate
        };
        if (this.grid.filterSettings.columns == undefined) {
          this.grid.filterSettings.columns = [p];
        }
        else {
          this.grid.filterSettings.columns.push(p);
        }
      }
    }
  }

  /*****************
   * Column chooser
   *****************/

  private onBeforeOpenColumnChooser(args: any) {
    if (this.grid?.columnChooserModule) {
      var chooser: any = this.grid.columnChooserModule;
      if (chooser.dlgObj) {
        var dlg: Dialog = chooser.dlgObj;
        if (dlg) {
          dlg.allowDragging = true;
          var dlgObj: any = dlg;
          //dlgObj.allowMaxHeight = true; // TODO:? ?
          dlgObj.beforeOpen = this.onBeforeOpenColumnChooserDialog.bind(this);
          var dlgEle: HTMLElement = dlg.element;
          if (dlgEle) {
            dlgEle.style.top = "0px";
            //            dlgEle.style.height = "60%"
          }
        }
      }
    }
  }

  private onBeforeOpenColumnChooserDialog(args: any) {
    console.log(args);
  }

  private onBeforeCloseColumnChooser(args: any) {
    console.log("Closed");
    console.log(args);
  }
}
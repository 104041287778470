import { Component, Input, SimpleChanges, ViewChild, ChangeDetectionStrategy, ElementRef, ChangeDetectorRef, OnInit, AfterViewChecked, AfterViewInit } from '@angular/core'
import { ChatDto } from "../../app/api";
import { CerAppChatService } from './cer-app-chat.service';

@Component({
  selector: 'cer-app-chat',
  templateUrl: './cer-app-chat.component.html',
  styleUrls: ['./cer-app-chat.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CerAppChatComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @Input() chats: ChatDto[];
  @Input() refTableId: number;
  @Input() refRowId: number;

  private refRowIdCurr: number;
  private initialFocus = true;

  @ViewChild('chatAddTextArea') chatAddTextArea: ElementRef;
  @ViewChild('chatList') chatList: ElementRef;

  constructor(private chatService: CerAppChatService, private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.chatService.chats$.subscribe(chats => this.onChats(chats));;
  }

  ngAfterViewInit() {
    //this.setFocus();
    this.scrollToBottom();
  }

  ngAfterViewChecked() {
    //this.setFocus();
    this.scrollToBottom();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.refRowId != this.refRowIdCurr) {
      this.refRowIdCurr = this.refRowId;
      this.initialFocus = true;
    }
  }

  private onChats(chats: ChatDto[]) {
    if (chats.length > 0 || this.chats == null || this.chats.length == 0) {
      if (this.refTableId && this.refRowId && chats.length > 0) {
        if (chats[0].refTableId == this.refTableId && chats[0].refRowId == this.refRowId) {
          var isChanged: boolean = this.chatService.isChatsChanged(this.chats, chats, this.refTableId, this.refRowId);
          if (isChanged) {
            this.chats = chats;
            this.refresh();
          }
        }
      }
    }
  }

  public refresh() {
    this.cdr.detectChanges();
  }

  public scrollToBottom() {
    if (this.chatList?.nativeElement) {
      this.chatList.nativeElement.scrollTop = this.chatList.nativeElement.scrollHeight;
    }
  }

  public setFocus() {
    if (this.chatAddTextArea && this.chatAddTextArea.nativeElement) {
      this.chatAddTextArea.nativeElement.focus();
    }
    if (this.initialFocus) {
      this.initialFocus = false;
      if (this.refTableId && this.refRowId) {
        this.chatService.chatMarkRead(this.refTableId, this.refRowId);
      }
    }

  }

  // Add chat
  public addChatBtnClick() {
    let txt = this.chatAddTextArea.nativeElement.value;
    this.chatAddTextArea.nativeElement.value = "";
    while (txt.length > 0 && txt[txt.length - 1] == '\n') {
      txt = txt.slice(0, -1);
    }
    if (txt.length > 0) {
      this.chatService.chatPost(txt, this.refTableId, this.refRowId, this.chats).then(chats => {
        this.scrollToBottom();
      });
    }
  }


  // Chat UI
  public chatListItemClass(chat: ChatDto, idx: number): string {
    return this.chatService.chatUIListItemClass(chat, idx, this.chats);
  }

  public chatIsOwn(chat: ChatDto): boolean {
    return this.chatService.chatUIIsOwn(chat);
  }

  public chatUserAvatar(chat: ChatDto): string {
    return this.chatService.chatUIAvatar(chat);
  }

  public chatUserName(chat: ChatDto): string {
    return this.chatService.userNameByChatCreatedBy(chat);
  }

  public chatShowCreator(idx: number): boolean {
    return this.chatService.chatUIShowCreatedBy(idx, this.chats);
  }

  public chatShowDate(chat: ChatDto, idx: number): string {
    return this.chatService.chatUIShowDate(chat, idx, this.chats);
  }

}

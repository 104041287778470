<cer-form #form [panes]="panes">
  <ng-template #mainTemplate>
    <cer-grid
      #mainGrid
      height="100%"
      width="100%"
      [viewMetadata]="viewMetadata"
      [fieldMetadata]="fieldMetadata"
      [toolbarCommands]="toolbarCommands"
      [contextMenuCommands]="contextMenuCommands"
      dataRelationRole="parent"
    ></cer-grid>
  </ng-template>
  <ng-template #detailTemplate>
    <cer-grid
      #detailGrid
      height="100%"
      width="100%"
      [viewMetadata]="logViewMetadata"
      [fieldMetadata]="logFieldMetadata"
      mode="simpleToolbar"
      dataRelationRole="child"
    ></cer-grid>
  </ng-template>
</cer-form>

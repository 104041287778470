// Angular
import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

// SyncFusion
import { GridComponent } from '@syncfusion/ej2-angular-grids';

// Cerium
import { GridDesign } from './cer-grid.component';
import { CerGridCommandService, UiActionTypeEnum, UiCommandEvent, UiCommandSourceEnum } from './cer-grid-command.service';

@Injectable()
export class CerGridVirtualizeService implements OnDestroy {
  // Subscriptions
  private subscriptionManager: Subscription = new Subscription();

  // Grid edit
  public grid: GridComponent;
  public design: GridDesign;

  // Constructor
  constructor(private command: CerGridCommandService) {
    this.subscriptionManager.add(command.commandPre$.subscribe(cmd => this.onCommandPre(cmd)));
  }

  ngOnDestroy(): void {
    this.subscriptionManager.unsubscribe();
  }

  public init(grid: GridComponent, design: GridDesign) {
    this.grid = grid;
    this.design = design;
  }

  public onCommandPre(event: UiCommandEvent) {
    if (!this.design.virtualPaging) {
      return;
    }
    switch (event.source) {
      case UiCommandSourceEnum.Command:
        switch (event.actionType) {
          case UiActionTypeEnum.Reflow:
            this.doReflow();
            break;
        }
        break;
      case UiCommandSourceEnum.ActionBegin:
        switch (event.actionType) {
          case UiActionTypeEnum.DataBound:
            this.onDataBoundActionBegin(event.args);
            break;
          case UiActionTypeEnum.VirtualScroll:
            this.onVirtualScrollActionBegin(event.args);
            break;
        }
        break;
      case UiCommandSourceEnum.ActionComplete:
        switch (event.actionType) {
          case UiActionTypeEnum.Created:
            this.doReflow();
            break;
          case UiActionTypeEnum.VirtualScroll:
            this.onVirtualScrollActionComplete(event.args);
            break;
        }
        break;
    }
  }

  private onDataBoundActionBegin(args: any) {
    if (args.action !== 'edit') {
      this.virtualDataSetup(args);
    }
  }

  private onVirtualScrollActionBegin(args: any) {
  }

  private onVirtualScrollActionComplete(args: any) {
  }


  private doReflow() {
    if (this.grid && this.design?.useMaxHeight) {
      this.setupMaxHeight();
    }
  }

  private virtualDataSetup(args: any) {
    var rowCount : number = args.count;
    var pageIndex: number = 0;
    var pageSize : number = 0;
  
    var q = args?.query?.queries;
    if (q?.length > 0) {
      var f = args.query.queries.find((f: any) => f.fn == 'onPage' && f.e?.pageIndex > 0 && f.e?.pageSize > 0);
      if (f) {
        pageIndex = f.e.pageIndex;
        pageSize = f.e.pageSize;
      }
    }
    //this.debug('records read: ' + pageSize + '*' + pageIndex + ' total ' + rowCount);
    this.command.toolbarTitleUpdateCount(pageSize * pageIndex, rowCount);
  }

  private setupMaxHeight() {
    var exclude: number = 42 /* Toolbar */ + 37.6 /* Header */ + 1 /* BORDER */  + 5 /* UPGRADE SPECIAL */;
    if (this.grid.aggregates?.length > 0) {
      exclude += 36;
    }
    if (this.grid.groupSettings?.showDropArea) {
      exclude += 48;
    }
    if (this.grid.element) {
      var h = this.grid.element.parentElement.clientHeight - exclude; // 114 = height toolbar + header + footer
      h = Math.trunc(h);
      //this.debug("Calculated max height: " + h);
      var hpx: string = h + "px";
      if (this.grid.height != hpx) {
        this.grid.height = h + "px";
        this.grid.element.style.height = this.grid.height;
      }
      var panel = <HTMLDivElement>this.grid.contentModule?.getPanel();
      if (panel) {
        // Set Height Content
        if (panel.style.height != hpx) {
          panel.style.height = h + 'px';
          //this.debug("Panel height: " + panel.style.height);
        }

        var virtualtables = panel.getElementsByClassName('e-virtualtable');
        if (virtualtables?.length) {
          var virtualTable = <HTMLDivElement>virtualtables[0];
          if (virtualTable) {
            var hpxVT = panel.clientHeight + 'px';
            if (panel && virtualTable.clientWidth > panel.clientWidth) {
              // Deduct height of visible scrollbar-x from virtual table to avoid unneeded scrollbar-y
              var barHeight: number = 18; //.offsetHeight - panel.clientHeight;
              hpxVT = (panel.clientHeight - barHeight) + 'px';
            }
            if (virtualTable.style.minHeight != hpxVT) {
              virtualTable.style.minHeight = hpxVT;
              //this.debug("VT min height: " + hpxVT);
            }
          }

          // Virtual track
          var virtualtracks = panel.getElementsByClassName('e-virtualtrack');
          if (virtualtracks?.length) {
            var virtualtrack = <HTMLDivElement>virtualtracks[0];
            if (virtualtrack.clientHeight > panel.clientHeight) {
              virtualtrack.style.height = panel.style.height;
              //this.debug("Track height: " + virtualtrack.style.height);
            }
          }
        }
      }
    }
  }

}
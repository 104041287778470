import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ChatDto, NotificationMessageDto, UserDto } from 'src/app/api';
import { CerAppChatService } from '../cer-app-chat/cer-app-chat.service';
import { AppUsersService } from 'src/platform/app-user/app-users.service';
import { UserSessionService } from 'src/platform/app-user/user-session-service';

@Injectable({
  providedIn: 'root'
})
export class CerAppNotificationService implements OnDestroy {

  private subscriptionManager$: Subscription = new Subscription();

  public userNotifications$ = new BehaviorSubject<NotificationMessageDto[]>(null);

  constructor(chatService: CerAppChatService,
    private usersService: AppUsersService,
    private userSessionService: UserSessionService) {

    this.subscriptionManager$.add(
      this.userSessionService.userSession$.subscribe(user => this.init(user?.vm?.notificationMessages))
    );

    this.subscriptionManager$.add(
      chatService.chats$.subscribe(chats => this.userNotificationsAddFromChats(this.userNotifications$.value, chats))
    );
  }

  public ngOnDestroy(): void {
    this.subscriptionManager$.unsubscribe();
  }

  public init(notificationMessages: NotificationMessageDto[]) {
    this.prepareNotifications(notificationMessages, true);
    this.userNotifications$.next(notificationMessages);
  }

  public userNotificationsAddFromChats(messages: NotificationMessageDto[], chats: ChatDto[]) {
    chats.forEach(chat => this.userNotificationsAddFromChat(messages, chat));
  }

  private userNotificationsAddFromChat(messages: NotificationMessageDto[], chat: ChatDto) {
    var userId: number = this.userSessionService.userId();
    if (userId && chat.id && chat.chatUsers && messages) {
      var chatUser = chat.chatUsers.find(cu => cu.userId == userId);
      if (chatUser) {
        var message = messages.find(m => m.chatId == chat.id);
        if (message) {
          var chatUser = chat.chatUsers.find(cu => cu.userId == userId);
          message.isRead = chatUser.isRead;
          message.isNotified = chatUser.isRead; // TODO: Isnotified!!!
        }
        else {
          message = new NotificationMessageDto();
          message.txt = chat.txt;
          message.chatId = chat.id;
          message.userId = chatUser.userId;
          message.fromUserId = chatUser.createdBy;
          message.refTableId = chat.refTableId;
          message.refRowId = chat.refRowId;
          message.isRead = chatUser.isRead;
          message.isNotified = chatUser.isRead;
          message.created = new Date(chat.created);
          message.createdBy = chat.createdBy;
          message.modified = new Date(chat.modified);
          message.modifiedBy = chat.modifiedBy;
          var user: UserDto = this.getUserById(message.userId);
          if (user) {
            message.userName = user.name;
            message.userShortName = user.shortName;
          }
          message.fromUserId = chat.createdBy;
          var user: UserDto = this.getUserById(message.fromUserId);
          if (user) {
            message.fromUserName = user.name;
            message.fromShortName = user.shortName;
          }
          this.prepareNotification(message, false);
          messages.unshift(message);
        }
      }
      this.userNotifications$.next(messages);
    }
  }

  private getUserById(userId: number): UserDto {
    return this.usersService.userById(userId);
  }

  public prepareNotifications(messages: NotificationMessageDto[], adjustDateType : boolean) {
    messages?.forEach(msg => this.prepareNotification(msg, adjustDateType));
  }

  private prepareNotification(msg: NotificationMessageDto, adjustDateType : boolean = true) {
    if (msg.refTableId == 1) {
      msg.url = encodeURI('/voucher/chat/' + String(msg.refRowId));
    }
    if (adjustDateType) {
      msg.created = new Date(msg.created);
      msg.modified = new Date(msg.modified);
    }
  }

  /*
  private convertDateToUTC(date: Date): Date {
    if (date != null) {
      date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    }
    return date;
  }*/
}

import { CerAppFormModelAdapter } from '../../../platform/models/cer-app-form-model-adapter';

export class SalesChannelFormAdapter extends CerAppFormModelAdapter {

  override init() {

    this.viewMetadata = {
      name: 'salg-kanaler', dataApiName: 'SalesChannel', text: 'Salgskanaler', textSingular: 'Salgskanal',
      primaryKey: 'num', baseFields: ['id', 'num', 'name', 'history']
    };

    this.fieldMetadata = [
    ];
   
  }
}

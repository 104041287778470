import { FieldEditChangedArgs, FieldFormatEnum } from '../../../cer/cer-data/cer-data.service';
import { CerAppFormModelAdapter } from '../../../platform/models/cer-app-form-model-adapter';
import { UiCommandEvent } from '../../../cer/cer-grid/cer-grid-command.service';

export class ProductBOMFormAdapter extends CerAppFormModelAdapter {

    constructor() {
        super();

        this.viewMetadata = {
            name: 'udgivelse-bogpakker', dataApiName: 'ProductBOM', text: 'Bogpakker', textSingular: 'Bogpakke',
            primaryKey: 'num', baseFields: ['id', 'num', 'name', 'history'], titleFields: ['num', 'name']
        };

        this.fieldMetadata =
            [
                {
                    name: 'num', text: 'ISBN bogpakke', tooltipText: 'ISBN på bogpakken', allowEdit: false, allowEditOnCreate: true, required: true
                    //,
                    /*foreignKeyTableName: 'ProductLegacy', foreignKeyField: 'num', foreignKeyValue: 'num', foreignKeyAllowFiltering: true,
                    foreignKeyColumns: ['num', 'name','type'], foreignKeyTitles: ['ISBN', 'Titel','Type'],
                    //foreignKeyFilterFields: ['productType'], foreignKeyFilterOperators: ['equal'], foreignKeyFilterValues: ['Bogpakke'],
                    foreignKeySearchFields: ['num', 'name'], foreignKeySearchOperators: ['contains', 'contains'],
                    foreignKeySearchOnEmptyFocus: true,
                    onEditChanged: (args) => { this.editNum(args) }*/
                },
                { name: 'name', text: 'Titel', required: true },
            ];

        this.detailViewMetadata = {
            name: 'udgivelse-bogpakke-linjer', dataApiName: 'ProductBOMLine', text: 'Bogpakke indhold',
            primaryKey: 'id', parentPrimaryKey: 'num', parentForeignKey: 'productBOM', baseFields: ['id', 'history'], titleFields: ['product', 'name']
        };

        this.detailFieldGroupMetadata = [
            { idx: 1, tabIdx: 0, name: 'identification', text: 'Identifikation', fields: ['id', 'product', 'num', 'name'], width: 240 },
            { idx: 2, tabIdx: 0, name: 'period', text: 'Periode aktiv', fields: ['fromDate', 'toDate'] },
          ];
        
        

        this.detailFieldMetadata =
            [
                {
                    name: 'productBOM', text: 'ISBN bogpakke', tooltipText: 'ISBN på bogpakken', allowEdit: false, allowEditOnCreate: true, required: true, visible: false, orderBy: 'Ascending', orderByPriority: 1,
                    foreignKeyTableName: 'ProductBOM', foreignKeyTableFilterName: 'BOM', foreignKeyField: 'num', foreignKeyValue: 'num', foreignKeyAllowFiltering: true,
                    foreignKeyColumns: ['num', 'name', 'type'], foreignKeyTitles: ['ISBN', 'Titel', 'Type'],
                    foreignKeySearchFields: ['num', 'name'], foreignKeySearchOperators: ['contains', 'contains'],
                    foreignKeySearchOnEmptyFocus: true
                },
                {
                    name: 'product', text: 'ISBN indeholdt', tooltipText: 'ISBN på indhold i bogpakken', allowEdit: false, allowEditOnCreate: true, required: true, orderBy: 'Ascending', orderByPriority: 2
                    //,
                  /*  foreignKeyTableName: 'ProductLegacy', foreignKeyTableFilterName: 'BOMLine', foreignKeyField: 'num', foreignKeyValue: 'num', foreignKeyAllowFiltering: true,
                    foreignKeyColumns: ['num', 'name', 'type'], foreignKeyTitles: ['ISBN', 'Titel', 'Type'],
                    foreignKeySearchFields: ['num', 'name'], foreignKeySearchOperators: ['contains', 'contains'],
                    foreignKeyFilterFields: ['type'], foreignKeyFilterOperators: ['contains'], foreignKeyFilterValues: ['Trykt'],
                    foreignKeySearchOnEmptyFocus: true,
                    onEditChanged: (args) => { this.editProduct(args) }*/
                },
                { name: 'name', text: 'Titel', required: true },
                { name: 'qty', text: 'Antal', format: FieldFormatEnum.Number, required: true, defaultValue: 1 },
                { name: 'fromDate', text: 'Fra dato', format: FieldFormatEnum.DateShort },
                { name: 'toDate', text: 'Til dato', format: FieldFormatEnum.DateShort }
            ];

        this.rightPaneWidthPct = 50;
    }

    private editNum(args: FieldEditChangedArgs) {
        var product: any = args.itemData;
        if (product) {
            var nameValue: string = product.name;
            var e = this.grid.editService;
            var nameInput = e.fieldInput('name');
            if (nameInput && (nameValue?.length > 0)) {
                e.inputValueSet(nameInput, nameValue);
            }
        }
    }

    private editProduct(args: FieldEditChangedArgs) {
        var product: any = args.itemData;
        if (product) {
            var nameValue: string = product.name;
            var e = this.detailGrid.editService;
            var nameInput = e.fieldInput('name');
            if (nameInput && (nameValue?.length > 0)) {
                e.inputValueSet(nameInput, nameValue);
            }
        }
    }

    override onCommand(event: UiCommandEvent) {
    }

    override onDetailCommand(event: UiCommandEvent) {
    }

}

import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

import { ColumnModel } from '@syncfusion/ej2-angular-grids';
import { Query, Predicate } from '@syncfusion/ej2-data';
import { Dialog } from '@syncfusion/ej2-popups';

import { INextStep } from 'src/cer/cer-stepper/cer-stepper.component';

import { uiCmdBottomShow, uiCmdBottomClose, uiCmdBottomToggle, uiCmdPaneRightClose, uiCmdPaneRightToggle } from '../../cer/cer-form/cer-form-panes.service';
import { CerFormService } from 'src/cer/cer-form/cer-form.service';
import { CerDialogService } from 'src/cer/cer-dialog/cer-dialog.service';
import {
  FieldEditChangedArgs, FieldFormatEnum, FieldGroupMetadata,
  FieldMetadata, FieldWidthEnum, TabMetadata, ViewMetadata
} from '../../cer/cer-data/cer-data.service';
import { CerGridComponent } from '../../cer/cer-grid/cer-grid.component';
import { UserSessionService } from 'src/platform/app-user/user-session-service';
import { UiActionTypeEnum, UiCommandEvent, UiCommandSourceEnum, UiCommand, UiKeyboardShortcut } from '../../cer/cer-grid/cer-grid-command.service';

import { AfregningstabelDto } from '../api';
import { ProductService } from './product.service';
import { DropDownList } from '@syncfusion/ej2-angular-dropdowns';
import { Subscription } from 'rxjs';
import { CerFormPanesSettings } from 'src/cer/cer-form/cer-form-panes.service';
import { CerFormComponent } from 'src/cer/cer-form/cer-form.component';

@Component({
  selector: 'product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ProductComponent implements OnInit {

  // Get form
  private form: CerFormComponent;
  @ViewChild(CerFormComponent) set setForm(content: CerFormComponent) {
    if (content && !this.form) {
      this.form = content;
      this.manage(this.form.formService.formCommand$.subscribe(e => this.onCommand(e)));
    }
  }
  
  grid: CerGridComponent;
  @ViewChild('grid', { static: false }) set gridContent(content: CerGridComponent) {
    if (content && !this.grid) {
      this.grid = content;
    }
  }

  protected panes: CerFormPanesSettings = {
    'main': { visible: 'show', size: '70%', sizeSecondary: '70%' },
    'right': { visible: 'collapse', size: '30%' },
    'bottom': { visible: 'collapse', size: '30%' },
    orientationPrimary: 'vertical'
  };

  public viewMetadata: ViewMetadata = {
    name: 'udgivelser', dataApiName: 'Product', dataApiParams: ['$includeContractNavigation', '$includeSubProductNavigation', '$includeResponsibleNavigation',],
    text: 'Udgivelser', textSingular: 'Udgivelse', titleFields: ['num', 'nameWorking'],
    primaryKey: 'num', baseFields: ['id', 'history']
  };

  public tabMetadata: TabMetadata[] = null; /*
  [
   { idx: 0, name: 'general', text: 'Generelt', icon: 'folder' },
   { idx: 1, name: 'meta', text: 'Salgsark', icon: 'art_track' },
  ];
  */

  public fieldGroupMetadata: FieldGroupMetadata[] = [
    { idx: 1, tabIdx: 0, name: 'identification', text: 'Titel', fields: ['id', 'num', 'nameWorking', 'name', 'authorOriginal'], width: 240 },
    { idx: 2, tabIdx: 0, name: 'setup', text: ' ', fields: ['type', 'responsible', 'category'] },
    { idx: 3, tabIdx: 0, name: 'contract', text: 'Aftale', fields: ['requestContractCreate', 'contractNavigation.type', 'contractNavigation.currency', 'contract'] },
    { idx: 4, tabIdx: 0, name: 'status', text: 'Status', fields: ['status', 'salesPrice', 'published', 'closed'] },
    { idx: 5, tabIdx: 0, name: 'meta', text: 'Salgsark', fields: ['language', 'languageOriginal', 'group', 'theme'] },
    { idx: 6, tabIdx: 0, name: 'references', text: 'Referencer', fields: ['projectNum', 'itemNum', 'subProductPrimary.num', 'subProductDigital.create', 'subProductDigital.num', 'subProductAudio.create', 'subProductAudio.num'] }
  ];

  public fieldMetadata: FieldMetadata[] =
    [{ name: 'num', text: 'ISBN', tooltipText: 'ISBN nummer', widthType: FieldWidthEnum.XXL, visibleAdd: false, allowEdit: false, filterOperator: 'startswith' },
    { name: 'projectNum', text: 'Projekt', tooltipText: 'Projektnummer', widthType: FieldWidthEnum.M, visibleAdd: false, visibleEdit: true, allowEdit: false, orderBy: 'Descending' },
    { name: 'name', text: 'Titel', tooltipText: 'Titel på udgivelsen', widthType: FieldWidthEnum.XXL },
    {
      name: 'nameWorking', text: 'Arb.titel/Org.titel', tooltipText: 'Arbejdstitel/Originaltitel', required: true, widthType: FieldWidthEnum.XXL,
      onEditChanged: (args) => { this.onEditChangedNameWorking(args) }
    },
    { name: 'authorOriginal', text: 'Forfatter', tooltipText: 'Oprindelig forfatter', widthType: FieldWidthEnum.XXL, visible: false },
    { name: 'responsible', text: 'Redaktør', tooltipText: 'Redaktørnavn', widthType: FieldWidthEnum.XS, allowSearching: true, foreignKeyTableName: 'User', foreignKeyField: 'id', foreignKeyValue: 'shortName', required: true },
    {
      name: 'type', text: 'Type', required: true, tooltipText: 'Udgivelsesformat', widthType: FieldWidthEnum.S, allowSearching: false, foreignKeyTableName: 'ProductType',
      foreignKeyField: 'num', foreignKeyValue: 'name',
      foreignKeySortFields: ['sorting'], onEditChanged: (args) => { this.onEditChangedType(args) }
    },
    { name: 'category', text: 'Kategori', tooltipText: 'Intern kategori', widthType: FieldWidthEnum.XXL, allowSearching: false, foreignKeyTableName: 'ProductCategory', foreignKeyField: 'num', foreignKeyValue: 'search', foreignKeySortFields: ['sorting'], visible: false },
    { name: 'group', text: 'Varegruppe', tooltipText: 'Boghandler varegruppe', widthType: FieldWidthEnum.XXL, allowSearching: false, foreignKeyTableName: 'ProductGroup', foreignKeyField: 'num', foreignKeyValue: 'search', foreignKeyAllowFiltering: true, visible: false },
    { name: 'theme', text: 'Temakode', tooltipText: 'EDItEUR temakode', widthType: FieldWidthEnum.XXL, allowSearching: false, foreignKeyTableName: 'ProductTheme', foreignKeyField: 'num', foreignKeyValue: 'search', foreignKeyColumns: ['search', 'description'], foreignKeyTitles: ['Temakode', 'Beskrivelse'], foreignKeyAllowFiltering: true, filterOperator: 'startswith', visible: false },
    { name: 'status', text: 'Status', tooltipText: 'Udgivelsesstatus', widthType: FieldWidthEnum.S, allowSearching: false, foreignKeyTableName: 'ProductStatus', foreignKeyField: 'num', foreignKeyValue: 'name', foreignKeySortFields: ['sorting'], defaultValue: 'Oprettet', visible: false, visibleAdd: false, visibleEdit: true },
    {
      name: 'requestContractCreate', text: 'Opret ny kontrakt?', tooltipText: 'Skal der oprettes et ny kontrakt?', defaultValue: true, allowSearching: false, visible: false, visibleAdd: true, visibleEdit: true, allowColumnChooser: false, format: FieldFormatEnum.CheckBox,
      onEditChanged: (args) => { this.onEditChangedRequestContractCreate(args) }
    },
    {
      name: 'contractNavigation.type', text: 'Aftaletype', tooltipText: 'Kontraktens aftaletype', allowEdit: true, widthType: FieldWidthEnum.XS, allowSearching: false, foreignKeyTableName: 'ContractType', foreignKeyField: 'num', foreignKeyValue: 'name', foreignKeySortFields: ['sorting'],
      onEditChanged: (args) => { this.onEditChangedContractType(args) }
    },
    {
      name: 'contract', text: 'Kontrakt', tooltipText: 'Kontrakt nummer', widthType: FieldWidthEnum.XS, foreignKeyTableName: 'Contract', allowSearching: false, filterOperator: 'startswith', foreignKeyField: 'num', foreignKeyValue: 'num', foreignKeyColumns: ['num', 'name'], foreignKeyTitles: ['Nr', 'Navn'], foreignKeyAllowFiltering: true, foreignKeyFromNavigation: true,
      onEditChanged: (args) => { this.onEditChangedContract(args) }
    },
    { name: 'contractNavigation.currency', text: 'Aftalevaluta', tooltipText: 'Kontraktens aftalevaluta', widthType: FieldWidthEnum.XXS, allowSearching: false, foreignKeyTableName: 'Currency', foreignKeyField: 'iso', foreignKeyValue: 'iso', foreignKeyColumns: ['iso', 'name'], foreignKeyTitles: ['Kode', 'Navn'] },
    { name: 'published', text: 'Udgivelsesdato', tooltipText: 'Dato for udgivelse', format: FieldFormatEnum.DateShort, widthType: FieldWidthEnum.S, visibleAdd: false },
    { name: 'salesPrice', text: 'Salgspris inkl. moms', tooltipText: 'Gældende salgspris inkl. moms', format: FieldFormatEnum.Amount, widthType: FieldWidthEnum.S, visibleAdd: false },
    { name: 'language', text: 'Sprog', tooltipText: 'Sprog for udgivelse', foreignKeyTableName: 'Language', foreignKeyField: 'num', allowSearching: false, foreignKeyValue: 'name', defaultValue: 'dan', visible: false },
    { name: 'languageOriginal', text: 'Org.sprog', tooltipText: 'Sprog for original udgivelse', foreignKeyTableName: 'Language', allowSearching: false, foreignKeyField: 'num', foreignKeyValue: 'name', visible: false },
    { name: 'closed', text: 'Lukket dato', tooltipText: 'Dato for lukning', format: FieldFormatEnum.DateShort, widthType: FieldWidthEnum.S, visibleAdd: false },
    { name: 'itemNum', text: 'Varenr.', tooltipText: 'Varenr.', widthType: FieldWidthEnum.M, allowEdit: false, visible: false, visibleAdd: false, visibleEdit: true },
    { name: 'subProductPrimary.num', text: 'Trykt ISBN', tooltipText: 'ISBN på trykt bog', allowSearching: false, widthType: FieldWidthEnum.XL, visible: false, visibleAdd: false, visibleEdit: true, allowEdit: false },
    { name: 'subProductDigital.create', text: 'Opret ebog?', format: FieldFormatEnum.CheckBox, allowSearching: false, visible: false, visibleAdd: true, visibleEdit: true, defaultValue: false, allowColumnChooser: false },
    { name: 'subProductDigital.num', text: 'Ebog ISBN', tooltipText: 'ISBN på Ebog', widthType: FieldWidthEnum.XL, visible: false, visibleAdd: false, visibleEdit: true, allowEdit: false },
    { name: 'subProductAudio.create', text: 'Opret lydbog?', format: FieldFormatEnum.CheckBox, visible: false, allowSearching: false, visibleAdd: true, visibleEdit: true, defaultValue: false, allowColumnChooser: false },
    { name: 'subProductAudio.num', text: 'Lydbog ISBN', tooltipText: 'ISBN på lydbog', widthType: FieldWidthEnum.XL, allowSearching: false, visible: false, visibleAdd: false, visibleEdit: true, allowEdit: false },
    { name: 'legacyId', text: 'Opr. id', format: FieldFormatEnum.Integer, allowSearching: false, visible: false }
    ];

  private uiCmdDetailToggle = Object.assign(new Object(), uiCmdPaneRightToggle, { text: 'Budget', tooltipText: 'Vis budget i højre side [Ctrl]+[Pil højre]' });
  private uiCmdBottomToggle = Object.assign(new Object(), uiCmdBottomToggle, { text: 'Afregning', tooltipText: 'Vis afregningsopsætning nederst [Ctrl]+[Pil ned] (Opret: [Ctrl]+[Insert])' });
  private uiCmdBottomShowAddRecord = Object.assign(new Object(), uiCmdBottomToggle, { id: 'uiCmdBottomShowAddRecord', text: 'Opret afregning', tooltipText: 'Opret afregningsopsætning nederst [Ctrl]+[Insert]' });
  private uiCmdDetailClose = Object.assign(new Object(), uiCmdPaneRightClose, { text: '', tooltipText: 'Luk budget [Ctrl]+[Pil op]' });
  private uiCmdBottomClose = Object.assign(new Object(), uiCmdBottomClose, { text: 'Afregningsopsætning', tooltipText: 'Luk afregningsopsætning [Ctrl]+[Pil venste]' });

  protected toolbarCommands: UiCommand[] = [this.productService.uiCmdSyncNum, this.uiCmdBottomToggle, this.uiCmdDetailToggle];
  protected contextMenuCommands: UiCommand[] = [this.productService.uiCmdSyncSelected];
  protected keyboardShortcuts: UiKeyboardShortcut[] = [
    { code: 'ArrowRight', ctrl: true, alt: false, shift: false, cmd: this.uiCmdDetailToggle },
    { code: 'ArrowRight', ctrl: true, alt: false, shift: true, cmd: this.uiCmdDetailToggle },
    { code: 'ArrowLeft', ctrl: true, alt: false, shift: false, cmd: this.uiCmdDetailToggle },
    { code: 'ArrowLeft', ctrl: true, alt: false, shift: true, cmd: this.uiCmdDetailToggle },
    { code: 'ArrowDown', ctrl: true, alt: false, shift: false, cmd: this.uiCmdBottomToggle },
    { code: 'ArrowDown', ctrl: true, alt: false, shift: true, cmd: this.uiCmdBottomToggle },
    { code: 'ArrowUp', ctrl: true, alt: false, shift: false, cmd: this.uiCmdBottomToggle },
    { code: 'ArrowUp', ctrl: true, alt: false, shift: true, cmd: this.uiCmdBottomToggle },
    { code: 'Enter', ctrl: true, alt: false, shift: true, cmd: this.uiCmdBottomShowAddRecord },
    { code: 'Return', ctrl: true, alt: false, shift: true, cmd: this.uiCmdBottomShowAddRecord },
    { code: 'Insert', ctrl: true, alt: false, shift: false, cmd: this.uiCmdBottomShowAddRecord },
  ];

  public addressSearchActive: boolean = false;
  public selectionMode: string = 'row';
  public selectionInitial = true;

  detailGrid: CerGridComponent;
  @ViewChild('detailGrid', { static: false }) set detailGridContent(content: CerGridComponent) {
    if (content && !this.detailGrid) {
      this.detailGrid = content;
    }
  }

  public detailViewMetadata: ViewMetadata = {
    name: 'Budgetposter', dataApiName: 'StdBelob', text: 'Budget', textSingular: 'Budgetpost', allowSorting: false, allowFiltering: false,
    primaryKey: ['compositeKey' /* = 'ukontrakt§omkArt' workaround - EJ2 only has single field support */, 'ukontrakt', 'omkArt'],
    parentPrimaryKey: 'legacyId', parentForeignKey: 'ukontrakt',
    titleFields: ['ukontrakt', 'omkArt']
  };

  public detailFieldMetadata: FieldMetadata[] =
    [{ name: 'compositeKey', text: 'Nøgle', required: true, visible: false, widthType: FieldWidthEnum.XXL, visibleAdd: false, allowEdit: false },
    {
      name: 'ukontrakt', text: 'Udgivelse', required: true, visible: false, widthType: FieldWidthEnum.XXL, visibleAdd: false, allowEdit: false,
      foreignKeyTableName: 'Product', foreignKeyField: 'legacyId', foreignKeyColumns: ['num', 'name'], foreignKeyTitles: ['ISBN', 'Titel'], foreignKeyAllowFiltering: true
    },
    {
      name: 'omkArt', text: 'Art', tooltipText: 'Art for budget', required: true, widthType: FieldWidthEnum.XXL,
      foreignKeyTableName: 'ProductBudgetCategory', foreignKeyField: 'legacyId', foreignKeyValue: 'name', foreignKeyColumns: ['name', 'num'], foreignKeyTitles: ['Navn', 'Art'], foreignKeySortFields: ['sorting'], foreignKeyAllowFiltering: true,
      foreignKeyFilterFields: ['isBudget'], foreignKeyFilterValues: [true]
    },
    { name: 'belob', text: 'Beløb DKK', tooltipText: 'Budgetbeløb i DKK', format: FieldFormatEnum.Amount, widthType: FieldWidthEnum.M },
    { name: 'pct', text: '%', tooltipText: 'Procent angivelse', format: FieldFormatEnum.Amount, widthType: FieldWidthEnum.XS },
    { name: 'termin', text: 'Termin', widthType: FieldWidthEnum.M, visible: false },
    { name: 'logRef', text: 'Log ref.', format: FieldFormatEnum.Integer, visible: false },
    { name: 'kladde', text: 'Kladde', format: FieldFormatEnum.Integer, visible: false },
    { name: 'sorting', text: 'Sortering', format: FieldFormatEnum.Integer, visible: false, orderBy: 'Ascending' }
    ];

  public detailToolbarCommands: UiCommand[] = [this.uiCmdDetailClose, this.productService.uiCmdBudgetApprove, this.productService.uiCmdBudgetCreateStandard];

  bottomGrid: CerGridComponent;
  @ViewChild('bottomGrid', { static: false }) set bottomGridContent(content: CerGridComponent) {
    if (content && !this.bottomGrid) {
      this.bottomGrid = content;
    }
  }

  public contractCurrency: string = null;
  public contractCurrencyLegacyId: number = null;
  public contractCurrencyDKKLegacyId: number = null;

  public bottomViewMetadata: ViewMetadata = {
    name: 'Afregning', dataApiName: 'Afregningstabel', dataApiParams: ['$includeForfatterNavigation'],
    text: 'Afregningsopsætninger', textSingular: 'Afregningsopsætning', allowSorting: false, allowFiltering: false,
    primaryKey: ['compositeKey' /* = 'ukontrakt§omkArt' workaround - EJ2 only has single field support */, 'ukontrakt', 'omkArt'], parentPrimaryKey: 'legacyId', parentForeignKey: 'hid'
  };

  public bottomFieldMetadata: FieldMetadata[] =
    [{ name: 'compositeKey', text: 'Nøgle', required: true, visible: false, widthType: FieldWidthEnum.XXL, visibleAdd: false, allowEdit: false },
    {
      name: 'hid', text: 'Udgivelse', required: true, visible: false, widthType: FieldWidthEnum.XXL, visibleAdd: false, allowEdit: false,
      foreignKeyTableName: 'Product', foreignKeyField: 'legacyId', foreignKeyColumns: ['num', 'name'], foreignKeyTitles: ['ISBN', 'Titel'], foreignKeyAllowFiltering: true
    },
    {
      name: 'fid', text: 'Forfatter/Forlag/Agent', tooltipText: 'Reference til forfatter, forlag eller agent', required: true, widthType: FieldWidthEnum.XXXL,
      foreignKeyTableName: 'Forfatter', foreignKeyField: 'id', foreignKeyValue: 'idNavn', foreignKeyColumns: ['idNavn'], foreignKeyTitles: ['Id/Navn'], foreignKeyTableParams: ['$filterBlocked'],
      foreignKeyFromNavigation: true, foreignKeyAllowFiltering: true, foreignKeyFilterFields: ['inAktiv'], foreignKeyFilterValues: [false],
      onEditChanging: (args) => { this.bottomGridForfatterChanging(args) }, onEditChanged: (args) => { this.bottomGridForfatterChanged(args) }
    },
    { name: 'antal', text: 'Antal fra', tooltipText: 'Trappe antal fra', defaultValue: 0, required: true, format: FieldFormatEnum.Integer, widthType: FieldWidthEnum.S },
    { name: 'procent', text: '%', tooltipText: '% af omsætning til afregning', format: FieldFormatEnum.Amount, widthType: FieldWidthEnum.XS },
    { name: 'forskud', text: 'Forskud', tooltipText: 'Udbetalt forskud', format: FieldFormatEnum.Amount, widthType: FieldWidthEnum.S },
    {
      name: 'currency', text: 'Valuta', widthType: FieldWidthEnum.XS, foreignKeyTableName: 'ValutaKoder', foreignKeyField: 'id', foreignKeyValue: 'valutaKode',
      onEditChanging: (args) => { this.bottomGridValutaChanging(args) }, onEditChanged: (args) => { this.bottomGridValutaChanged(args) }
    },
    { name: 'sortKey', text: 'Sortering', widthType: FieldWidthEnum.S, visible: false },
    { name: 'logRef', text: 'Log ref.', format: FieldFormatEnum.Integer, visible: false },
    { name: 'kladde', text: 'Kladde', format: FieldFormatEnum.Integer, visible: false }
    ];

  public bottomToolbarCommands: UiCommand[] = [this.uiCmdBottomClose];

  public dateToday: Date = new Date();
  public datePast: Date = ProductComponent.addDays(this.dateToday, -300);
  public dateAgo: Date = ProductComponent.addDays(this.dateToday, -5);
  public dateFwd: Date = ProductComponent.addDays(this.dateToday, +14);

  public static addDays(d: Date, addDays: number): Date {
    var res: Date = new Date();
    res.setDate(d.getDate() + addDays);
    return res;
  }

  public stepperSteps: INextStep[] = [
    { title: 'Id', completed: false, icon: 'folder', completedDate: this.datePast },
    { title: 'Aftale', completed: true, icon: 'business_center', scheduledDate: this.dateAgo },
    { title: 'Rediger', completed: false, editable: true, icon: 'assignment_ind', scheduledDate: this.dateFwd },
    { title: 'Oplag', completed: false, editable: true, icon: 'upload_file', startedDate: this.dateAgo },
    { title: 'Salg', completed: false, editable: true, icon: 'store_mall_directory' },
    { title: 'Genoplag', completed: false, editable: true, icon: 'whatshot', disabled: true },
    { title: 'Udfasning', completed: false, editable: true, icon: 'local_parking' },
    { title: 'Nedlagt', completed: false, editable: true, icon: 'delete_outline', disabled: true }
  ];

  acceptUpdateContract: boolean;

  constructor(private productService: ProductService, private dialogService: CerDialogService, private userSessionService: UserSessionService) {
    //    this.stepperSteps[0].formGroup = this.formBuilder.group({ firstControl: ['', Validators.required] });
  }

  ngOnInit(): void {
    this.manage(this.userSessionService.user$.subscribe(user => {
      this.fieldMetadata.find(f => f.name == 'responsible').defaultValue = user?.dto?.id;
    }));
  }

  private subscriptionManager: Subscription = new Subscription();
  private manage(s: Subscription) {
    this.subscriptionManager.add(s);
  }

  ngOnDestroy() {
    this.subscriptionManager.unsubscribe();
  }

  public onCommand(event: UiCommandEvent) {
    switch (event.source) {
      case UiCommandSourceEnum.ActionBegin:
        switch (event.actionType) {
          case UiActionTypeEnum.Add:
          case UiActionTypeEnum.BeginEdit:
            this.dialogBeginEditActionBegin(event.args.dialog, event.args.rowData);
            break;
          case UiActionTypeEnum.Save:
            if (event.args.action != "insert") {
              this.contractIsChangedValidate(event.args);
            }
        }
        break;
      case UiCommandSourceEnum.ActionComplete:
        switch (event.actionType) {
          case UiActionTypeEnum.Add:
          case UiActionTypeEnum.BeginEdit:
            this.dialogBeginEditActionComplete(event.args.dialog, event.args.rowData)
            break;
        }
        break;
      default:
        switch (event.commandId) {
          case this.productService.uiCmdSyncNum.id:
            this.productService.syncNumFromPrompt(this.grid, this.fieldMeta('num'), this.fieldMeta('projectNum'));
            break;
          case this.productService.uiCmdSyncSelected.id:
            this.productService.syncFromProductList(this.grid, this.grid.selection.rowsSelectedData());
            break;
          case this.uiCmdBottomShowAddRecord.id:
            if (this.bottomGrid) {
              this.bottomGridAddRecord();
            }
            else {
              event.commandId = uiCmdBottomShow.id;
              this.bottomGridAddRecordOnInit = true;
              this.form.formService.childCommandNext(event);
            }
            break;
        }
    }
  }

  /*************************
  * Edit template
  *************************/

  /*
  actionComplete: (args: DialogEditEventArgs) => {
    if ((args.requestType === 'beginEdit' || args.requestType === 'add')) {
      
      new DropDownList({value: args.rowData.ShipCountry, popupHeight: '200px', floatLabelType: 'Always',
      dataSource: countryData, fields: {text: 'ShipCountry', value: 'ShipCountry'}, placeholder: 'Ship Country'}, args.form.elements.namedItem('ShipCountry') as HTMLInputElement);
      
      new CheckBox({ label: 'Verified', checked: args.rowData.Verified }, args.form.elements.namedItem('Verified'));
      // Set initail Focus
      if (args.requestType === 'beginEdit') {
        (args.form.elements.namedItem('CustomerID')as HTMLInputElement).focus();
      }
      
      document.getElementById('next').onclick = () => {
        moveNext();
      }
  */

  /*************************
   * Product edit (Main grid)
  *************************/

  private editContractIsSaved: boolean = false;
  private editContractChangedIsConfirmed: boolean = false;
  private editProductTypePrimary: boolean = false;
  private editProductTypeDigital: boolean = false;
  private editProductTypeAudio: boolean = false;

  private fieldMeta(fieldName: string): FieldMetadata {
    var meta = this.grid.metaService.fieldMetaGet(fieldName);
    if (!meta) {
      var err = "Felt definition findes ikke '" + fieldName + "'";
      this.dialogService.warningPrompt(err);
      throw new Error(err);
    }
    return meta;
  }

  private field(fieldName: string): ColumnModel {
    var field: ColumnModel = this.grid.grid.getColumnByField(fieldName);
    if (!field) {
      var err = "Felt findes ikke '" + fieldName + "'";
      this.dialogService.warningPrompt(err);
      throw new Error(err);
    }
    return field;
  }

  private fieldForeignKeyData(fieldName: string): any[] {
    var meta: FieldMetadata = this.fieldMeta(fieldName);
    if (meta?.foreignKeyData) {
      return meta.foreignKeyData;
    }

    var field: any = this.field(fieldName);
    if (field.foreignKeyData) {
      return field.foreignKeyData;
    }
    var err = "Mangler data for felt '" + fieldName + "'";
    this.dialogService.warningPrompt(err);
    throw new Error(err);
  }


  private dialogBeginEditActionBegin(dialog: Dialog, row: any) {
    this.editProductTypeEvaluate(row.type);

    this.editContractIsSaved = (row.contract?.length > 0);
    row.requestContractCreate = false;
    if (this.editContractIsSaved) {
      this.fieldMeta('requestContractCreate').currentRowVisible = false;
      this.fieldMeta('requestContractCreate').currentRowAllowEdit = false;
      //this.fieldMeta('contractNavigation.type').currentRowAllowEdit = false;
      //this.fieldMeta('contractNavigation.currency').currentRowAllowEdit = false;
      //this.fieldMeta('contract').currentRowAllowEdit = false;
    }
    if (row.contractNavigation == null) {
      row.contractNavigation = {};
      row.contractNavigation.type = null;
      row.contractNavigation.currency = null;
    }

    // Ensure edit objects exists
    if (!row.subProductPrimary) {
      row.subProductPrimary = {};
    }
    if (!row.subProductDigital) {
      row.subProductDigital = {};
    }
    if (!row.subProductAudio) {
      row.subProductAudio = {};
    }

    if (!(row.subProductPrimary.num?.length > 0)) {
      this.fieldMeta('subProductPrimary.num').currentRowVisible = false;
    }

    var hasDigital = (row.subProductDigital.num?.length > 0);
    if (!hasDigital) {
      this.fieldMeta('subProductDigital.num').currentRowVisible = false;
    }
    if (hasDigital || this.editProductTypeDigital) {
      this.fieldMeta('subProductDigital.create').currentRowVisible = false;
    }

    var hasAudio = (row.subProductAudio.num?.length > 0);
    if (!hasAudio) {
      this.fieldMeta('subProductAudio.num').currentRowVisible = false;
    }
    if (hasAudio || this.editProductTypeAudio) {
      this.fieldMeta('subProductAudio.create').currentRowVisible = false;
    }
    this.grid.editService.editedData = row;
  }

  private dialogBeginEditActionComplete(dialog: Dialog, row: any) {
    this.editEnableContract(this.editProductTypePrimary);
    this.editEnableSubProductCreate(this.editContractIsSaved || this.editProductTypePrimary);
  }

  private contractIsChangedValidate(args: any) {
    if (this.editContractChangedIsConfirmed) {
      this.editContractChangedIsConfirmed = false;
      return;
    }
    if (args.previousData) {
      var from = args.previousData['contract'];
      var to = args.data['contract'];
      if (from?.length > 0 && from != to) {
        this.contractIsChangedConfirm(args, from, to);
      }
    }
  }

  private contractIsChangedConfirm(args: any, from: string, to: string) {
    args.cancel = true; // Must resubmit save after confirmation
    var msg: string = 'Kontrakt nr. opdateres fra ' + from +
      ' til  ' + to + '.' + ' Er du sikker?';
    this.dialogService.confirmPrompt(msg, 'Opdatering').then(ok => {
      if (ok) {
        this.editContractChangedIsConfirmed = true;
        this.grid.grid.endEdit();
      }
    });
  }

  private contractTypeIsRoyalty(contractType: string): any {
    var isRoyalty: boolean = false;
    if (contractType) {
      var contratTypeDto = this.contractType(contractType);
      if (contratTypeDto?.royalty != null) {
        isRoyalty = contratTypeDto.royalty;
      }
    }
    return isRoyalty;
  }

  private contractType(contractType: string): any {
    if (contractType) {
      var field = 'contractNavigation.type';
      var contractTypeData: any[] = this.fieldForeignKeyData(field);
      return contractTypeData?.find(ct => <string>ct.num == contractType);
    }
    return null;
  }

  private onEditChangedNameWorking(args: any) {
    var e = this.grid.editService;
    var name: string = e.fieldValueGet('name');
    if (args.previousValue == name) {
      e.fieldValueSet('name', args.value);
    }
  }

  // During editing, when "Product.Type" changes, update "RequestContractCreate"+"Contract.Type"
  private onEditChangedType(args: any) {
    var itemData: any = args.itemData;
    if (itemData) {
      this.editProductTypeEvaluate(itemData.num);
      var createContract: boolean = this.editProductTypePrimary;
      this.editEnableContract(createContract);
      this.editEnableSubProductCreate(createContract);
    }
  }

  // During editing, when "Product.RequestContractCreate" changes, update "Contract.Type"
  private onEditChangedRequestContractCreate(args: FieldEditChangedArgs) {
    if (args.value != null && args.value != undefined) {
      var createContract: boolean = args.value;
      this.editEnableContract(createContract);
      this.editEnableSubProductCreate(createContract);
    }
  }

  // During editing, when "ContractNavigation.Type" changes, enabled "ContractNavigation.Currency"
  private onEditChangedContractType(args: any) {
    var e = this.grid.editService;
    this.editEnableContract();
  }

  private onEditChangedContract(args: any) {
    var e = this.grid.editService;
    this.editEnableSubProductCreate(false);
  }

  private editEnableContract(createContract: boolean = null) {
    var e = this.grid.editService;
    if (createContract === null) {
      createContract = true; //e.fieldValueGet('requestContractCreate'); TODO: Error, why?
    }
    else if (!this.editContractIsSaved) {
      e.fieldValueSet('requestContractCreate', createContract);
    }

    // Contract type enabled?
    var contractTypeInput = e.fieldInput('contractNavigation.type');
    var contractType: string = e.fieldValueGet('contractNavigation.type');
    e.inputDisable(contractTypeInput, !createContract);
    //if (!createContract) {
    //e.inputValueSet(contractTypeInput, null); // ??? why
    //}

    // Contract currency enabled?
    var allowEditCurrency = createContract && this.contractTypeIsRoyalty(contractType);
    var contractCurrencyInput = e.fieldInput('contractNavigation.currency');
    e.inputDisable(contractCurrencyInput, !allowEditCurrency);

    // Contract num enabled?
    var contractDisabled = createContract && !this.editContractIsSaved;
    var contractInput = e.fieldInput('contract');
    e.inputDisable(contractInput, contractDisabled);
    if (contractDisabled) {
      e.inputValueSet(contractInput, null);
    }
  }

  // Sub product 
  private editProductTypeEvaluate(productType: string) {
    var productTypeData: any[] = this.fieldForeignKeyData('type');
    var productTypeDto: any = (productType?.length > 0) ? productTypeData?.find(pt => <string>pt.num == productType) : null;
    this.editProductTypePrimary = (productTypeDto?.isPrimary === true);
    this.editProductTypeDigital = (productTypeDto?.isDigital === true);
    this.editProductTypeAudio = (productTypeDto?.isAudio === true);
  }

  private willHaveContractGet(createContract: boolean): boolean {
    var willHaveContract: boolean = createContract;
    if (!willHaveContract) {
      var e = this.grid.editService;
      willHaveContract = (e.fieldValueGet('contract')?.length > 0);
    }
    return willHaveContract;
  }

  private editEnableSubProductCreate(createContract: boolean) {
    var willHaveContract = this.willHaveContractGet(createContract);
    var e = this.grid.editService;

    var input = e.fieldInput('subProductDigital.create');
    if (input) {
      var enableDigital = (this.editProductTypePrimary || this.editProductTypeAudio) && willHaveContract;
      e.inputDisable(input, !enableDigital);
    }

    var input = e.fieldInput('subProductAudio.create');
    if (input) {
      var enableAudio = (this.editProductTypePrimary || this.editProductTypeDigital) && willHaveContract;
      e.inputDisable(input, !enableAudio);
    }
  }

  private getSelectedProduct(): any {
    var product: any = this.grid.selection.rowSelectedData();
    return product;
  }

  private getSelectedContract(): any {
    var product: any = this.getSelectedProduct();
    var contract = product?.contractNavigation;
    return contract;
  }

  private getContractCurrency(contract: any): any {
    return contract ? contract['currency'] : null;
  }


  /*************************
   * Budget (Detail grid)
   *************************/

  public onDetailCommand(event: UiCommandEvent) {
    switch (event.source) {
      case UiCommandSourceEnum.ActionBegin:
        switch (event.actionType) {
          case UiActionTypeEnum.Add:
            this.detailGridBeginEdit(event.args.rowData, true);
            break;
          case UiActionTypeEnum.BeginEdit:
            this.detailGridBeginEdit(event.args.rowData, false);
            break;
        }
        break;
      case UiCommandSourceEnum.ActionComplete:
        switch (event.actionType) {
          case UiActionTypeEnum.Add:
            this.detailGridBeginEditComplete(event.args.rowData, true);
            break;
        }
        break;
      default:
        switch (event.commandId) {
          case this.productService.uiCmdBudgetCreateStandard.id:
            this.productService.budgetUpdateFromProductList(this.detailGrid, this.grid.selection.rowsSelectedData(), true, false);
            break;
          case this.productService.uiCmdBudgetApprove.id:
            this.productService.budgetUpdateFromProductList(this.detailGrid, this.grid.selection.rowsSelectedData(), false, true);
            break;
        }
        break;
    }
  }

  private detailGridBeginEdit(rowData: any, isAdd: boolean) {
    this.detailOmkArtQueryFilter(rowData, isAdd);
  }

  private detailGridBeginEditComplete(row: any, isAdd: boolean) {
    var focusOmkArt = !(row.omkArt);
    setTimeout(() => { this.detailGridInitialFocus(focusOmkArt); }, 200);
  }

  /*
      name: 'omkArt', text: 'Art', tooltipText: 'Art for budget', required: true, widthType: FieldWidthEnum.XXL,
      foreignKeyTableName: 'ProductBudgetCategory', foreignKeyField: 'legacyId', foreignKeyValue: 'name', foreignKeyColumns: ['name', 'num'], foreignKeyTitles: ['Navn', 'Art'], foreignKeySortFields: ['sorting'], foreignKeyAllowFiltering: true,
      foreignKeyFilterField: 'isBudget', foreignKeyFilterOperator: 'equal', foreignKeyFilterValue: true
  */

  public detailGridInitialFocus(focusOmkArt: boolean) {
    var e = this.detailGrid.editService;
    if (focusOmkArt) {
      var fidInput = e.fieldInput('omkArt');
      if (fidInput) {
        fidInput.focus();
        var ddl: DropDownList = e.inputGetComponent(fidInput);
        if (ddl) {
          ddl.showPopup();
        }
      }
      else {
        var fidInputBelob = e.fieldInput('antal');
        if (fidInputBelob) {
          fidInputBelob.focus();
        }
      }
    }
  }

  private detailOmkArtQueryFilter(rowData: any, isAdd: boolean) {
    var e = this.detailGrid.editService;
    var column = e.fieldColumn('omkArt');
    if (column) {
      var predArt: Predicate[] = [];
      this.detailOmkArtExisting(rowData).forEach(omkArt => {
        predArt.push(new Predicate('legacyId', 'notequal', omkArt));
      });
      var meta: FieldMetadata = (<any>column).fieldMetadata;
      if (meta) {
        var metaPredicate: Predicate = this.detailGrid.dataService.fkFilterPredicates(meta);
        if (metaPredicate) {
          predArt.push(metaPredicate);
        }
      }
      var query = new Query();
      if (predArt.length > 0) {
        var pred = Predicate.and(predArt);
        query.where(pred);
      }
      (<any>column.edit.params).query = query;
      var input = e.fieldInput('omkArt');
      if (input) {
        var ddl: DropDownList = e.inputDropDownListGet(input);
        if (ddl) {
          ddl.query = query;
        }
      }
    }
  }

  private detailOmkArtExisting(rowData: any): number[] {
    var existing: number[] = [];
    this.detailGrid.grid.getCurrentViewRecords().forEach(row => existing.push((<any>row).omkArt));

    // Remove current row (can be reselected)
    if (rowData?.omkArt) {
      var idx = existing.indexOf(rowData.omkArt);
      if (idx >= 0) {
        existing.splice(idx, 1);
      }
    }

    return existing;
  }

  /*******************************
  * Afregnings tabel (Bottom grid)
  *******************************/

  private bottomGridAddRecordOnInit: boolean = false;
  private bottomGridAddRecordOnInitHasFKData: boolean = false;
  protected onBottomCommand(event: UiCommandEvent) {
    switch (event.source) {
      case UiCommandSourceEnum.ActionBegin:
        switch (event.actionType) {
          case UiActionTypeEnum.Destroyed:
            this.bottomGrid = null;
            break;
          case UiActionTypeEnum.Add:
            this.bottomGridBeginAdd(event.args, event.args.dialog, event.args.rowData);
            break;
          case UiActionTypeEnum.BeginEdit:
            this.bottomGridBeginEdit(event.args, event.args.dialog, event.args.rowData);
            break;
          case UiActionTypeEnum.Save:
            break;
        }
        break;
      case UiCommandSourceEnum.ActionComplete:
        switch (event.actionType) {
          case UiActionTypeEnum.DataBound:
            this.productRightsFromOtherProduct = undefined;
            break;
          case UiActionTypeEnum.DataFKLoad:
            this.bottomGridAddRecordOnInitHasFKData = true;
            break;
          case UiActionTypeEnum.Refresh:
            if (this.bottomGridAddRecordOnInit && this.bottomGridAddRecordOnInitHasFKData) {
              this.bottomGridAddRecordOnInit = false;
              this.bottomGridAddRecordOnInitHasFKData = false;
              this.bottomGridAddRecord();
            }
            break;
          case UiActionTypeEnum.Add:
            this.bottomGridStartBeginEditAddComplete(event.args.dialog, event.args.rowData);
            break;
          case UiActionTypeEnum.BeginEdit:
            this.bottomGridStartBeginEditAddComplete(event.args.dialog, event.args.rowData);
        }
        break;
      default:
        switch (event.commandId) {
          case this.uiCmdBottomShowAddRecord.id:
            this.bottomGridAddRecord();
            break;
        }
    }
  }

  private bottomFieldMeta(fieldName: string): FieldMetadata {
    var meta = this.bottomGrid.metaService.fieldMetaGet(fieldName);
    if (!meta) {
      var err = "Felt definition findes ikke '" + fieldName + "'";
      this.dialogService.warningPrompt(err);
      throw new Error(err);
    }
    return meta;
  }

  private getValutaLegacyIdFromCurrencyNum(currencyNum: string): number {
    if (currencyNum?.length > 0) {
      var currencyMeta: any = this.bottomGrid.metaService.fieldMetaGet('currency');
      if (currencyMeta?.foreignKeyData) {
        return (<any[]>currencyMeta.foreignKeyData).find(fk => fk.valutaKode == currencyNum)?.id ?? null;
      }
    }
    return null;
  }

  private getCurrencyNumFromValutaLegacyId(valutaLegacyId: number): string {
    if (valutaLegacyId) {
      var currencyMeta: any = this.bottomGrid.metaService.fieldMetaGet('currency');
      if (currencyMeta?.foreignKeyData) {
        return (<any[]>currencyMeta.foreignKeyData).find(fk => fk.id == valutaLegacyId)?.valutaKode ?? null;
      }
    }
    return null;
  }

  private readContractCurrency(contract: any) {
    this.contractCurrency = this.getContractCurrency(contract);
    this.contractCurrencyLegacyId = this.getValutaLegacyIdFromCurrencyNum(this.contractCurrency);
    this.contractCurrencyDKKLegacyId = this.getValutaLegacyIdFromCurrencyNum('DKK');
  }

  private bottomGridPrepare(args: any): boolean {
    var product = this.getSelectedProduct();
    if (product == null) {
      this.dialogService.errorPrompt(["Kan ikke oprette afregningsopsætning", "Udgivelse er ikke markeret"], "Afregningsopsætning");
      args.cancel = true;
      return false;

    }
    var contract = this.getSelectedContract();
    if (contract == null || product?.legacyId == null) {
      this.dialogService.errorPrompt(["Kan ikke vedligeholde afregningsopsætning", "Kontrakt for udgivelse kan ikke findes"], "Afregningsopsætning");
      args.cancel = true;
      return false;
    }
    this.readContractCurrency(contract);
    return true;
  }

  private bottomGridPrepareCurrency(row: any, currency: number, isAdd: boolean, isCopy: boolean) {
    var contract = this.getSelectedContract();
    var editCurrency = true;
    var isRoyalty = this.contractTypeIsRoyalty(contract?.type);
    if (isAdd && !isCopy) {
      if (isRoyalty) {
        row.currency = this.contractCurrencyLegacyId;
      }
      else {
        row.currency = this.contractCurrencyDKKLegacyId;
      }
      editCurrency = false;
    }
    else if (currency) {
      editCurrency = (this.contractCurrencyLegacyId && currency && currency != this.contractCurrencyLegacyId);
      if (editCurrency) {
        var txt: string = "Kontrakt valuta er '" + this.contractCurrency + "'";
        this.dialogService.snackBar(txt, "Advarsel");
      }
    }
    if (!editCurrency) {
      this.bottomFieldMeta('currency').currentRowAllowEdit = false;
    }
    this.bottomGridForfatterFilter(row.currency);
  }

  private bottomGridBeginEdit(args: any, dialog: Dialog, row: any) {
    if (!this.bottomGridPrepare(args)) {
      return;
    };
    this.bottomFieldMeta('forskud').currentRowAllowEdit = this.bottomGridFirstForfatterRow(row.fid, row.antal, row.sortKey);  // Forskud not enabled, when another step already exists
    this.bottomGridPrepareCurrency(row, row.currency, false, false);
  }

  private productRightsFromOtherProduct: any = undefined;
  private bottomGridBeginAdd(args: any, dialog: Dialog, row: any) {
    if (!this.bottomGridPrepare(args)) {
      return;
    }
    var origData: any = this.bottomGrid.rowSelectedData();
    var data: any[] = this.bottomGrid.grid.getCurrentViewRecords();
    if (!origData) {
      if (data?.length > 0) {
        var idx = data?.length - 1;
        origData = data[idx];
      }
      if (!origData) {
        origData = this.productRightsFromOtherProduct;
      }
    }
    if (origData) {
      row.fid = origData.fid;
      row.currency = origData.currency;
      if (row.hid == origData.hid) {
        var antals: number[] = data.filter(r => r.fid == row.fid).map(r => r.antal);
        row.antal = Math.max(...antals) + 1000;
        this.bottomFieldMeta('forskud').currentRowAllowEdit = false;  // Forskud not enabled, when another step already exists
      }
      this.bottomGridPrepareCurrency(row, row.currency, true, true);
    }
    else {
      if (this.productRightsFromOtherProduct === undefined) {
        var product = this.getSelectedProduct();
        if (product) {
          var subProduct = this.getSubProduct(product);
          if (subProduct) {
            args.cancel = true;
            this.bottomGridAddFromSubProduct(subProduct);
            return;
          }
        }
      }
      this.bottomGridPrepareCurrency(row, null, true, false);
    }
  }

  private getSubProduct(product: any): any {
    var num = product.subProductPrimary?.num;
    if (num?.length > 0 && num != product.num) {
      return product.subProductPrimary;
    }

    num = product.subProductPrimary?.num;
    if (num?.length > 0 && num != product.num) {
      return product.subProductDigital;
    }

    num = product.subProductAudio?.num;
    if (num?.length > 0 && num != product.num) {
      return product.subProductAudio;
    }

    return null;
  }

  private bottomGridAddFromSubProduct(product: any) {
    if (product) {
      this.productService.productRightsQuery(product).then(
        (result: AfregningstabelDto[]) => {
          this.productRightsFromOtherProduct = null;
          if (result?.length > 0) {
            this.productRightsFromOtherProduct = result[0];
          }
          this.bottomGrid.grid.addRecord();

        }
      );
    }
  }

  private bottomGridAddRecord() {
    var _this = this;
    setTimeout(() => { _this.bottomGrid.grid.addRecord(); }, 200);
  }

  private bottomGridStartBeginEditAddComplete(dialog: Dialog, row: any) {
    var focusForfatter = !(row.hid && row.fid);
    setTimeout(() => { this.bottomGridInitialFocus(focusForfatter); }, 200);
  }

  public bottomGridInitialFocus(focusForfatter: boolean) {
    if (focusForfatter) {
      var e = this.bottomGrid.editService;
      var fidInput = e.fieldInput('fid');
      if (fidInput) {
        fidInput.focus();
        var ddl: DropDownList = e.inputGetComponent(fidInput);
        if (ddl) {
          ddl.showPopup();
        }
      }
    }
    else {
      var antalInput = this.bottomGridAntalInput();
      if (antalInput) {
        antalInput.focus();
      }
    }
  }

  private bottomGridValutaChangedActive = false;

  private bottomGridForfatterSet(fid: number) {
    var e = this.bottomGrid.editService;
    //this.bottomValutaChangedActive = true;
    e.inputValueSet(e.fieldInput('fid'), fid);
    //this.bottomValutaChangedActive = false;
  }

  private bottomGridForfatterFilter(currency: number) {
    var e = this.bottomGrid.editService;
    var column = e.fieldColumn('fid');
    if (column) {
      var pred = new Predicate('valuta', 'equal', currency, true, false);

      var query = new Query().where(pred);
      (<any>column.edit.params).query = query;
      var input = e.fieldInput('fid');
      if (input) {
        var ddl: DropDownList = e.inputDropDownListGet(input);
        if (ddl) {
          ddl.query = query;
        }
      }
    }
  }

  private bottomGridForfatterChanging(args: any) {
    var itemData: any = args.itemData;
    if (itemData) {
      var valuta: number = itemData.valuta;
      this.bottomGridValutaPrompt(valuta, args).then(
        ok => { }
      );
    }
  }

  // Is this the primary row for forfatter?
  private bottomGridFirstForfatterRow(fid: number, antal: number, sortKey: number): boolean {
    var data: any[] = this.bottomGrid.grid.getCurrentViewRecords();
    if (fid && data?.length > 0 && antal > 0) {
      if (data.filter(r => (r.fid == fid && r.antal < antal)).length > 0) {
        return false;
      }
    }
    return true;
  }

  private bottomGridForfatterChanged(args: any) {
    var itemData: any = args.itemData;
    if (itemData) {
      var e = this.bottomGrid.editService;
      var input = this.bottomGridValutaInput();
      if (input && e.inputIsDisabled(input) == false) {
        var valuta: number = itemData.valuta;
        this.bottomGridValutaSet(valuta);
      }
      var canHaveForskud = this.bottomGridFirstForfatterRow(itemData.fid, e.fieldValueGet('antal'), e.fieldValueGet('sortKey'))
      this.bottomGridForskudDisable(!canHaveForskud);
    }
  }

  private bottomGridForskudDisable(disable: boolean) {
    var e = this.bottomGrid.editService;
    var value = e.fieldValueGet('forskud');
    var doDisable = (value == null && disable);
    var input = e.fieldInput('forskud');
    if (input) {
      e.inputDisable(input, doDisable);
    }
  }

  private bottomGridAntalInput(): HTMLInputElement {
    var e = this.bottomGrid.editService;
    return e.fieldInput('antal');
  }

  private bottomGridValutaInput(): HTMLInputElement {
    var e = this.bottomGrid.editService;
    return e.fieldInput('currency');
  }

  private bottomGridValutaDisable(disable: boolean) {
    var e = this.bottomGrid.editService;
    e.inputDisable(this.bottomGridValutaInput(), disable);
  }

  private bottomGridValutaSet(valuta: number) {
    var e = this.bottomGrid.editService;
    e.inputValueSet(this.bottomGridValutaInput(), valuta);
  }

  private bottomGridValutaChanging(args: any) {
    if (this.bottomGridValutaChangedActive) {
      this.bottomGridValutaChangedActive = false;
      return;
    }
    var itemData: any = args.itemData;
    if (itemData) {
      var valuta: number = itemData.id;
      this.bottomGridValutaPrompt(valuta, args).then(ok => { });
    }
  }

  private bottomGridValutaChanged(args: any) {
    var itemData: any = args.itemData;
    if (itemData) {
      var valuta: number = itemData.id;
      this.bottomGridForfatterFilter(valuta);
    }
  }

  private bottomGridValutaPrompt(valuta: number, args: any): Promise<boolean> {
    var ok: Promise<boolean>;
    if (this.contractCurrencyLegacyId && valuta && valuta != this.contractCurrencyLegacyId) {
      if (args) {
        args.cancel = true;
      }
      var txt: string[] = [
        "<strong>Afregningsopsætning valuta</strong> opdateres til <strong>'" + this.getCurrencyNumFromValutaLegacyId(valuta) + "'</strong>",
        "<strong>Kontrakt valuta</strong> er angivet til <strong>'" + this.contractCurrency + "'</strong>",
        "Rettelse ikke tilladt"
      ];
      ok = this.dialogService.infoPrompt(txt, 'Opdater valuta', false);
    }
    else {
      ok = this.dialogService.confirmPromiseAutoOk()
    }
    return ok;
  }
}


<cer-form #form pattern="parentChildPane">
  <ng-template #mainTemplate>
    <cer-grid
      #grid
      id="grid"
      height="100%"
      [viewMetadata]="viewMetadata"
      [fieldMetadata]="fieldMetadata"
      dataRelationRole="parent"
    ></cer-grid>
  </ng-template>
  <ng-template #detailTemplate>
    <ejs-toolbar (clicked)="editDescription()">
      <e-items>
        <e-item text="Rediger"></e-item>
      </e-items>
    </ejs-toolbar>
    <div style="padding: 4px">
      <div [innerHTML]="description"></div>
    </div>
  </ng-template>
</cer-form>

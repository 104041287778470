import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, ParamMap } from '@angular/router';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Subscription } from 'rxjs';
import { AngularDeviceInformationService } from 'angular-device-information';
import { v4 as uuidv4 } from 'uuid';

import { CerAppRouteService } from 'src/cer-app/cer-app-route/cer-app-route.service';

import { VoucherMessageCreateCommand, MessageVoucherCreateBaseDataVm, VoucherCategoryDto, VoucherMessageStatusEnum, VoucherApprovalStatusEnum, FileData, CurrencyDto, VoucherRequestDto, BankSettlementStatusEnum } from '../../api';
import { VoucherMessageViewClient } from "../../api";
import { CerDialogService } from "src/cer/cer-dialog/cer-dialog.service";
import { CerAppDialogService } from "src/cer-app/cer-app-dialog/cer-app-dialog.service";
import { CerAmountPipe, CerIntlService } from "src/cer-platform/cer-intl/cer-intl.service";

class Attachment {
  public id: number;
  public fileName: string;
  public extension: string;
  public file: File;
  public dataURL: string;
  public isImage: boolean;
  public active: boolean;
}

export class TxtRequiredErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}


@Component({
  selector: 'voucher-create-mobile',
  //encapsulation: ViewEncapsulation.None,
  templateUrl: 'voucher-create-mobile.component.html',
  styleUrls: ['./voucher-create-mobile.component.css'],
  providers: [CerAmountPipe],
})

export class VoucherCreateMobileComponent implements AfterViewInit, OnDestroy {
  private unlistener: () => void;

  private subscriptionManager: Subscription = new Subscription();

  public routerOverflowAuto = true; // Checked by router outlet component

  public hasCameraSupport: boolean = false;
  public camaraTextClass: string = "";

  private lifeCycleIsViewInit: boolean = false;
  private lifeCycleIsDataInit: boolean = false;
  private lifeCycleIsControlsInit: boolean = false;

  public validImageFiles: string[] = ['jpg', 'jpeg', 'gif', 'png', 'webp', 'svg'];
  public attachments: Attachment[] = [];

  public vm: MessageVoucherCreateBaseDataVm = null;
  public currencyList: CurrencyDto[] = null;
  public voucherCategoryList: VoucherCategoryDto[] = null;

  public isMobile: boolean;
  public isDragging: boolean = false;

  public transactionId: string;
  public hasVoucherRequest: boolean = false;
  public paramRequestId: number;

  public createForm: FormGroup = null;
  public voucherRequestCtrl = new FormControl();
  public amountCtrl = new FormControl('', [Validators.required]);
  public amountMatcher = new TxtRequiredErrorStateMatcher();
  public currencyCtrl = new FormControl('', [Validators.required]);
  public currencyMatcher = new TxtRequiredErrorStateMatcher();
  public transDateCtrl = new FormControl('', [Validators.required]);
  public transDateMatcher = new TxtRequiredErrorStateMatcher();
  public categoryCtrl = new FormControl();
  public categoryMatcher = new TxtRequiredErrorStateMatcher();
  public categoryNullList: VoucherCategoryDto[] = [new VoucherCategoryDto({ id: null, name: 'Ingen' })];

  public descriptionCtrl = new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(40)]);
  public descriptionMatcher = new TxtRequiredErrorStateMatcher();
  public chatCtrl = new FormControl();
  @ViewChild('#chat') chatRef: ElementRef;

  constructor(
    private routeService: CerAppRouteService,
    //private appStateService: AppStateService,
    private appUi: CerAppDialogService,
    private ui: CerDialogService,
    private intlService: CerIntlService,
    private client: VoucherMessageViewClient,
    private route: ActivatedRoute,
    private deviceService: AngularDeviceInformationService) {
    this.hasCameraSupport = this.capturePropertySupported();
    this.isMobile = this.deviceService.isMobile() || this.deviceService.isTablet();

    this.createForm = new FormGroup({
      voucherRequest: this.voucherRequestCtrl,
      amount: this.amountCtrl,
      currency: this.currencyCtrl,
      transDate: this.transDateCtrl,
      category: this.categoryCtrl,
      description: this.descriptionCtrl,
      chat: this.chatCtrl
    });

    var that = this;
    this.subscriptionManager.add(this.voucherRequestCtrl.valueChanges.subscribe(id => that.voucherRequestChanged(id)));
    this.subscriptionManager.add(this.route.queryParamMap.subscribe(params => that.getQueryParam(params)));
    this.getData();
  }

  public ngOnDestroy() {
    this.subscriptionManager.unsubscribe();
  }

  public ngAfterViewInit() {
    this.lifeCycleIsViewInit = true;
    this.controlsSetup();
  }

  // Camera buttons
  private capturePropertySupported(): boolean {
    var i: any = document.createElement('input');
    let supported: boolean = (i.capture != undefined);
    return supported;
  }

  private attributeSupported(attribute: string): boolean {
    var i = document.createElement('input');
    var supported: boolean = (i.getAttribute(attribute) != undefined);
    return supported;
  }

  // Form
  public resetForm() {
    //this.chatCtrl.setErrors(null);

    this.hasVoucherRequest = this.vm.voucherRequestList.length > 1;

    if (this.voucherRequestCtrl.value) {
      this.voucherRequestCtrl.patchValue(null);
    }

    this.createForm.patchValue(
      {
        amount: "",
        currency: this.vm.currencyIdDefault,
        transDate: new Date().toJSON().split('T')[0],
        category: null,
        description: "",
        chat: ""
      }
    )

    this.attachments = [];
    this.transactionId = uuidv4();

    this.createForm.markAsPristine();
    this.createForm.markAsUntouched();
  }

  private resetFormFromVoucherRequest(voucherRequest: VoucherRequestDto) {
    if (this.voucherRequestCtrl.value != voucherRequest.id) {
      this.voucherRequestCtrl.patchValue(voucherRequest.id);
    }
    this.createForm.patchValue(
      {
        amount: voucherRequest.amountCur,
        currency: voucherRequest.currency,
        transDate: voucherRequest.transDate.toJSON().split('T')[0],
        category: null,
        description: voucherRequest.description,
        chat: ""
      });
  }

  public voucherRequestChanged(id: number) {
    this.paramRequestId = null;

    if (id) {
      {
        var voucherRequest = this.vm.voucherRequestList.find(r => r.id == id);
        if (voucherRequest) {
          this.resetFormFromVoucherRequest(voucherRequest);
        }
        else {
          this.ui.showInfo('Bilagsanmodning er ukendt.', 'Status:');
          id = null;
        }
      }
    }
    if (!id) {
      this.resetForm();
    }
  }

  public saveForm() {
    if (this.createForm.invalid) {
      return;
    }

    var filesDataList: FileData[] = []
    this.attachments.filter(a => a.active == true)
      .forEach(a => filesDataList.push(new FileData({ fileName: a.fileName, dataUrl: a.dataURL })));

    var cmd = new VoucherMessageCreateCommand(
      {
        voucherRequest: this.createForm.value.voucherRequest,
        transactionId: this.transactionId,
        messageStatus: VoucherMessageStatusEnum.Voucher,
        voucherStatus: VoucherApprovalStatusEnum.Created,
        bankSettlementStatus: BankSettlementStatusEnum.Open,
        currentUserAsApprover: true,
        channelId: this.vm.channelId,
        amount: this.createForm.value.amount,
        currency: this.createForm.value.currency,
        transDate: new Date(this.createForm.value.transDate),
        voucherCategory: this.createForm.value.category,
        description: this.createForm.value.description,
        comment: this.createForm.value.chat,
        filesDataList: filesDataList
      }
    );


    this.postData(cmd);
  }

  protected requestFullDescription(req: VoucherRequestDto): string {
    if (req?.description?.length > 0) {
      return this.intlService.formatDecimal(req.amountCur)+' ' + this.currencyIsoById(req.currency)+': '+req.description;
    }
    return "";
  }

  private currencyIsoById(id: number): string {
    return this.currencyList.find(c => c.id == id)?.iso;
  }

  private controlsSetup() {
    if (this.lifeCycleIsViewInit && this.lifeCycleIsDataInit && !this.lifeCycleIsControlsInit) {
      this.lifeCycleIsControlsInit = true;

      this.currencyList = this.vm.currencyList;
      this.voucherCategoryList = this.vm.voucherCategoryList;
      if (this.isMobile) {
        // transDate use std. picker
      }

      // Currency
      //this.currencyDDL = new DropDownList();
      //this.currencyDDL.fields = { text: 'iso', value: 'id' };
      //this.currencyDDL.dataSource = new DataManager(this.vm.currencyList);
      //this.currencyDDL.appendTo(this.currencyRef.nativeElement);

      // Categories
      //this.categoryDDL = new DropDownList();
      //this.categoryDDL.fields = { text: 'name', value: 'id' };
      //this.categoryDDL.dataSource = new DataManager(this.vm.voucherCategoryList);
      //this.categoryDDL.appendTo(this.categoryRef.nativeElement);

      if (this.paramRequestId) {
        this.voucherRequestChanged(this.paramRequestId);
      }
      else {
        this.resetForm();
      }
      this.routeService.isLoading$.next(false);
    }
  }

  // Data
  private getQueryParam(params: ParamMap) {
    // VoucherRequestId
    if (params.has("request")) {
      var parm: string = params.get("request");
      if (parm && parm.length) {
        var request: number = Number(parm);
        if (request && request != this.paramRequestId) {
          this.paramRequestId = request;
          if (this.vm) {
            this.voucherRequestChanged(this.paramRequestId);
          }
        }
      }
    }
  }

  private getData() {
    this.client.getMessageCreateBaseData().subscribe({
      next: (vm) => {
        this.vm = vm;
        this.vm.voucherRequestList.unshift({ id: null, description: "" } as VoucherRequestDto);
        this.hasVoucherRequest = this.vm.voucherRequestList.length > 1;
        this.lifeCycleIsDataInit = true;
        if (this.lifeCycleIsViewInit && this.lifeCycleIsDataInit && !this.lifeCycleIsControlsInit) {
          this.controlsSetup();
        }
      },
      error: (error) => {
        this.routeService.isLoading$.next(false);
        this.appUi.handleApiError(error);
      }
    });
  }

  private postData(cmd: VoucherMessageCreateCommand) {
    this.routeService.isLoading$.next(true);
    this.chatCtrl.setErrors({ 'saving': true });
    this.client.messageCreate(cmd).subscribe({
      next: (result) => {
        if (cmd.voucherRequest) {
          this.vm.voucherRequestList = this.vm.voucherRequestList.filter(r => r.id != cmd.voucherRequest);
        }
        this.routeService.isLoading$.next(false);
        this.ui.showInfo('Bilaget er oprettet.', 'Status:');
        this.resetForm();
      },
      error: (error) => {
        this.routeService.isLoading$.next(false);
        this.chatCtrl.setErrors(null);
        this.appUi.handleApiError(error);
      }
    });
  }

  // Attachments
  public handleFiles(type: number, event: any) {
    var files: FileList = event?.srcElement?.files;
    if (files != null && files.length > 0) {
      Array.from(files).forEach(file => this.fileUpload(file));
    }
  }

  public fileUpload(file: File) {
    if (file.name) {
      var extension = this.fileExtension(file.name)?.toLocaleLowerCase();
      if (this.isExtensionValid(extension)) {
        var attachments = this.attachments;
        var attachment = new Attachment();

        attachment.id = attachments.length;
        attachment.fileName = file.name;
        attachment.active = true;
        attachment.extension = extension;
        attachment.isImage = this.isImage(extension);


        var reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = function () {
          attachment.dataURL = reader.result as string;
          attachments.push(attachment);
        }
      }
    }
  }

  private isImage(extension: string): boolean {
    var image: boolean = false;
    if (extension?.length > 0) {
      image = this.vm.validImageExtensions.includes(extension);
    }
    return image;
  }

  private isExtensionValid(extension: string): boolean {
    var isValid: boolean = false;
    if (extension?.length > 0) {
      isValid = this.vm.validExtensions.includes(extension);
    }
    return isValid;
  }

  private fileExtension(fileName: string): string {
    return '.' + fileName?.split('.').pop();
  }

  // Drag-drop file

  private fileTreeUpload(that: VoucherCreateMobileComponent, item: any, path: string = '') {
    if (item.isFile) {

      item.file(function (file: File) {
        that.fileUpload(file);
      });

    } else if (item.isDirectory) {

      console.log(item.fullPath);  //console.log(item.name)

      // Get folder contents
      var dirReader = item.createReader();
      dirReader.readEntries(function (entries: any) {
        for (var i = 0; i < entries.length; i++) {
          that.fileTreeUpload(that, entries[i], path + item.name + "/");
        }
      });
    }
  }

  public captureDrop(event: any): void {
    // Stop browser opening the file
    event.preventDefault();
    this.isDragging = false;

    if (event.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (var i = 0; i < event.dataTransfer.items.length; i++) {
        var item = event.dataTransfer.items[i].webkitGetAsEntry();  // In future might be renamed to GetAsEntry()
        if (item) {
          this.fileTreeUpload(this, item);
        }
      }
    }
    else if (event.dataTransfer.files) {
      // Use DataTransfer interface to access the file(s)
      for (const file of event.dataTransfer.files) {
        this.fileUpload(file);
      }
    }
  }

  public captureDragOver(event: any): void {
    // Stop browser opening the file
    event.preventDefault();
    this.isDragging = true;
  }

  public captureDragStop(event: any): void {
    this.isDragging = false;
    event.preventDefault();
    event.stopPropagation();
  }

}

import { DropDownList, dropDownListClasses, FilteringEventArgs, FilterType } from "@syncfusion/ej2-angular-dropdowns";
import { Column } from "@syncfusion/ej2-angular-grids";
import { EventHandler, KeyboardEventArgs } from "@syncfusion/ej2-base";
import { DataManager, Query, Predicate } from '@syncfusion/ej2-data';
import { FieldMetadata } from "src/cer/cer-data/cer-data.service";

export class CerDropDownList extends DropDownList {
  protected fieldMeta: FieldMetadata;
  protected popupOpenOnEmptyFocus: boolean = false;
  protected baseQuery: Query = null;

  private autoBuildQuery: boolean = true;
  private inputSearchText: string;
  private awaitSearchText: string;
  private querySearchText: string;
  private defaultSearchOperator: FilterType = 'StartsWith';
  private columns: Column[] = [];
  private selectColumnNames: string[] = [];

  protected static getDdlFromInputContainer(currentTarget: Node): CerDropDownList {
    var ddl: CerDropDownList = null;
    if (currentTarget && currentTarget.hasChildNodes()) {
      for (var i: number = 0; i < currentTarget.childNodes.length; ++i) {
        var child: Node = currentTarget.childNodes[i];
        if (child instanceof HTMLInputElement) {
          ddl = CerDropDownList.getDdlFromInput(child);
          return ddl;
        }
        else if (child.hasChildNodes()) {
          ddl = CerDropDownList.getDdlFromInputContainer(child);
          if (ddl) {
            return ddl;
          }
        }
      }
    }
    return ddl;
  }

  protected static getDdlFromInput(input: HTMLInputElement): CerDropDownList {
    var ddl: CerDropDownList = null;
    var ele: any = input;
    for (var i: number = 0; i < ele.ej2_instances.length; ++i) {
      var ej2 = ele.ej2_instances[i];
      if (ej2 instanceof CerDropDownList) {
        ddl = ej2;
        return ddl;
      }
    }
    return ddl;
  }

  public static getDdlContainerDivFromInput(input: HTMLInputElement): HTMLElement {
    var ddl: CerDropDownList = CerDropDownList.getDdlFromInput(input)
    if (ddl) {
      return ddl.inputWrapper?.container;
    }
    return null;
  }

  constructor(options: any) {
    super(options);
    this.popupOpenOnEmptyFocus = options.popupOpenOnEmptyFocus ?? false;
    this.baseQuery = options.baseQuery;
    this.columns = options.columns;
    this.autoBuildQuery = options.autoBuildQuery ?? true;

    this.fieldMeta = options.fieldMeta;
    if (this.fieldMeta) {
      this.initFromFieldMetaData(options);
    }

    if (this.columns?.filter(c => c.visible !== false).length > 1) {
      if (!this.headerTemplate) {
        this.headerTemplate = this.getTemplate(true);
      }
      if (!this.itemTemplate) {
        this.itemTemplate = this.getTemplate(false);
      }
    }

    if (options?.filterType === undefined) {
      this.filterType = this.defaultSearchOperator;
    }
    if (options?.query === undefined) {
      this.query = new Query();
    }
  }

  protected initFromFieldMetaData(options: any) {
    if (this.fieldMeta) {
      var meta: FieldMetadata = this.fieldMeta;

      // Data
      if (!this.data && this.fieldMeta.foreignKeyData) {
        this.data = Array.from(this.fieldMeta.foreignKeyData);
      }
      // Appearance
      if (options?.showClearButton === undefined) {
        this.showClearButton = meta.allowClear !== false;
      }

      if (options?.popupOpenOnEmptyFocus === undefined && meta.foreignKeySearchOnEmptyFocus !== undefined) {
        this.popupOpenOnEmptyFocus = meta.foreignKeySearchOnEmptyFocus;
      }

      // Columns
      if (options?.columns === undefined) {
        var columns: Column[] = [];
        if (meta.foreignKeyColumns?.length > 0) {
          meta.foreignKeyColumns.forEach((columnName, index) => {
            var column: Column = <any>{};
            column.field = columnName;
            column.headerText = meta.foreignKeyTitles?.length > index ? meta.foreignKeyTitles[index] : undefined;
            columns.push(column);
          });
        }
        else if (meta.foreignKeyValue) {
          var column: Column = <any>{};
          column.field = meta.foreignKeyValue;
          columns.push(column);
        }
      }
      this.columns = columns;
    }

    if (options.selectColumnNames === undefined) {
      let selectColumnNames: string[] = [meta.foreignKeyValue ?? 'name', meta.foreignKeyField ?? 'id'];
      if (meta.foreignKeyColumns?.length > 0) {
        selectColumnNames = selectColumnNames.concat(meta.foreignKeyColumns);
      }
      if (meta.foreignKeySearchFields?.length > 0) {
        selectColumnNames = selectColumnNames.concat(meta.foreignKeySearchFields);
      }
      if (meta.foreignKeySortFields?.length > 0) {
        selectColumnNames = selectColumnNames.concat(meta.foreignKeySortFields);
      }
      let selectColumnNamesUnique: string[] = [...new Set(selectColumnNames)].filter((f) => f?.length > 0);
      this.selectColumnNames = selectColumnNamesUnique;
    }

    // Appearance
    if (options?.popupHeight === undefined && options.popupWidth === undefined) {
      var isLarge: boolean = this.columns?.length > 0;
      this.popupHeight = isLarge ? '600px' : '200px';
      this.popupWidth = isLarge ? '400px' : '200px';
    }

    // Data
    //this.enableVirtualization =  true;
    //this.query = this.getQueryFromFieldMeta(null);

    if (options?.sortOrder === undefined) {
      this.sortOrder = meta.foreignKeySortFields?.length > 0 ? 'None' : 'Ascending';  // Query already sorts  
    }
    if (options?.allowFiltering === undefined) {
      this.allowFiltering = meta.foreignKeyAllowFiltering ?? true;
    }

    this.dataSave();
  }

  override wireEvent() {
    super.wireEvent();
    EventHandler.remove(this.inputWrapper.container, 'keypress', (<any>this).onSearch);

    this.addEventListener('open', this.onCerOpenPopup);
    this.addEventListener('actionBegin', this.onCerActionBegin);
    this.addEventListener('actionComplete', this.onCerActionComplete);
    this.addEventListener("filtering", this.onCerFiltering);

    document.addEventListener("paste", this.pasteHandlerInputDoc.bind(this));
    //EventHandler.add(document, "paste", this.pasteHandlerInputDoc, this);
    EventHandler.add(this.inputWrapper.container, 'keypress', this.onSearch_Input);
    EventHandler.add(this.inputWrapper.container, 'focus', this.onFocusInput);
    EventHandler.add(this.inputWrapper.container, 'paste', this.pasteHandlerInput, this);
    EventHandler.add(this.inputWrapper.container, 'keyDown', this.keyDownHandlerInput, this);

  }

  override unWireEvent() {
    EventHandler.remove(this.inputWrapper.container, 'keydown', this.keyDownHandlerInput);
    EventHandler.remove(this.inputWrapper.container, 'paste', this.pasteHandlerInput);
    EventHandler.remove(this.inputWrapper.container, 'focus', this.onFocusInput);
    EventHandler.remove(this.inputWrapper.container, 'keypress', this.onSearch_Input);

    if (this.inputElement) {
      this.inputElement.parentElement.parentElement.addEventListener("paste", this.pasteHandlerInputDoc);
      this.inputElement.parentElement.addEventListener("paste", this.pasteHandlerInputDoc);
      this.inputElement.addEventListener("paste", this.pasteHandlerInputDoc);
      //EventHandler.remove(this.inputElement, 'paste', this.pasteHandlerInput);
    }
    this.removeEventListener("filtering", this.onCerFiltering);
    this.removeEventListener('actionComplete', this.onCerActionComplete);
    this.removeEventListener('actionBegin', this.onCerActionBegin);
    this.removeEventListener('open', this.onCerOpenPopup);

    document.removeEventListener("paste", this.pasteHandlerInputDoc);

    super.unWireEvent();
  }

  override render() {
    // this.cloneDataSource();
    super.render();
  }

  override postRender(listElement: any, list: any, bindEvent: any) {
    super.postRender(listElement, list, bindEvent);

    //if (this.enqueueFiltering) {
    //  this.enqueueFiltering = false;
    this.isSelected = true;
    var e: KeyboardEvent = new KeyboardEvent("force", null);
    //this.onChangeEvent(e);
    //}
  }

  protected cloneDataSource() {
    if (this.data) {
      //var data = Array.from(this.data);
      // this.dataSource = new DataManager(data);
      //console.log('clone dataSource count', data.length);
    }
  }

  protected override getQuery(query: Query): Query {
    this.cloneDataSource();

    if (this.autoBuildQuery) {
      var searchText = this.filterInput ? this.filterInput.value : this.inputSearchText ?? null;
      query = this.onCerBuildQuery(query, searchText);
    }
    else {
      query = super.getQuery(query);
    }
    return query;
  }

  public override showPopup(e?: MouseEvent | KeyboardEventArgs | TouchEvent) {
    //this.enqueueFiltering = false;
    //this.query = this.getQuery(null);
    super.showPopup();
    //this.filterInput.focus();
  }

  public checkShowPopup() {
    if (!this.isPopupOpen && !this.value && this.popupOpenOnEmptyFocus) {
      this.showPopup();
    }
  }

  public checkIsPopupOpen(): boolean {
    return this.isPopupOpen;
  }

  public hasContainerFocus(): boolean {
    var hasFocus: boolean = false;

    if (document.activeElement && this.inputWrapper && this.inputWrapper.container) {
      hasFocus = (document.activeElement == this.inputWrapper.container);
    }
    return hasFocus;
  }

  private onFocusInput(e: any) {
    var ddl: CerDropDownList = CerDropDownList.getDdlFromInputContainer(e.currentTarget);
    if (ddl?.popupOpenOnEmptyFocus) {
      //ddl.checkShowPopup();
    }
  }

  // Saved => Reload data onCerActionBegin
  private data: any[];
  protected dataSave() {
    if (!this.data) {

      if (this.dataSource instanceof DataManager) {
        var dm = this.dataSource as DataManager;
        var dataOrig: any[] = dm.dataSource?.json;
        if (dataOrig) {
          if (dataOrig?.length > 0) {
            this.data = dataOrig;
            //console.log('dataSourceOrig count', this.data?.length);
          }
        }
      }
    }
  }

  private onCerFiltering(e: FilteringEventArgs) {
    this.querySearchText = this.awaitSearchText;
    //console.log('filtering', e.text);
  }

  protected onCerActionBegin(e: any) {
    this.awaitSearchText = e.query?.searchTextInfo ?? this.filterInput?.value ?? undefined;
    e.query = this.getQuery(e.query);
    //console.log('actionBegin', this.awaitSearchText ?? '<full result>', '(where predicates', (e.query.onwhere?.length ?? '<none>') + ')');
  }

  protected onCerActionComplete(e: any) {
    if (!e.cancel) {
      this.querySearchText = this.awaitSearchText;

    }
    //console.log('actionComplete', this.querySearchText ?? '<full result>');
  }

  protected onSearch_Input(e: any) {
    if (e.key?.length > 0) {
      let ddl: CerDropDownList = CerDropDownList.getDdlFromInputContainer(e.currentTarget);
      if (ddl) {
        if (e.charCode !== 32 && e.charCode !== 13) {
          ddl.openPopupWithKeyboardEvent(e);
        }
        else if (e.charCode == 32) {
          ddl.showPopup(e);
        }
      }
    }
  }

  public pasteHandlerInputDoc(e: any) {
    if (e.cerHandled !== true) {
      let ddl: CerDropDownList = CerDropDownList.getDdlFromInputContainer(document.activeElement);
      if (ddl) {
        e.cerHandled = true;
        ddl.pasteHandlerInput(e);
      }
    }
  }

  public keyDownHandlerInput(e: any) {
    //console.log('keyDownHandlerInput');
  }

  public pasteHandlerInput(e: any) {
    if (e.clipboardData) {
      var text: string = e.clipboardData.getData('Text');
      this.inputSearchText = null;
      this.openPopupWithKeyboardValue(text, e);
    }
  }

  public openPopupWithKeyboardEvent(e: any) {
    var text: string = e?.key;
    this.openPopupWithKeyboardValue(text, e);
  }

  public openPopupWithKeyboardValue(text: string, e: any) {
    if (this.allowFiltering && text?.length > 0) {
      this.inputSearchText = this.inputSearchText?.length > 0 ? this.inputSearchText + text : text;
      if (!this.isPopupOpen) {
        this.showPopup();
      }
      else {
        this.filterInput.value = this.inputSearchText;
        this.filterText(this.filterInput.value, e);
      }
      e.preventDefault();
      e.cancelBubble = true;
    }
  }

  protected onCerOpenPopup(e: any) {
    if (this.allowFiltering) {
      if (this.inputSearchText?.length > 0) {
        this.filterText(this.inputSearchText, e);
      }
      else if (this.querySearchText != this.filterInput?.value) {
        this.filterText(this.filterInput?.value ?? undefined, e);
      }
    }
  }

  private filterText(text: string, e: any) {
    this.typedString = text;
    this.inputSearchText = '';
    this.filterInput.value = text;
    this.filterInput.focus();
    //this.enqueueFiltering = true;
    this.searchLists(e);
    if (this.enableVirtualization) {
      this.getFilteringSkeletonCount();
    }
  }

  /*
  override setListData(dataSource: any, fields: any, query: any, event: any) {
    super.setListData(dataSource, fields, query, event);
    this.lastFilteredValue = this.filterInput.value;
  }*/

  protected isSearchListCurrent(): boolean {
    var isCurrent: boolean = true;
    if (this.allowFiltering) {
      var searchText = this.filterInput?.value;
      if (searchText?.length > 0 || this.querySearchText?.length > 0) {
        isCurrent = (this.querySearchText == searchText);
        if (!isCurrent) {
          //console.log('Not current: "' + this.querySearchText + '" != "' + searchText + '"');
        }
      }
    }
    return isCurrent;
  }

  override selectCurrentItem(e: any) {
    if (this.isPopupOpen) {
      if (!this.isSearchListCurrent()) {
        this.searchLists(e);
      }
      else {
        let li = this.list.querySelector('.' + dropDownListClasses.focus);
        if (li) {
          this.setSelection(li, e);
          this.isTyped = false;
        }
        if (this.isSelected) {
          this.isSelectCustom = false;
          this.onChangeEvent(e);
        }
        this.hidePopup();
        // @ts-ignore
        this.focusDropDown(e);
        /*
                else {
                  if (this.filterInput.value.length > 0) {
                    this.buildQueryForced = true;
                    this.enqueueFiltering = true;
                    this.searchLists(e);
                  }
                  else {
                    //if (!this.isSelected) {
                    //  this.onChangeEvent(e);
                    //}
                    this.hidePopup();
                    this.focusDropDown(e);
                  }
                }*/
      }
    }
    else {
      this.showPopup();
    }
  }

  protected selectFirstItem(e: any) {
    if (this.isPopupOpen) {
      let li = <Element>this.list?.firstChild?.firstChild;
      if (li) {
        this.setSelection(li, e);
        this.isTyped = false;
      }
      if (this.isSelected) {
        this.isSelectCustom = false;
        this.onChangeEvent(e);
      }
      else {
        if (!this.isSelected) {
          this.onChangeEvent(e);
        }
        this.hidePopup();
        // @ts-ignore
        this.focusDropDown(e);
      }
    }
    else {
      this.showPopup();
    }
  }

  // Data binding
  protected onCerBuildQuery(query: Query, searchText: string): Query {
    //console.log('onCerbuildQuery=', searchText, '- ', (this.dataSource as any)?.dataSource?.json?.length);

    let queryNew: Query = this.baseQuery ? this.baseQuery.clone() : new Query();

    if (this.selectColumnNames?.length > 0) {
      queryNew = queryNew.select(this.selectColumnNames);
    }

    var queryPredicate: Predicate = this.fieldMeta ? this.searchPredicateFromMeta(this.fieldMeta, searchText) : this.searchPredicate(searchText);

    var queryPredicateIdNotNull = new Predicate(this.fields.value, 'notequal', null, true, false);
    queryPredicate = (!queryPredicate) ? queryPredicateIdNotNull : new Predicate(queryPredicate, 'and', queryPredicateIdNotNull);

    if (queryPredicate) {
      queryNew = queryNew.where(queryPredicate);
    }

    this.fieldMeta?.foreignKeySortFields?.forEach((sortField: string) => {
      queryNew = queryNew.sortBy(sortField);
    });

    return queryNew;
  }

  protected searchPredicate(searchText: string = null): Predicate {
    var queryPredicate: Predicate;
    if (searchText?.length > 0) {
      var searchColumns = this.columns?.filter(c => c.allowFiltering !== false);
      if (searchColumns?.length > 0) {
        searchColumns.forEach(c => {
          let searchField: string = c.field;
          let searchOperator: string = (c as any).searchOperator ?? this.defaultSearchOperator;
          let predicateSearch: Predicate = new Predicate(searchField, searchOperator, searchText, true, true, false);
          queryPredicate = queryPredicate ? new Predicate(queryPredicate, 'or', predicateSearch) : predicateSearch;
        });
      }
      else if (this.fields?.text?.length > 0) {
        let searchFields: string[] = [this.fields.text];
        let searchOperators: string[] = [];
        for (let i = 0; i < searchFields.length; i++) {
          let searchField: string = searchFields[i];
          let searchOperator: string = (searchOperators?.length > i) ? searchOperators[i] : this.filterType;
          let predicateSearch: Predicate = new Predicate(searchField, searchOperator, searchText, true, true, false);
          queryPredicate = queryPredicate ? new Predicate(queryPredicate, 'or', predicateSearch) : predicateSearch;
        }
      }
    }
    return queryPredicate;
  }

  protected searchPredicateFromMeta(meta: FieldMetadata, searchText: string = null): Predicate {
    var queryPredicate: Predicate;

    if (meta) {
      if (searchText?.length > 0 && meta) {
        let searchFields: string[] = meta.foreignKeySearchFields;
        let searchOperators: string[] = meta.foreignKeySearchOperators;
        if (!(searchFields?.length > 0)) {
          if (meta.foreignKeyColumns?.length > 0) {
            searchFields = meta.foreignKeyColumns;
          }
          else if (meta.foreignKeyValue?.length > 0) {
            searchFields = [meta.foreignKeyValue];
          }
          searchOperators = [];
        }
        if (searchFields?.length > 0) {
          for (let i = 0; i < searchFields.length; i++) {
            let searchField: string = searchFields[i];
            let searchOperator: string = (searchOperators?.length > i) ? searchOperators[i] : this.filterType;
            let predicateSearch: Predicate = new Predicate(searchField, searchOperator, searchText, true, true, false);
            queryPredicate = queryPredicate ? new Predicate(queryPredicate, 'or', predicateSearch) : predicateSearch;
          }
        }
      }
      if (meta.foreignKeyFilterFields?.length > 0) {
        let filterFields: string[] = meta.foreignKeyFilterFields;
        let filterOperators: string[] = meta.foreignKeyFilterOperators;
        let filterValues: any[] = meta.foreignKeyFilterValues;
        for (let i = 0; i < filterFields.length; i++) {
          let filterField: string = filterFields[i];
          let filterOperator: string = (filterOperators?.length >= i) ? filterOperators[i] : this.defaultSearchOperator;
          let filterValue: any = (filterOperators?.length >= i) ? filterValues[i] : undefined;
          if (filterValue !== undefined) {
            let predicateFilter: Predicate = new Predicate(filterField, filterOperator, filterValue, true, true, false);
            queryPredicate = queryPredicate ? new Predicate(queryPredicate, 'and', predicateFilter) : predicateFilter;
          }
        }
      }
    }
    return queryPredicate;
  }

  protected getTemplate(isHeader: boolean): string {
    let template: string;
    let hasHeader: boolean = false;
    let len: number = this.columns?.length ?? 0;
    if (len > 0) {
      let spanClass: string = isHeader ? 'ddl-header' : 'ddl-item';
      let cellClass: string = isHeader ? 'ddl-header-col' : 'ddl-col';
      template = '<span class="' + spanClass + '">';

      this.columns.forEach((column, index) => {
        if (column.visible !== false) {
          if (isHeader && column.headerText?.length > 0) {
            hasHeader = true;
          }
          var colValue: string = isHeader ? (column.headerText ?? column.field) : '${' + column.field + '}';
          let colWidth: string = column.width ? column.width as string : ((len == 1) ? '100%' : (index == 0 ? '30%' : '70%'));
          template += '<span class="' + cellClass + '" style="width: ' + colWidth + '">' + colValue + '</span>';
        }
      });

      template += '</span>';
    }

    if (isHeader && !hasHeader) {
      template = undefined;
    }

    return template;
  }

  /*
  private onSearchFreeText(e: any) {
    if (e.charCode !== 32 && e.charCode !== 13) {
      var ddl: snDropDownListSearch = getDdlFromInputContainer(e);
      if (ddl) {
        ddl.openPopupWithKeyboardValue(e);
      }
      e.preventDefault();
      e.cancelBubble = true;
    }
  }

  override incrementalSearch(e: KeyboardEventArgs) {
    if (e.code !== 'KeySpace' && e.code != 'KeyEnter' && e.code != 'KeyDown' && !this.isPopupOpen) {
      this.openPopupWithKeyboardValue(e);
    }
    else {
      super.incrementalSearch(e);
    }
  }
  */
}


import { Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { FieldFormatEnum, FieldMetadata, FieldWidthEnum, ViewMetadata } from '../../cer/cer-data/cer-data.service';
import { CerFormComponent } from '../../cer/cer-form/cer-form.component';
import { CerGridComponent } from '../../cer/cer-grid/cer-grid.component';
import { UiCommand, UiCommandEvent } from '../../cer/cer-grid/cer-grid-command.service';
import { UserDto as Dto, UserClient, UserRoleClient, UserRoleVm } from '../../app/api';
import { AppUserIdentityComponent } from './app-user-identity/app-user-identity.component';
import { AppUserRoleComponent } from './app-user-role/app-user-role.component';
import { CerFormPanesSettings } from 'src/cer/cer-form/cer-form-panes.service';
import { CerAppRouteService } from 'src/cer-app/cer-app-route/cer-app-route.service';
import { CerAppDialogService } from 'src/cer-app/cer-app-dialog/cer-app-dialog.service';

const uiCmdUserRoleShowHide: UiCommand = { id: 'UserRoleShowHide', text: 'Vis roller', iconCss: "e-menu-icon e-icons-org e-history", tooltipText: 'Vis liste over roller for brugeren', target: ".e-content", needSelection: true };
const uiCmdUserRoleEdit: UiCommand = { id: 'UserRoleEdit', text: 'Tilknyt roller', iconCss: "e-menu-icon e-icons-org e-lock", tooltipText: 'Tilknyt eller fjern roller fra brugeren', target: ".e-content", needSelection: true };
const uiCmdUserLinkLogin: UiCommand = { id: 'UserLinkLogin', text: 'Tilknyt login', iconCss: "e-menu-icon e-icons-org e-password", tooltipText: 'Tilknyt login til brugeren', target: ".e-content", needSelection: true };

@Component({
  selector: 'app-app-user',
  templateUrl: './app-user.component.html',
  styleUrls: ['./app-user.component.css']
})
export class AppUserComponent {
  form: CerFormComponent;
  @ViewChild('form', { static: false }) set formContent(content: CerFormComponent) {
    if (content && !this.form) {
      this.form = content;
      this.form.commmand.subscribe(e => this.onCommandMain(e));
    }
  }

  protected panes: CerFormPanesSettings = { main: { visible: "show", size: "62%" }, right: { visible: "hide", size: "38%" } };

  mainGrid: CerGridComponent;
  @ViewChild('mainGrid', { static: false }) set mainGridContent(content: CerGridComponent) {
    if (content && !this.mainGrid) {
      this.mainGrid = content;
      this.mainGrid.commmand.subscribe(e => this.onCommandMain(e));
    }
  }

  detailGrid: CerGridComponent;
  @ViewChild('detailGrid', { static: false }) set detailGridContent(content: CerGridComponent) {
    if (content && !this.detailGrid) {
      this.detailGrid = content;
    }
  }

  // User view
  public viewMetadata: ViewMetadata = {
    name: 'brugere', dataApiName: 'User',
    dataApiParams: ['$filterSystemRows'], text: 'Brugere', textSingular: 'Bruger', textPlural: 'Brugere',
    primaryKey: 'id', titleFields: ['shortName'], baseFields: ['id', 'history']
  };
  // User fields
  public fieldMetadata: FieldMetadata[] =
    [
      { name: 'shortName', text: 'Kort navn', tooltipText: 'Afsender af besked', required: true, allowEdit: true, allowEditOnCreate: true, widthType: FieldWidthEnum.M, orderBy: 'Ascending' },
      { name: 'name', text: 'Navn', tooltipText: 'Afsender af besked', required: true, allowEdit: true, allowEditOnCreate: true, widthType: FieldWidthEnum.L },
      { name: 'email', text: 'E-mail', tooltipText: 'Brugers e-mail', allowEdit: true, defaultValue: '', allowEditOnCreate: true, widthType: FieldWidthEnum.L },
      { name: 'active', text: 'Aktiv', tooltipText: 'Er brugeren aktiv', allowEdit: true, allowEditOnCreate: true, format: FieldFormatEnum.CheckBox },
      { name: 'locale', text: 'Sprog', tooltipText: 'Indstilling for sprog', visible: false, foreignKeyTableName: 'Locale', foreignKeyField: 'num', foreignKeyValue: 'name' },
      { name: 'appUserId', text: 'Login', tooltipText: 'Reference til login', allowEdit: false, allowEditOnCreate: true, visible: false, widthType: FieldWidthEnum.M }
    ];

  public toolbarCommands: UiCommand[] = [uiCmdUserRoleShowHide, uiCmdUserRoleEdit, uiCmdUserLinkLogin];
  public contextMenuCommands: UiCommand[] = [uiCmdUserRoleShowHide, uiCmdUserRoleEdit, uiCmdUserLinkLogin];

  // Role view
  public detailViewMetadata: ViewMetadata = {
    name: 'user-roles', dataApiName: 'RoleUserView', textSingular: 'Rolle tilknytning:', textPlural: 'Tilknyttede roller',
    primaryKey: 'id', parentPrimaryKey: 'id', parentForeignKey: 'userId', titleFields: ['roleName'], allowEdit: false, allowCreate: false, allowDelete: false, baseFields: ['history']
  };

  // Role fields
  public detailFieldMetadata: FieldMetadata[] =
    [{ name: 'id', text: 'Id', tooltipText: 'Unik nøgle for posten', allowEdit: false, visible: false, isIdentity: true, widthType: FieldWidthEnum.S },
    { name: 'roleId', text: 'Rolle Id', tooltipText: 'Rollens nøgle', allowEdit: false, widthType: FieldWidthEnum.S, visible: false },
    { name: 'roleName', text: 'Rollenavn', tooltipText: 'Navn på rollen', allowEdit: false, widthType: FieldWidthEnum.L, orderBy: 'Ascending' },
    { name: 'userId', text: 'Bruger Id', tooltipText: 'Brugerens nøgle', allowEdit: false, widthType: FieldWidthEnum.S, visible: false },
    { name: 'userName', text: 'Brugernavn', tooltipText: 'Brugerens navn', allowEdit: false, widthType: FieldWidthEnum.M, visible: false },
    { name: 'isActive', text: 'Aktiv', tooltipText: 'Er rollen aktiv?', allowEdit: false, widthType: FieldWidthEnum.XXXS, format: FieldFormatEnum.CheckBox }
    ];

  public filterInternal: boolean;
  public filterExternal: boolean;

  constructor(private userClient: UserClient,
    private userRoleClient: UserRoleClient,
    private routeService: CerAppRouteService,
    private appDialogService: CerAppDialogService,
    private dialog: MatDialog,
    route: ActivatedRoute) {
    this.filterInternal = (route.snapshot.data['external'] == true);
    this.filterExternal = (route.snapshot.data['internal'] == true);
  }

  // Commands
  private onCommandMain(event: UiCommandEvent) {
    switch (event.commandId) {
      case uiCmdUserRoleShowHide.id:
        this.userRoleShowHide();
        break
      case uiCmdUserRoleEdit.id:
        this.userRoleEdit();
        break;
      case uiCmdUserLinkLogin.id:
        this.userLinkLogin();
        break;
    }
  }

  public userRoleShowHide() {
    if (this.form) {
      this.form.panesService.toggle('right');
      if (this.form.panesService.visible('right')) {
        this.mainGrid?.selection.rowSelectRefresh();
      }
    }
  }

  public userRoleEdit() {
    var dto: Dto = <Dto>this.mainGrid.rowSelectedData();
    if (dto && dto.id) {
      this.userRoleClient.get(dto.id).subscribe(vm => this.userRoleDialog(vm));
    }
  }

  public userRoleDialog(vm: UserRoleVm) {
    this.routeService.isLoading$.next(true);

    var config = <MatDialogConfig>{ width: '800px', height: '600px', maxWidth: '95vw', data: { vm: vm } };
    var dialogRef: MatDialogRef<AppUserRoleComponent> = this.dialog.open(AppUserRoleComponent, config);
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        this.routeService.isLoading$.next(false);
        if (result && result.dataChanged) {
          this.userRolePost(vm.user.id, result.data);
        }
      },
      error: (error) => {
        this.routeService.isLoading$.next(false);
        this.appDialogService.handleApiStatusMessage(error);
      }
    });
  }

  public userRolePost(userId: number, roleIds: number[]) {
    this.routeService.isLoading$.next(true);
    this.userRoleClient.post(userId, roleIds).subscribe({
      next: (changesMade) => {
        this.routeService.isLoading$.next(false);
        if (changesMade) {
          this.mainGrid.research();
        }
      },
      error: (error) => {
        this.routeService.isLoading$.next(false);
        this.appDialogService.handleApiStatusMessage(error);
      }
    }
    );
  }

  public userLinkLogin() {
    var dto: Dto = <Dto>this.mainGrid.rowSelectedData();
    if (dto && dto.id) {
      this.userRoleClient.get(dto.id).subscribe(vm => this.userIdentityDialog(vm));
    }
  }

  public userIdentityDialog(vm: UserRoleVm) {
    this.routeService.isLoading$.next(true);
    var config = <MatDialogConfig>{ width: '600px', height: '600px', maxWidth: '95vw', data: { vm: vm } };
    var dialogRef: MatDialogRef<AppUserIdentityComponent> = this.dialog.open(AppUserIdentityComponent, config);
    dialogRef.afterClosed().subscribe(result => {
      this.routeService.isLoading$.next(false);
      if (result && result.dataChanged && result.identityId) {
        this.userIdentityPost(vm.user.id, result.identityId, result.confirmEmail);
      }
    });
  }

  public userIdentityPost(userId: number, identityId: string, confirmEmail: boolean) {
    this.routeService.isLoading$.next(true);
    this.userClient.linkIdentity(userId, identityId, confirmEmail).subscribe({
      next: (changesMade) => {
        this.routeService.isLoading$.next(false);
        if (changesMade) {
          this.mainGrid.research();
        }
      },
      error: (error) => {
        this.routeService.isLoading$.next(false);
        this.appDialogService.handleApiStatusMessage(error);
      }
    }
    );
  }

}

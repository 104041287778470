import { Injectable, OnDestroy } from '@angular/core';
import { ChatClient, ChatDto } from 'src/app/api';
import { Subject, Subscription } from 'rxjs';
import { AppUsersService } from '../../platform/app-user/app-users.service';
import { totalDifferenceInMinutes } from 'src/cer-platform/cer-date/cer-date';
import { CerLocaleService } from 'src/cer/cer-locale/cer-locale.service';
import { UserSessionService } from 'src/platform/app-user/user-session-service';
import { differenceInCalendarDays } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class CerAppChatService implements OnDestroy {

  private subscriptionManager$: Subscription = new Subscription();

  public chats$ = new Subject<ChatDto[]>();

  constructor(
    private chatClient: ChatClient,
    private appUsersService: AppUsersService,
    private localeService: CerLocaleService,
    private userSessionService: UserSessionService) {
  }

  public ngOnDestroy(): void {
    this.subscriptionManager$.unsubscribe();
  }

  private userId(): number {
    return this.userSessionService.userId();
  }

  public chatPost(txt: string, refTableId: number, refRowId: number, chats: ChatDto[]): Promise<ChatDto[]> {
    return new Promise<ChatDto[]>((resolve, reject) => {
      let chat: ChatDto = new ChatDto();
      chat.id = null;
      chat.createdBy = this.userId();
      chat.modifiedBy = chat.createdBy;
      chat.created = new Date();
      chat.modified = chat.created;
      chat.txt = txt;
      chat.refTableId = refTableId;
      chat.refRowId = refRowId;
      this.chatToChatsSync(chats, chat);

      this.chatClient.post(chat).subscribe(
        data => {
          var isChanged: boolean = false;
          data.forEach(chat => {
            this.prepareChatDates(chat);
            if (this.chatToChatsSync(chats, chat)) {
              isChanged = true;
            }
          });
          if (isChanged) {
            this.chats$.next(chats);
            resolve(chats);
          }
        },
        error => {
          alert("Kan ikke opdatere chat");
          reject(chats);  
        });
    });
  }

  public chatMarkRead(refTableId: number, refRowId: number) {
    this.chatClient.markReadThread(refTableId, refRowId).subscribe(
      data => {
      },
      error => {
        alert("Kan ikke læse chat");
      });
  }

  //public refreshNotifications(chat) {
  //this.chatService.chatPosted.subscribe(
  //   this.notificationService.userNotificationsAddChats(this.userNotifications.getValue(), data))
  //}

  private prepareChatDates(chat: ChatDto) {
      chat.created = new Date(chat.created);
      chat.modified = new Date(chat.modified);
      if (chat.chatUsers) {
        chat.chatUsers.forEach(cu => {
          cu.created = new Date(cu.created);
          cu.modified = new Date(cu.modified);
        });
      }
  }

  public isChatsChanged(chats: ChatDto[], newChats: ChatDto[], refTableId: number, refRowId: number): boolean {
    if ((chats == null || chats.length == 0) && (newChats.length > 0 && newChats[0].refTableId == refTableId && newChats[0].refRowId == refRowId)) {
      chats = newChats;
      return true;  
    }
    var isChanged: boolean = false;
    chats.forEach(chat =>
      isChanged = isChanged || this.isChatChanged(chats, chat, refTableId, refRowId)
    );
    return isChanged;
  }

  private isChatChanged(chats: ChatDto[], chat: ChatDto = null, refTableId: number, refRowId: number): boolean {
    var chatsChanged: boolean = false;
    if (chat.refRowId == refRowId && chat.refTableId == refTableId) {
      chatsChanged = this.chatToChatsSync(chats, chat);
    }
    return chatsChanged;
  }

  public chatToChatsSync(chats: ChatDto[], chat: ChatDto = null) : boolean {
    var isChanged: boolean = false;
    if (chats == null || chats.length == 0) {
      chats = [chat];
      return true;
    }
    var n = chats.length;
    if (n > 0) {
      for (var i = n - 1; i >= 0; i--) {
        var check = chats[i];
        if (check.id == null) {
          chats.splice(i, 1);
          isChanged = true;
        }
        else if (check.id == chat.id)
        {
          if (JSON.stringify(chat) === JSON.stringify(check))
            {
              return false;
            }
            else {
              chats.splice(i, 1);
              isChanged = true;
            };

        }
      }
    }
    if (chat) {
      chats.push(chat);
      isChanged = true;
    }
    return isChanged;
  }


  // UI Helpers
  public chatUIAvatar(chat: ChatDto): string {
    return this.appUsersService.avatarById(chat.createdBy);
  }

  public chatUIIsOwn(chat: ChatDto): boolean {
    return chat && chat.createdBy == this.userId();
  }

  public userNameByChatCreatedBy(chat: ChatDto): string {
    return this.appUsersService.userNameById(chat.createdBy);
  }


  // Relative UI helpers

  public chatUIShowCreatedBy(idx: number, chats: ChatDto[]): boolean {
    return ((idx == 0 || chats[idx].createdBy != chats[idx - 1].createdBy) && !this.chatUIIsOwn(chats[idx]));
  }

  public chatUIShowDate(data: ChatDto, idx: number, chats: ChatDto[]): string {
    var dateStr: string = null;

    var created: Date = new Date(data.created);
    var now: Date = new Date();
    var previous: Date = (idx > 0) ? new Date(chats[idx - 1].created) : now;
    var diffDaysNow: number = differenceInCalendarDays(created, now);
    var diffDaysPrev: number = differenceInCalendarDays(created, previous);
    var diffMinutesPrev: number = Math.abs(totalDifferenceInMinutes(created, previous));

    if ((idx == 0 && diffMinutesPrev > 5)  /* When first posted a little while ago */ ||
      (diffDaysNow == 0 && diffMinutesPrev > 5) ||
      (diffDaysNow != 0 && diffDaysPrev != 0)) {
      dateStr = this.localeService.formatDateRelative(created, previous);
    }
    return dateStr;
  }

  public chatUIListItemClass(data: ChatDto, idx: number, chats: ChatDto[]): string {
    var isOwn: boolean = this.chatUIIsOwn(data);
    var sameUserBefore: boolean = (idx > 0 && data.createdBy == chats[idx - 1].createdBy);
    var sameUserAfter: boolean = (idx < chats.length - 1 && data.createdBy == chats[idx + 1].createdBy);
    var baseclass: string = isOwn ? "own" : "other";
    var classes: string = "chat-list-item " + baseclass;
    if (sameUserBefore) {
      classes = classes + " " + baseclass + "-before";
    }
    if (sameUserAfter) {
      classes = classes + " " + baseclass + "-after";
    }
    return classes;
  }

}

// Angular
import { Component, ViewChild, LOCALE_ID, Inject, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from "@angular/platform-browser";

// Material design
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Grid
import { setCulture, debounce, Internationalization, EventHandler } from '@syncfusion/ej2-base';
import {
  EditSettingsModel, Column, ForeignKeyService, PageService, ColumnChooserService,
  ToolbarService, SortService, GroupService, ResizeService, ReorderService, AggregateService,
  ColumnMenuService, ContextMenuService, GroupSettingsModel, GridComponent, ExcelExportService, PdfExportService, ContextMenuItemModel,
  ExcelExportProperties, ContextMenuClickEventArgs, CommandModel, DetailRowService, ToolbarItems, IEditCell, ContextMenuOpenEventArgs, FilterService, EditService,
  CustomSummaryType, AggregateColumnModel, RowInfo,
} from '@syncfusion/ej2-angular-grids';
import { DataManager, WebApiAdaptor, Query, Predicate } from '@syncfusion/ej2-data';
import { FilteringEventArgs, ChangeEventArgs, DropDownList } from '@syncfusion/ej2-angular-dropdowns';
import { SplitterComponent } from '@syncfusion/ej2-angular-layouts';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { NumericTextBox } from '@syncfusion/ej2-angular-inputs';

// WebApi
import {
  VoucherViewClient as Client,
  VoucherMessageViewClient as MessageClient,
  VoucherUtilClient as UtilClient,
  VoucherViewVm as Vm,
  VoucherMessageViewDetailsClient as DetailsClient,
  VoucherMessageViewDetailsVm as DetailsVm,
  VoucherViewDto as VoucherDto,
  VoucherPostingViewDto as PostingDto,
  VoucherMessageViewDto as MessageDto,
  VoucherApprovalStatusDto as ApprovalStatusDto,
  VoucherApprovalStatusEnum,
  UserDto,
  VendorDto,
  VoucherBaseDataClient as BaseDataClient,
  VoucherBaseDataVm as BaseDataVm,
  CurrencyDto,
  VoucherPostingTypeDto,
  ProjectDto,
  ProjectCostTypeDto,
  VoucherPostingTypeEnum,
  FinanceLedgerAccountDto,
  VoucherMessageStatusEnum,
  DebtorDto,
  BankAccountDto,
  FinanceTaxCodeDto,
  VoucherViewDto
} from 'src/app/api';

//import { LookupTable, RecordSelectedEventArg } from './commerce-voucher-basedata.component';
import { VoucherHelper, VoucherService } from '../voucher.service';
import { AuthorizeService } from 'src/api-authorization/authorize.service';
import { CerAppChatService } from '../../../cer-app/cer-app-chat/cer-app-chat.service';
import { CerAppFileViewerTabComponent } from 'src/cer-app/cer-app-file-viewer-tab/cer-app-file-viewer-tab.component';
import { CerDropDownList } from 'src/cer/cer-control/cer-drop-down-list/cer-drop-down-list';
import { CerDialogService } from 'src/cer/cer-dialog/cer-dialog.service';
import { CerLocaleService } from 'src/cer/cer-locale/cer-locale.service';
import { CerEditDatePickerAdapter } from 'src/cer/cer-control/cer-date-edit/cer-edit-cell-date-picker-adapter';
import { VoucherDataService } from '../voucher-data.service';
import { VoucherAttachmentService } from '../voucher-attachment.service';
import { CerAppDialogService, DialogInput } from 'src/cer-app/cer-app-dialog/cer-app-dialog.service';
import { AppStateService } from 'src/app-core/app-state/app-state.service';

enum GridNavigationCmd {
  None,
  Default,
  VoucherCurrent,
  VoucherFirst,
  VoucherPrevious,
  VoucherNext,
  VoucherLast,
  PostingCurrent,
  PostingPrevious,
  PostingNext,
  PostingFirst,
  PostingLast
};

@Component({
  selector: 'voucher-detail',
  templateUrl: './voucher-detail.component.html',
  styleUrls: ['./voucher-detail.component.css'],
  providers: [VoucherService, PageService, EditService, ForeignKeyService, FilterService, SortService, GroupService, ResizeService, ReorderService,
    ToolbarService, ColumnChooserService, ColumnMenuService, ContextMenuService,
    ExcelExportService, PdfExportService, DetailRowService, AggregateService]
})

export class VoucherDetailComponent {

  // State
  protected pageIsLoading: boolean = true;
  protected isExpenseVoucher: boolean = false;
  protected attachmentsIsActive: boolean = false;
  protected attachmentSelectedIdxDefault: number = 0;
  protected isAnyVoucherPosted: boolean = false;
  protected isStatusColumnVisible: boolean = false;
  protected categoryVisible: boolean = false;
  protected canEdit: boolean = true;
  protected canNewApprover: boolean = false;

  private voucherGridIsDataBound: boolean = false;
  private postingGridIsDataBound: boolean = false;
  private gridNavigationCmd: GridNavigationCmd = GridNavigationCmd.Default;
  private voucherGridRequestEditIdx: number = -1;
  private postingGridRequestEditIdx: number = -1;
  private gridTryEdit: boolean = true;
  private gridCancelledConfirmed: GridComponent = null;
  //private dialogObj: Dialog = null;

  // Form dialog
  protected showChat: boolean = false;
  private callerMsgId: number = null;
  protected voucherCanPending: boolean = false;
  protected voucherCanSaveAndClose: boolean = true;

  private splitterObj: SplitterComponent;
  @ViewChild('splitterInstance', { static: false }) set splitterContent(splitterObj: SplitterComponent) {
    if (splitterObj && !this.splitterObj) {
      this.splitterObj = splitterObj;
      if (this.attachmentsIsActive) {
        this.splitterObj.paneSettings[1].collapsed = false;
      }
    }
  }

  // File viewer
  @ViewChild(CerAppFileViewerTabComponent) fileViewerTab: CerAppFileViewerTabComponent;

  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    this.handleKeyDownEvent(event);
  }

  // WebApi
  //public data: DataManager = null;
  private messageDto: MessageDto = null;
  protected voucherDtoList: VoucherDto[] = null;
  private voucherDtoActive: VoucherDto = null;
  protected postingDtoListAll: VoucherDto[] = null;
  protected postingDtoList: PostingDto[] = null;
  protected approvalStatusDto: ApprovalStatusDto[] = null;
  private detailsVmMap = new Map<number, DetailsVm>();
  protected detailsVm: DetailsVm = null;
  private baseDataVm: BaseDataVm = null;
  private users: UserDto[] = null;
  protected usersDM: DataManager;
  private userCurrent: UserDto = null;
  private taxCodes: FinanceTaxCodeDto[] = null;
  protected taxCodesDM: DataManager;
  private currencies: CurrencyDto[] = null;
  protected currenciesDM: DataManager;
  private postingTypes: VoucherPostingTypeDto[] = null;
  protected postingTypesDM: DataManager;
  private vendorsDefault: VendorDto[] = null;
  private debtorsDefault: DebtorDto[] = null;
  private projectsDefault: ProjectDto[] = null;
  private projectLedgerAccounts: FinanceLedgerAccountDto[] = null;
  private ledgerAccounts: FinanceLedgerAccountDto[] = null;
  protected ledgerAccountsDM: DataManager;
  private projectCostTypes: ProjectCostTypeDto[] = null;
  protected projectCostTypesDM: DataManager;
  private projectCostTypesFiltered: ProjectCostTypeDto[] = null;
  protected projectCostTypesFilteredDM: DataManager;
  private bankAccounts: BankAccountDto[] = null;
  protected userIsAdmin: boolean = false;
  protected dataIsReady: boolean = false;
  protected fromName: string = null;

  private voucherForcedCancel: boolean = false;
  protected voucherIsStatusCreated: boolean = false;
  protected voucherCanApprove: boolean = false;
  protected voucherCanApproveByPostingEdit: boolean = false;
  protected voucherCanHold: boolean = false;

  private postingDtoEdit: PostingDto = null;
  private postingAmountCurEditValue: number = null;

  private vendorPostingProformaDtoOrg: PostingDto = null;
  private projectPostingProformaDtoOrg: PostingDto = null;

  // Grid options
  // Formatting
  protected dateFormat: any = { type: "date", format: "dd.MM.yyyy" };
  protected dateTimeFormat: any = { type: "date", format: "dd.MM.yyyy HH:mm" };

  // Message grid
  private messageGrid: GridComponent;
  @ViewChild('messageGrid', { static: false }) set messageGridContent(content: GridComponent) {
    if (content && !this.messageGrid) {
      this.messageGrid = content;
    }
  }
  protected messageGridContextMenuItems: ContextMenuItemModel[] = [
    { id: 'gridColumnHide', text: "Skjul kolonne", iconCss: "e-menu-icon e-icons .e-icon-hide", target: ".e-headercontent" },
    { id: 'gridColumnsChoose', text: "Kolonner ..", iconCss: ".e-grid-menu e-icons .e-columnchooserdiv", target: ".e-headercontent" }
  ];


  // Voucher grid
  private voucherGrid: GridComponent;
  @ViewChild('voucherGrid', { static: false }) set voucherGridContent(content: GridComponent) {
    if (content && !this.voucherGrid) {
      this.voucherGrid = content;
      this.gridEditRowOnClickSetup(this.voucherGrid);
      var transDateCol: Column = this.voucherGrid.getColumnByField('transDate');
      new CerEditDatePickerAdapter(this.voucherGrid, transDateCol, this.localeService);
      var documentDateCol: Column = this.voucherGrid.getColumnByField('documentDate');
      new CerEditDatePickerAdapter(this.voucherGrid, documentDateCol, this.localeService);
    }
  }

  protected voucherGridToolbar: ToolbarItems[] | object = [
    { text: 'Vis vedhæftninger', tooltipText: 'Vis/skjul vedhæftninger (ALT+V)', id: 'attachmentsShowHide' },
    'Edit',
    'Update',
    'Cancel'//,
    //'Add',
    //'Delete'
  ];
  protected voucherGridGroupSettings: GroupSettingsModel = { disablePageWiseAggregates: true, showDropArea: false, showUngroupButton: true, showGroupedColumn: false };
  protected gridCommands: CommandModel[] = [
    { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
    { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } },
    { type: 'Save', buttonOption: { cssClass: 'e-flat', iconCss: 'e-update e-icons' } },
    { type: 'Cancel', buttonOption: { cssClass: 'e-flat', iconCss: 'e-cancel-icon e-icons' } }];
  protected voucherGridEditSettings: EditSettingsModel = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal', showDeleteConfirmDialog: true, newRowPosition: 'Bottom' };
  protected voucherGridContextMenuItems: ContextMenuItemModel[] = [
    { id: 'gridColumnGroup', text: "Grupper", iconCss: "e-menu-icon e-icons e-icon-group", target: ".e-headercontent" },
    { id: 'gridColumnHide', text: "Skjul kolonne", iconCss: "e-menu-icon e-icons e-icon-hide", target: ".e-headercontent" },
    { id: 'gridColumnsChoose', text: "Kolonner ..", iconCss: "e-grid-menu e-icons e-columnchooserdiv", target: ".e-headercontent" },
    { separator: true, target: ".e-headercontent" },
    { id: 'gridExcelExport', text: "Excel eksport", iconCss: "e-menu-icon e-icons e-excelexport", target: ".e-headercontent" },
    { separator: true, target: ".e-headercontent" },
    { id: 'dataBind', text: "Genlæs data", iconCss: "e-menu-icon e-icons e-reload-01", target: ".e-headercontent" },
    { id: 'setStatusPending', text: "Sæt status 'Til godkendelse'", iconCss: "e-menu-icon e-icons e-document-01", target: ".e-content" },
    { id: 'setStatusOnHold', text: "Sæt status 'Afventer'", iconCss: "e-menu-icon e-icons e-document-01", target: ".e-content" },
    { id: 'setStatusApproved', text: "Sæt status 'Godkendt'", iconCss: "e-menu-icon e-icons e-document-01", target: ".e-content" },
    { id: 'setStatusAccounted', text: "Sæt status 'Afsluttet'", iconCss: "e-menu-icon e-icons e-close-01", target: ".e-content" },
    { id: 'setStatusPosted', text: "Sæt status 'Bogført'", iconCss: "e-menu-icon e-icons e-document-01", target: ".e-content" }];

  // Posting grid
  private postingGrid: GridComponent;
  private postingGridAutoGroupDone: boolean = false;
  @ViewChild('postingGrid', { static: false }) set postingGridContent(content: GridComponent) {
    if (content && !this.postingGrid) {
      this.postingGrid = content;
    }
  }
  protected postingGridEditSettings: EditSettingsModel = {
    allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal', showConfirmDialog: false,
    showDeleteConfirmDialog: true, newRowPosition: 'Bottom'
  };
  protected postingGridGroupSettings: GroupSettingsModel = {/* disablePageWiseAggregates: true,*/ showDropArea: false, showUngroupButton: true, showGroupedColumn: false };
  protected postingGridToolbar: ToolbarItems[] | object = [
    { text: 'Fordel beløb', tooltipText: 'Fordel beløb konteret til at matche fakturabeløb', id: 'postingAdjustAmounts' },
    'Edit',
    'Update',
    'Add',
    'Delete'
  ];
  protected postingGridContextMenuItems: ContextMenuItemModel[] = [
    { id: 'gridColumnGroup', text: "Grupper", iconCss: "e-menu-icon e-icons e-icon-group", target: ".e-headercontent" },
    { id: 'gridColumnUnGroup', text: "Fjern grupper", iconCss: "e-menu-icon e-icons e-icon-group", target: ".e-headercontent" },
    { id: 'gridColumnHide', text: "Skjul kolonne", iconCss: "e-menu-icon e-icons e-icon-hide", target: ".e-headercontent" },
    { id: 'gridColumnsChoose', text: "Kolonner ..", iconCss: "e-grid-menu e-icons e-columnchooserdiv", target: ".e-headercontent" },
    { separator: true, target: ".e-headercontent" },
    { id: 'gridExcelExport', text: "Excel eksport", iconCss: "e-menu-icon e-icons e-excelexport", target: ".e-headercontent" }
  ];

  // Dialog
  //@ViewChild('dialogInfo')
  //public dialogInfo: DialogComponent;
  //public dialogInfoButtons: Object = [{
  //  click: (args: any) => { this.dialogInfo.hide(); },
  //  buttonModel: { content: "Ok", isPrimary: true }
  //}];
  //public dialogComponent: Dialog = null;

  // Controls
  private balanceAccountNumElement: HTMLInputElement;
  private documentNumElement: HTMLInputElement;

  private authToken: string;  // Used in data manager headers

  protected voucherVendorAccountNumEditCell: IEditCell;
  private voucherVendorAccountNumDdl: CerDropDownList;
  private voucherVendorAccountNumDdlElement: HTMLInputElement;

  protected voucherProjectNumEditCell: IEditCell;
  private voucherProjectNumDdl: CerDropDownList;
  private voucherProjectNumDdlElement: HTMLInputElement;

  protected currencyEditCell: IEditCell;
  private currencyDdl: CerDropDownList;
  private currencyDdlElement: HTMLInputElement;

  protected approverEditCell: IEditCell;
  private approverDdl: CerDropDownList;
  private approverDdlElement: HTMLInputElement;

  protected postingTypeEditCell: IEditCell;
  private postingTypeDdl: CerDropDownList;
  private postingTypeDdlElement: HTMLInputElement;

  protected accountNumEditCell: IEditCell;
  private accountNumDdl: CerDropDownList;
  private accountNumDdlElement: HTMLInputElement;
  private accountNumEditDdlCurrentTableName: string = '';
  private accountNumFilterIsExecuting: boolean = false;
  private accountNumFilterArgsQueued: FilteringEventArgs = null;
  private accountnNumFilterDefaultListData: object[] = null;

  private projectCostTypeDdl: CerDropDownList;
  public projectCostTypeEditCell: IEditCell;
  private projectCostTypeDdlElement: HTMLInputElement;

  private projectLedgerNumDdl: CerDropDownList;
  public projectLedgerNumEditCell: IEditCell;
  private projectLedgerNumDdlElement: HTMLInputElement;

  private taxCodeDdl: CerDropDownList;
  public taxCodeEditCell: IEditCell;
  private taxCodeDdlElement: HTMLInputElement;

  public avoidFocusOnVendor: boolean = false;

  // Constructor
  constructor(private authorize: AuthorizeService,
    private client: Client, private messageClient: MessageClient, private detailsClient: DetailsClient, private baseDataClient: BaseDataClient,
    private utilClient: UtilClient,
    private router: Router, private chatService: CerAppChatService,
    private stateService: AppStateService, private dialogService: CerDialogService, private ui: CerAppDialogService,
    private localeService: CerLocaleService, public dialog: MatDialog, private dialogRef: MatDialogRef<VoucherDetailComponent>,
    public sanitizer: DomSanitizer, private voucherService: VoucherService, @Inject(MAT_DIALOG_DATA) callerData: any, @Inject(LOCALE_ID) locale: string) {

    setCulture(locale);

    if (callerData != null) {
      this.callerMsgId = callerData.dto.msgId ? callerData.dto.msgId : callerData.dto.id; // Called from 1) voucher or 2) message
      this.userIsAdmin = callerData.userIsAdmin;
      this.attachmentsIsActive = (callerData.attachmentsIsActive || callerData.showChat);
      this.showChat = callerData.showChat;
      this.avoidFocusOnVendor = this.attachmentsIsActive;
    }

    if (!this.userIsAdmin) {
      this.voucherGridContextMenuItems = this.voucherGridContextMenuItems.filter(mi => !['setStatusApproved', 'setStatusAccounted', 'setStatusPosted'].includes(mi.id));
    }

  };

  ngOnInit(): void {
    this.chatService.chats$.subscribe(chat => {
      VoucherDataService.detailsVmMapRefreshChat(this.detailsVmMap, this.chatService, chat);
      this.dataBindCalcStatus();
    });

    this.authorize.getAccessToken().subscribe(
      token => this.authToken = token
    );

    if (this.callerMsgId != null) {
      this.detailsVmDataBind(true);
      this.baseDataVmDataBind();
    }
  }

  /*
  private pageIsLoadingUpdate() {
    this.pageIsLoading = (this.messageDtoList == null);
  }
  */

  // Router
  private routerShortUrl(): string {
    let routeShortUrl = this.router.url;
    while (routeShortUrl.startsWith('/')) {
      routeShortUrl = routeShortUrl.substr(1);
    }
    return routeShortUrl;
  }

  private async baseDataVmDataBind() {
    this.baseDataClient.get().subscribe(
      result => {
        this.baseDataVm = result;
        this.users = this.baseDataVm.users;
        this.usersDM = new DataManager(this.users);
        this.userCurrent = this.baseDataVm.userCurrent;
        this.taxCodes = this.baseDataVm.taxCodes;
        this.taxCodesDM = new DataManager(this.taxCodes);
        this.currencies = this.baseDataVm.currencies;
        this.currenciesDM = new DataManager(this.currencies)
        this.postingTypes = this.baseDataVm.postingTypes;
        this.postingTypesDM = new DataManager(this.postingTypes);

        this.vendorsDefault = this.baseDataVm.vendorsDefault;
        this.debtorsDefault = this.baseDataVm.debtorsDefault;
        this.projectsDefault = this.baseDataVm.projectsDefault;
        this.projectLedgerAccounts = this.baseDataVm.projectLedgerAccounts;
        this.ledgerAccounts = this.baseDataVm.ledgerAccounts;
        this.ledgerAccountsDM = new DataManager(this.ledgerAccounts);
        this.bankAccounts = this.baseDataVm.bankAccounts;
        this.bankAccounts.forEach(b => b.description = b.description ?? b.bankAccountNum);
        this.projectCostTypes = this.baseDataVm.projectCostTypes;
        this.projectCostTypes.push(new ProjectCostTypeDto({ id: null, num: '', name: '', description: '', costOnly: true }));
        this.projectCostTypesDM = new DataManager(this.projectCostTypes);

        if (this.baseDataVm && this.detailsVm) {
          this.setAttachmentSelectextIdxDefault();
          this.dataBindGrids(this.getLastVoucherId(this.detailsVm.voucherViewDtoList));
        }
      },
      error => {
        this.error(["Indlæsning af godkendelse fejlet", error]);
      });
  }

  // DetailsVm (including attachments etc)
  private detailsVmReBind(detailsVmNew: DetailsVm, focusVoucherId: number, gridDataBind: boolean = true) {
    this.detailsVm = detailsVmNew;
    this.detailsVmMap.set(this.detailsVm.voucherMessageViewDto.id, this.detailsVm);
    if (gridDataBind) {
      this.dataBindGrids(focusVoucherId);
    }
  }

  private setAttachmentSelectextIdxDefault() {
    var idx: number = 0;
    var i: number = 0;
    if (this.showChat) {
      this.attachmentSelectedIdxDefault = this.detailsVm.voucherMessageAttachmentDtoList.length;
    }
    else {
      this.detailsVm.voucherMessageAttachmentDtoList.forEach(a => { if (idx == 0 && a.fileExtension.toLocaleLowerCase() == '.pdf') { idx = i }; i++; });
      this.attachmentSelectedIdxDefault = idx;
    }
  }

  private detailsVmDataBind(initialLoad: boolean = false) {
    this.voucherGridIsDataBound = false;
    this.postingGridIsDataBound = false;
    var msgId: number = this.callerMsgId;
    if (msgId) {
      if (this.detailsVmMap.has(msgId)) {
        this.detailsVm = this.detailsVmMap.get(msgId);
        if (initialLoad) {
          this.setAttachmentSelectextIdxDefault();
        }
        this.dataBindGrids(this.getLastVoucherId(this.detailsVm.voucherViewDtoList));
      }
      else {
        this.detailsVmGetPromise(msgId);
      }
    }
  }

  private detailsVmGetPromise(msgId: number, focusVoucherId: number = null): Promise<DetailsVm> {
    return new Promise<DetailsVm>((resolve) => {
      this.apiDetailsVmGet(msgId).then((detailsVm) => {
        this.detailsVmBindNonCached(msgId, detailsVm, focusVoucherId);
        resolve(detailsVm);
      });
    });
  }

  private apiDetailsVmGet(msgId: number): Promise<DetailsVm> {
    return new Promise<DetailsVm>((resolve, reject) => {
      this.detailsClient.get(msgId).subscribe({
        next: (detailsVm) => { resolve(detailsVm); },
        error: (error) => {
          this.dialogService.snackBar("Indlæsning af stamdata fejlet : " + error);
          reject(error);
        }
      });
    });
  }

  private detailsVmBindNonCached(msgId: number, detailsVm: DetailsVm, focusVoucherId: number = null) {
    this.voucherService.prepareDetailsVm(detailsVm);
    this.detailsVm = detailsVm;
    this.detailsVmMap.set(msgId, this.detailsVm);
    if (this.detailsVm && this.baseDataVm) {
      this.dataBindGrids(focusVoucherId ? focusVoucherId : this.getLastVoucherId(this.detailsVm.voucherViewDtoList));
    }
  }

  public dataBindGrids(focusVoucherId: number) {
    if (this.detailsVm && this.baseDataVm) {
      this.dataIsReady = true;
      this.isExpenseVoucher = this.voucherService.isExpenseVoucher(this.detailsVm.voucherMessageChannelType);
      this.voucherDtoList = this.detailsVm.voucherViewDtoList;
      this.postingDtoListAll = this.detailsVm.voucherPostingViewDtoList;
      this.projectsDefault = this.baseDataVm.projectsDefault;

      var voucherDto: VoucherViewDto = this.voucherDtoList.length > 0 ? this.voucherDtoList[0] : null;
      this.messageDto = this.detailsVm.voucherMessageViewDto;

      this.canNewApprover = (!this.userIsAdmin && voucherDto?.status == VoucherApprovalStatusEnum.Pending);
      this.voucherCanPending = ((voucherDto?.status == VoucherApprovalStatusEnum.Created || this.messageDto?.status == VoucherMessageStatusEnum.Inbox) && this.userIsAdmin);
      this.voucherCanSaveAndClose = true;
      if (this.voucherCanPending && this.voucherService.isVendorVoucher(this.detailsVm.voucherMessageChannelType)) {
        this.voucherCanSaveAndClose = false;
      }

      if (!this.projectCostTypesFiltered) {
        this.projectCostTypesFiltered = this.isExpenseVoucher ? this.projectCostTypes : this.projectCostTypes.filter(c => c.costOnly);
        this.projectCostTypesFilteredDM = new DataManager(this.projectCostTypesFiltered);
      }

      //this.postingDtoListAll = Object.assign({}, this.detailsVm.voucherPostingViewDtoList);
      if (!this.userIsAdmin) {
        var that = this;
        this.postingDtoList = this.detailsVm.voucherPostingViewDtoList.filter(p => !that.isPostingTypeVendor(p.postingType))
      }
      else {
        this.postingDtoList = this.detailsVm.voucherPostingViewDtoList;
      }

      this.dataBindCalcStatus();

      this.vendorsDefault = this.baseDataVm.vendorsDefault;
      this.detailsVm.vendorsSelected.forEach(s => {
        if (!this.vendorsDefault.find(d => d.num == s.num)) {
          this.vendorsDefault.unshift(s);
        }
      });

      this.categoryVisible = (this.isExpenseVoucher);

      this.debtorsDefault = this.baseDataVm.debtorsDefault;
      this.detailsVm.debtorsSelected.forEach(s => {
        if (!this.debtorsDefault.find(d => d.num == s.num)) {
          this.debtorsDefault.unshift(s);
        }
      });

      this.projectsDefault = this.baseDataVm.projectsDefault;
      this.detailsVm.projectsSelected.forEach(s => {
        if (!this.projectsDefault.find(d => d.num == s.num)) {
          this.projectsDefault.unshift(s);
        }
      });

      this.voucherVendorAccountNumEditCellSetup(); // Voucer vendor account num
      this.voucherProjectNumEditCellSetup();  // Voucher project num
      this.currencyEditCellSetup(); // Currency
      this.approverEditCellSetup();  // User (approver)
      this.postingTypeEditCellSetup();  // Posting type editor
      this.accountNumEditCellSetup();   // Account num editor
      this.projectCostTypeEditCellSetup(); // Cost type editor
      this.projectLedgerNumEditCellSetup(); // Project ledger num editor
      this.taxCodeEditCellSetup();  // Tax code

      this.setFromName();

      if (focusVoucherId && (this.userIsAdmin || voucherDto.status == VoucherApprovalStatusEnum.Created)) {
        let idx = this.voucherDtoList.findIndex(v => v.id == focusVoucherId);
        if (idx >= 0) {
          this.gridNavigationCmd = GridNavigationCmd.VoucherCurrent;
          this.voucherGridRequestEditIdx = idx;
        }
      }
    }
  }

  private setFromName(data: any = null) {
    this.fromName = this.voucherTitleName(this.messageDto, this.voucherDtoList, data);
  }

  private isPostingTypeProject(postingType: number): boolean {
    return this.voucherService.isPostingTypeProject(postingType);
  }

  private isPostingTypeLedger(postingType: number): boolean {
    return this.voucherService.isPostingTypeLedger(postingType);
  }

  private isPostingTypeVendor(postingType: number): boolean {
    return this.voucherService.isPostingTypeVendor(postingType);
  }

  private voucherTitleName(msgDto: MessageDto, voucherList: VoucherDto[], vendorDto: any): string {
    var fromName: string = null;
    var vendorName: string = null;
    if (this.userIsAdmin) {
      if (msgDto) {
        // FromName
        fromName = '';  //strTrim(msgDto.fromEmail ? msgDto.fromEmail : '') + " " + (msgDto.fromName ? '(' + msgDto.fromName + ')' : '');
        if (msgDto.fromEmail?.length > 0) {
          var fromUsers: UserDto[] = this.users.filter(u => u.email == msgDto.fromEmail);
          if (fromUsers?.length > 0) {
            fromName = fromUsers[0].shortName;
          }
        }

        // Vendor name
        var balanceName: string = null;
        if (vendorDto) {
          vendorName = vendorDto.description;
        }
        else {
          var accountNum = '';
          var vouchers = voucherList?.filter(v => v.balanceAccountDescription?.length > 0);
          if (vouchers.length > 0) {
            accountNum = vouchers[0].balanceAccountNum;
          }
          if (this.voucherGrid?.isEdit) {
            var balanceNumCtrl = this.voucherGridEditFormInputElementGetByColumnName('balanceAccountNum');
            if (balanceNumCtrl) {
              accountNum = balanceNumCtrl.value;
            }
          }
          if (accountNum?.length > 0) {
            var vendors = this.vendorsDefault?.filter(v => v.num == accountNum);
            if (vendors.length > 0) {
              vendorName = vendors[0].description;
            }
          }
        }
      }
      var name = '';
      if (fromName?.length > 0) {
        name = fromName;
      }
      if (vendorName?.length > 0) {
        name = (name.length > 0 ? name + ' / ' : '') + vendorName;
      }
    }
    return name;
  }

  private dataBindCalcStatus() {
    //this.userIsAdmin = this.detailsVm.userIsAdmin;

    this.isAnyVoucherPosted = false;
    this.isStatusColumnVisible = false;
    this.voucherCanApprove = false;
    this.voucherCanHold = false;
    //this.isAllApproved = false;
    this.canEdit = true;

    var isIncomplete: boolean = false;
    this.voucherDtoList.forEach(v => {
      if (this.voucherService.IsStatusPosted(v.status)) {
        this.isAnyVoucherPosted = true;
      }
      if (this.voucherService.IsStatusNonCreatedOrPendingOrOnHold(v.status)) {
        this.isStatusColumnVisible = true;
      }
      if (v.approver == this.userCurrent.id || (v.approver == null && this.userIsAdmin)) {
        if (!this.voucherService.IsStatusNonCreatedOrPendingOrOnHold(v.status)) {
          this.voucherCanApprove = true;
          if (!this.voucherService.voucherIsComplete(v, this.userIsAdmin, this.isExpenseVoucher)) {
            isIncomplete = true;
          }
          this.voucherCanHold = this.voucherService.IsStatusPendingOrCreated(v.status);
        }
        if (this.voucherService.IsStatusApproved(v.status)) {
          this.voucherCanHold = true;
        }
      }
      if (this.voucherService.IsStatusAccountedOrPosted(v.status)) {
        this.canEdit = false;
      }
    });

    this.voucherCanApproveByPostingEdit = (this.voucherCanApprove && !isIncomplete);

    var amountSum: number = 0;
    this.postingDtoListAll.forEach(p => {
      if (!this.voucherService.postingIsComplete(p, this.userIsAdmin)) {
        isIncomplete = true;
      }
      amountSum += p.amountCur;
    });
    if (Math.abs(amountSum) > 0.001) {
      isIncomplete = true;
    }

    this.voucherCanApprove = this.voucherCanApprove && !isIncomplete;
    this.voucherCanHold = this.voucherCanHold && !this.userIsAdmin;

    if (!this.voucherCanApprove && !this.userIsAdmin) {
      this.voucherCanApprove = (this.detailsVm && this.detailsVm.chatDtoList && this.detailsVm.chatDtoList.length > 0);
    }

    this.voucherIsStatusCreated =
      this.voucherDtoList?.filter(v => v.status == VoucherApprovalStatusEnum.Created)?.length > 0;
    this.voucherGridEditSettings.allowEditing = this.canEdit && (this.userIsAdmin || this.voucherIsStatusCreated);
    this.voucherGridEditSettings.allowAdding = false;
    this.voucherGridEditSettings.allowDeleting = false;
    this.postingGridEditSettings.allowEditing = this.canEdit;
  }

  private voucherCanApproveByPostingEditCalc(postingEditDataPassed: PostingDto = null) {
    if (this.voucherCanApproveByPostingEdit) {

      var amountSum: number = 0;
      var isIncomplete = false;


      var postingDtoFull: PostingDto = new PostingDto();
      Object.assign(postingDtoFull, this.postingDtoEdit);

      var postingEditData: PostingDto = (postingEditDataPassed != null) ? postingEditDataPassed : this.postingGrid.isEdit ? this.gridEditDataGet(this.postingGrid) : null;
      if (postingEditData != null) {
        Object.assign(postingDtoFull, postingEditData);
      }

      this.postingDtoListAll.forEach(p => {
        if (!postingDtoFull || p.id != postingDtoFull.id) {
          if (!this.voucherService.postingIsComplete(p, this.userIsAdmin)) {
            isIncomplete = true;
          }
          amountSum += p.amountCur;
        }
      });

      if (postingDtoFull && !this.voucherService.postingIsComplete(postingDtoFull, this.userIsAdmin)) {
        isIncomplete = true;
      }
      amountSum += postingDtoFull.amountCur;

      console.log(amountSum);
      if (amountSum != 0) {
        isIncomplete = true;
      }
      this.postingGrid.aggregateModule.refresh(postingEditData);

      this.voucherCanApprove = !isIncomplete;
    }
  }

  /*
  private voucherInitFromMessage(dto: VoucherDto): boolean {
    let ok: boolean = false;
    let messageDto = this.messageDtoList[0];
    if (messageDto.id) {
      ok = true;
      dto.msgId = messageDto.id;
      let dtoPrev: VoucherDto = this.voucherGridRowActive();
      if (dtoPrev) {
        dto.approver = dtoPrev.approver;
        dto.approverName = dtoPrev.approverName;
        dto.approverShortName = dtoPrev.approverShortName;
        dto.currency = dtoPrev.currency;
        dto.currencyISO = dtoPrev.currencyISO;
        dto.currencyName = dtoPrev.currencyName;
        dto.documentDate = dtoPrev.documentDate;
        dto.documentNum = dtoPrev.documentNum;
        dto.notes = dtoPrev.notes;
        dto.recieved = dtoPrev.recieved;
        dto.status = dtoPrev.status;
        dto.statusName = dtoPrev.statusName;
        dto.transDate = dtoPrev.transDate;
        dto.txt = dtoPrev.txt;
        dto.voucherNum = dtoPrev.voucherNum;
        dto.channelNum = dto.channelNum;
        dto.channelName = dto.channelName;
      }
      else {
        dto.txt = messageDto.subject;
        dto.recieved = messageDto.recieved;
        dto.transDate = messageDto.recieved;
        dto.channelNum = messageDto.channelNum;
        dto.channelName = messageDto.channelName;
      }
    }
    return ok;
  }
  */

  protected postingGridLoad(args: any) {
    this.gridEditRowOnClickSetup(this.postingGrid);
  }

  /*
  private voucherGridLoad(args: any) {
  }
  */

  private gridEditRowOnClickSetup(grid: GridComponent) {
    grid.element.addEventListener('mouseup', (e: MouseEvent) => {
      this.gridEditRowOnClick(grid, e);
    });
  }

  private gridEditRowOnClick(grid: GridComponent, e: MouseEvent) {
    if ((e.target as HTMLElement).classList.contains("e-rowcell")) {
      let rowInfo = grid.getRowInfo(e.target);
      this.gridEditRow(grid, rowInfo);
    };
  }
  private gridEditRow(grid: GridComponent, rowInfo: RowInfo) {
    if (grid) {

      /*        console.log(rowInfo.column);
            if (grid.isEdit) {
   
              //let editData = this.gridEditDataGet(grid);
              //if (!this.isDataUnchanged(editData, rowInfo.rowData)) {  // TODO!!!
              //}
              //grid.endEdit();
              //else
              grid.closeEdit();
            }
            //if (rowInfo.column.field === "DShipCountry")
            //  this.isDropdown = true;
            //
            console.log(rowInfo.column);
          }
          */
      grid.selectRow(rowInfo.rowIndex);
      if (grid.editSettings.allowEditing) {
        grid.startEdit();
      }

    }
  }

  private gridEditFormElementGet(grid: GridComponent): HTMLFormElement {
    return grid.editModule.formObj.element;
  }

  private gridEditDataGet(grid: GridComponent): any {
    var editData: any = null;
    if (grid && grid.editModule) {
      editData = grid.editModule.getCurrentEditedData(this.gridEditFormElementGet(grid), {});
    }
    return editData;
  }

  protected voucherGridRowSelected(args: any) {
    this.voucherDtoActive = args.data;
    //console.log("Voucher row selected");
    this.gridNavigationCmd = GridNavigationCmd.VoucherCurrent;

    if (this.gridTryEdit && this.voucherGrid.editSettings.allowEditing) {
      this.gridTryEdit = false;
      this.voucherGrid.startEdit();
    }
    //this.voucherGridRequestEditIdx =
    //if (this.postingGrid.isEdit) {
    //  this.postingGrid.endEdit();
    //}
    //else if (this.voucherGridIsDataBound) {
    //  console.log("Voucher row selected start edit");
    //  this.gridStartEdit();
    //}
  }

  protected postingGridRowSelected(args: any) {
    //console.log("Posting row selected");
    this.gridNavigationCmd = GridNavigationCmd.PostingCurrent;
    if (this.voucherGrid?.isEdit) {
      this.voucherGrid.endEdit();
    }
    if (this.gridTryEdit && this.postingGrid.editSettings.allowEditing) {
      this.gridTryEdit = false;
      this.postingGrid.startEdit();
    }

    //if (this.postingGridIsDataBound) {
    //  this.gridStartEdit();
    //}
  }

  private voucherSave(dto: VoucherDto) {
    this.voucherGridIsDataBound = false;
    this.postingGridIsDataBound = false;
    if (dto.msgId) {
      if (dto.id) {
        this.apiVoucherPutPromise(dto).then(detailsVm => { this.voucherSavePost(detailsVm, dto); });
      }
    }
    else {
      let voucherDto = new VoucherDto();
      for (var c in dto) {
        (<any>voucherDto)[c] = (<any>dto)[c];
      }
      voucherDto.id = 0;
      this.apiVoucherPostPromise(dto).then(detailsVm => { this.voucherSavePost(detailsVm, dto); });
    }
  }

  private voucherApprovalStatusSetDeferred: VoucherApprovalStatusEnum = null;  // Set status after save complete

  private voucherSavePost(detailsVm: DetailsVm, voucherDtoUpdated: VoucherDto) {
    if (this.voucherApprovalStatusSetDeferred) {
      this.voucherApprovalStatusSetDeferred = null;
      this.setApprovalStatusPromise(voucherDtoUpdated?.id, this.voucherApprovalStatusSetDeferred).then(dtoUpdated => {
        this.detailsVmReBind(detailsVm, voucherDtoUpdated.id);
        this.voucherGridEndEdit(voucherDtoUpdated);
      });
    }
    else {
      this.detailsVmReBind(detailsVm, voucherDtoUpdated.id);
      this.voucherGridEndEdit(voucherDtoUpdated)
    }
  }

  private voucherGridEndEdit(voucherDtoUpdated: VoucherDto) {
    if (this.voucherGrid.isEdit) {
      this.voucherForcedCancel = true;
      this.voucherGrid.editModule.closeEdit(); //cancel the edit 
    }
    this.voucherGrid.dataSource = this.voucherDtoList;
    this.postingGrid.dataSource = this.postingDtoList;
    this.voucherGrid.setRowData(voucherDtoUpdated.id, voucherDtoUpdated);
    this.saveAndCloseContinue();
  }

  private apiVoucherPutPromise(dto: VoucherDto): Promise<DetailsVm> {
    return new Promise<DetailsVm>((resolve, reject) => {
      this.detailsClient.putVoucher(dto.msgId, dto.id, dto).subscribe({
        next: (detailsVm: DetailsVm) => {
          resolve(detailsVm);
        },
        error: (error) => {
          this.error(["Kan ikke opdatere godkendelse", error]);
          reject(error);
        }
      });
    });
  }

  private apiVoucherPostPromise(dto: VoucherDto): Promise<DetailsVm> {
    return new Promise<DetailsVm>((resolve, reject) => {
      this.detailsClient.postVoucher(dto.msgId, dto).subscribe({
        next: (detailsVm: DetailsVm) => {
          resolve(detailsVm);
        },
        error: (error) => {
          this.error(["Kan ikke oprette godkendelse", error]);
        }
      });
    });
  }

  /*
    private voucherDelete(dtoList: VoucherDto[]) {
      for (var dto of dtoList) {
        if (dto.msgId && dto.id) {
          this.detailsClient.deleteVoucher(dto.msgId, dto.id).subscribe(result => {
            this.detailsVm = result;
            this.detailsVmMap.set(this.detailsVm.voucherMessageViewDto.id, this.detailsVm);
            this.dataBindGrids(this.getLastVoucherId(this.detailsVm.voucherViewDtoList));
          },
            error => {
              this.error(["Fejl ved sletning af godkendelse", error]);
            });
        }
      }
    }
  */

  private getLastVoucherId(dtoList: VoucherDto[]): number {
    var lastVoucherId: number = null;
    if (dtoList && dtoList.length > 0) {
      lastVoucherId = dtoList[dtoList.length - 1].id;
    }
    return lastVoucherId;
  }

  private postingInitFromVoucher(dto: PostingDto): boolean {
    let ok: boolean = false;
    let voucherDto = this.voucherGridRowActive();
    if (voucherDto.id) {
      ok = true;
      dto.id = Number.MAX_SAFE_INTEGER;
      dto.postingType = this.isExpenseVoucher ? VoucherPostingTypeEnum.Ledger : VoucherPostingTypeEnum.Project;
      dto.msgId = voucherDto.msgId;
      dto.voucherId = voucherDto.id;
      dto.txt = voucherDto.txt;
      dto.amountCur = voucherDto.amountCur;
      dto.transDate = voucherDto.transDate;

      let dtoPrev: PostingDto = this.postingGridLastRow(voucherDto);
      if (dtoPrev) {
        dto.postingType = this.isExpenseVoucher ? VoucherPostingTypeEnum.Ledger : VoucherPostingTypeEnum.Project;
        dto.lineNum = dtoPrev.lineNum + 1;
      }
      else {
        dto.postingType = this.isExpenseVoucher ? VoucherPostingTypeEnum.Bank : VoucherPostingTypeEnum.Vendor;
        dto.lineNum = 1;
      }
    }
    return ok;
  }

  private clone(from: any, to: any) {
    to = structuredClone(from);
    /*
    for (var attribut in from) {
      if (typeof from[attribut] === "object" && from[attribut] != null) {
        to[attribut] = from[attribut].clone();
      } else {
        to[attribut] = from[attribut];
      }
    }
    */
  }

  private postingAdjustAmounts() {
    var _this = this;
    this.dialogService.confirmPrompt("Fordel beløb konteret til at matche fakturabeløb?").then(ok => {
      if (ok)
        _this.postingAdjustAmountsApi();
    }
    );
  }

  private postingAdjustAmountsApi() {
    var msgId: number = this.detailsVm.voucherMessageViewDto.id;
    this.utilClient.adjustPostingAmountToVoucher(msgId).subscribe(
      result => {
        this.detailsVm = result;
        this.detailsVmMap.set(msgId, this.detailsVm);
        this.dataBindGrids(this.getLastVoucherId(this.detailsVm.voucherViewDtoList));
      },
      error => {
        this.error(["Kan ikke fordele konteringslinjer", error]);
      });
  }

  private postingSave(dto: PostingDto, gridRowIdx: number) {
    if (dto.msgId) {
      if (dto.id && dto.id != Number.MAX_SAFE_INTEGER) {
        this.detailsClient.putPosting(dto.msgId, dto.id, dto).subscribe({
          next: (result) => {
            this.postingPutResultApply(result, dto);
            this.postingSavePost(gridRowIdx);
          },
          error: (error) => {
            this.error(["Kan ikke opdatere konteringslinje", error]);
          }
        });
      }
      else {
        this.detailsClient.postPosting(dto.msgId, dto).subscribe({
          next: (result) => {
            this.postingPostResultApply(dto, result);
            this.gridNavigationCmd = GridNavigationCmd.PostingLast;
            this.postingSavePost(gridRowIdx)
          },
          error: (error) => {
            this.error(["Kan ikke oprette konteringslinje", error]);
          }
        });
      }
    }
  }

  private postingDelete(dtoList: PostingDto[]) {
    for (var dto of dtoList) {
      this.detailsClient.deletePosting(dto.msgId, dto.id).subscribe(
        result => {
          this.postingDeleteResultApply(dto);
          this.gridNavigationCmd = GridNavigationCmd.PostingLast;
          this.postingGridRefresh(-1);
        },
        error => {
          this.error(["Kan ikke slette linje", error]);
        });
    }
  }

  private postingPostResultApply(dto: PostingDto, result: PostingDto) {
    var idx = this.postingDtoList.findIndex(function (element) { return element.id === dto.id; });
    if (idx !== -1) {
      this.postingDtoList.splice(idx, 1);
    }
    this.postingDtoList.push(result);

    if (!this.userIsAdmin) {
      var idx = this.postingDtoListAll.findIndex(function (element) { return element.id === dto.id; });
      if (idx !== -1) {
        this.postingDtoListAll.splice(idx, 1);
      }
      this.postingDtoListAll.push(result);
    }
  }

  private postingPutResultApply(result: PostingDto, dto: PostingDto) {
    this.clone(result, dto);
    this.postingGrid.setRowData(dto.id, dto);
    var idx = this.postingDtoList.findIndex(function (element) { return element.id === result.id; });
    if (idx !== -1) {
      this.postingDtoList.splice(idx, 1, dto);
    }
    else {
      this.postingDtoList.push(dto);
    }
    idx = this.postingDtoListAll.findIndex(function (element) { return element.id === result.id; });
    if (idx !== -1) {
      this.postingDtoListAll.splice(idx, 1, dto);
    }
    else {
      this.postingDtoListAll.push(dto);
    }
  }

  private postingSavePost(gridRowIdx: number) {
    this.dataBindCalcStatus();
    this.postingGridRefresh(gridRowIdx);
    this.saveAndCloseContinue();
  }

  private postingDeleteResultApply(dto: PostingDto) {
    var idx = this.postingDtoList.findIndex(function (element) { return element.id === dto.id; });
    if (idx !== -1) {
      this.postingDtoList.splice(idx, 1);
    }

    idx = this.postingDtoListAll.findIndex(function (element) { return element.id === dto.id; });
    if (idx !== -1) {
      this.postingDtoListAll.splice(idx, 1);
    }
  }

  private postingAmountCurAggregateRow(row: PostingDto): number {
    var amountCur: number = row.amountCur;
    if (this.postingGrid.isEdit && this.postingDtoEdit && this.postingDtoEdit.id == row.id && this.postingAmountCurEditValue) {
      amountCur = this.postingAmountCurEditValue;
    }
    return amountCur;
  }

  protected postingAmountCurAggregate: CustomSummaryType = (data: any, column: AggregateColumnModel) => {
    var credit: number = 0;
    var debit: number = 0;
    var vendor: number = 0;
    var voucher: number = 0;

    if (this.voucherDtoList) {
      this.voucherDtoList.filter((row: VoucherDto) => { voucher += row.amountCur; });
    }

    //if (data.result.records) {
    //  data.result.records.filter((row: PostingDto) => {
    if (this.postingDtoListAll) {
      this.postingDtoListAll.filter((row: PostingDto) => {
        var amt: number = this.postingAmountCurAggregateRow(row);
        if (this.voucherService.isPostingTypeVendor(row.postingType)) {
          vendor += amt;
        }
        if (amt < 0) {
          credit -= amt;
        }
        else {
          debit += amt;
        }
      });
    }

    if (this.postingGrid?.isEdit && this.postingDtoEdit && this.postingDtoEdit.id == Number.MAX_SAFE_INTEGER && this.postingAmountCurEditValue) {
      // In insert
      var amt: number = this.postingAmountCurAggregateRow(this.postingDtoEdit);
      if (amt < 0) {
        credit -= amt;
      }
      else {
        debit += amt;
      }
    }

    voucher = Math.round(voucher * 100) / 100;
    const voucherAbs = Math.abs(voucher);
    vendor = Math.round(vendor * 100) / 100;
    credit = Math.round(credit * 100) / 100;
    debit = Math.round(debit * 100) / 100;

    let agg: string = 'OK';
    const unmatchedVoucher: boolean = (voucher && (credit < voucherAbs || debit < voucherAbs));
    const unbalancedPosting: boolean = (credit != debit);
    const missingVendorPosting: boolean = (!this.isExpenseVoucher && voucher != -vendor);

    if (unmatchedVoucher || unbalancedPosting || missingVendorPosting) {
      let intl: Internationalization = new Internationalization();
      let nFormatter: Function = intl.getNumberFormat({ skeleton: 'N2', minimumFractionDigits: 2, maximumFractionDigits: 2 });

      agg = '<div>Debit: ' + nFormatter(debit) + '</div>';
      agg += '<div>Kredit: ' + nFormatter(-credit) + '</div>';
      if (unmatchedVoucher || missingVendorPosting) {
        agg += '<div>Bilag: ' + nFormatter(voucher) + '</div>';
      }
      if (missingVendorPosting) {
        agg += '<div>Kreditor: ' + nFormatter(vendor) + '</div>';
      }
    }

    return agg;
  }
  // ----------------------- DIALOG ------------------

  private error(message: string | string[]) {
    this.info(message);
  }

  private info(message: string | string[]) {
    let content: string = '';
    if (Array.isArray(message)) {
      message.forEach(function (s) { content = (content == '' ? '' : content + '<br/>') + s.toString() });
    }
    else {
      content = message;
    }

    this.dialogService.infoPrompt(content);
  }

  // ----------------------- GRID ------------------

  private voucherGridRowActive(): VoucherDto {
    if (this.voucherDtoList && this.voucherDtoList.length == 1) {
      return this.voucherDtoList[0];
    }
    else if (this.voucherDtoActive && this.voucherDtoActive.id) {
      return this.voucherDtoList.find(voucher => voucher.id == this.voucherDtoActive.id);
    }
    else {
      return this.voucherDtoList[this.voucherDtoList.length - 1];
    }
  }

  private gridGetRowIdxById(gridComponent: GridComponent, id: number): number {
    var data = <any[]>gridComponent.getCurrentViewRecords();
    var rec = data.filter(function (r) { return r.id == id })[0];
    return data.indexOf(rec);
  }


  private gridGetFirstSelectedRecord(grid: GridComponent) {
    var record: any = null;
    if (grid) {
      var selectedRecords: Object[] = grid.getSelectedRecords();
      if (selectedRecords.length > 0) {
        record = selectedRecords[0];
      }
    }
    return record;
  }

  voucherGridGetFirstSelectedDto(): VoucherDto {
    return <VoucherDto>this.gridGetFirstSelectedRecord(this.voucherGrid);
  }

  voucherGridDataBound(args: any) {
    if (!this.voucherGridIsDataBound) {
      //console.log("Voucher data bound (first)");
      this.voucherGridIsDataBound = true;
      //console.log("xxx Grid row databound");
      this.gridStartSelectRow();
    }
    //console.log("Voucher data bound");

  }

  voucherGridActionBegin(args: any) {
    //console.log("Voucher Action begin: " + args.requestType);
    if (this.gridActionIs(args, 'columnstate')) {
      this.voucherGrid.endEdit();
    }
    if (this.gridActionIs(args, 'save')) {
      this.voucherSave(args.data);
      args.cancel = true;
    }
    if (this.gridActionIs(args, ['beginEdit', 'add'])) {
      // Ensure only voucher is in active edit
      if (this.postingGrid.isEdit) {
        this.postingGrid.endEdit();
      }
    }
    if (this.gridActionIs(args, 'cancel') && !this.voucherForcedCancel) {
      args.cancel = this.cancelNeedConfirm(this.voucherGrid, args);
      this.voucherForcedCancel = false;
    }
    if (this.gridActionIs(args, 'add')) {
      //if (!this.voucherInitFromMessage(args.data)) {
      //  args.cancel = true;
      //  this.info("Mangler valgt meddelelse");
      //}
      args.cancel = true;
      if (this.postingGrid.editSettings.allowAdding) {
        if (this.postingGrid.isEdit) {
          this.postingGrid.endEdit();
        }
        this.postingGrid.addRecord();
      }
    }
    if (this.gridActionIs(args, 'delete')) {
      //this.voucherDelete(args.data);
      args.cancel = true;
      if (this.postingGrid.editSettings.allowDeleting) {
        if (this.postingGrid.isEdit) {
          this.postingGrid.endEdit();
        }
        this.postingGrid.deleteRecord();
      }
    }
    //console.log("Voucher Action begin cancel: " + args.cancel);
  }

  voucherGridActionFailure(args: any) {
    console.log("Voucher Action Failure");
    console.log(args);
  }

  voucherGridActionComplete(args: any) {
    //console.log("Voucher Action complete: " + args.requestType);

    if (this.gridActionIs(args, 'save')) {
      //this.voucherDtoList.shift();         // Remove the newly added record from first position
      //this.voucherDtoList.push(args.data); // Push the newly added record in data source
    }
    if (this.gridActionIs(args, ['beginEdit', 'add'])) {
      this.voucherDtoActive = args.rowData;

      // Prepare columns
      this.voucherGridInitControls();

      for (var i = 0; i < args.form.elements.length; i++) {
        if (args.form.elements[i].name == 'balanceAccountNum') {
          this.balanceAccountNumElement = args.form.elements[i];
          this.balanceAccountNumElement.onchange = (args) => {
            this.checkDocumentNumUnique();
          }
        }
        // Parse transDate
        /*
        if (args.form.elements[i].name == 'transDate') {
          this.transDateElement = args.form.elements[i];
          this.transDateElement.onchange = (args) => {
            var parsed = this.localeService.parseDate(this.transDateElement.value);
            this.transDateElement.value = this.ui.formatDate(parsed);
          }
        }
        */
        if (args.form.elements[i].name == 'documentNum') {
          this.documentNumElement = args.form.elements[i];
          this.documentNumElement.onchange = (args) => {
            this.checkDocumentNumUnique();
          }
        }

      }
    }

    if (this.gridActionIs(args, 'cancel')) {
      this.gridNavigationCmd = GridNavigationCmd.VoucherCurrent;
      this.resetVendorPostingProforma();
      this.resetProjectPostingProforma();
    }
    if (this.gridActionIs(args, 'refresh')) {
      //this.gridStartEdit();
    }
    //console.log("Voucher Action Complete after: " + args);
  }

  // Confirm cancel
  private cancelNeedConfirm(grid: GridComponent, args: any) {
    var ret: boolean = false;
    if (!this.gridCancelledConfirmed) {
      if (!this.localeService.isDtosEqual(this.gridEditDataGet(grid), args.rowData)) {
        ret = true;
        this.confirmCancelDialogShow(grid);
      }
    }
    else {
      this.gridCancelledConfirmed = null;
      this.gridNavigationCmd = GridNavigationCmd.PostingCurrent;
      this.postingGridRequestEditIdx = args.rowIndex; // start edit on this row
    }
    return ret;
  }

  private checkDocumentNumUnique() {
    var vendorAccountNum: string = this.balanceAccountNumElement != null ? this.balanceAccountNumElement.value : null;
    var documentNum: string = this.documentNumElement != null ? this.documentNumElement.value : null;
    if (vendorAccountNum && documentNum) {
      this.utilClient.isVendorDocumentNumUnique(this.detailsVm.voucherMessageViewDto.id, vendorAccountNum, documentNum).subscribe(
        data => { if (data.voucherViewDtoList && data.voucherViewDtoList.length > 0) { this.showDocumentNumInfo(data.voucherViewDtoList[0], vendorAccountNum, documentNum); } }
      );
    }
  }

  private showDocumentNumInfo(voucher: VoucherDto, vendorAccountNum: string, documentNum: string) {
    this.dialogService.infoPrompt(
      "Faktura " + documentNum + " er registreret for kreditor " + vendorAccountNum + (voucher.voucherNum ? " - Bilag " + voucher.voucherNum + " findes allerede." : ""),
      "Faktura " + documentNum + " er brugt på kreditor " + vendorAccountNum
    );
  }

  private confirmCancelDialogShow(grid: GridComponent) {
    this.gridCancelledConfirmed = grid;
    this.dialogService.confirmPrompt(
      "Annuller redigering?",
      "Bekræft").then(ok => {
        if (ok) {
          this.confirmCancelDialogOkClick.bind(this);
        }
        else {
          this.confirmCancelDialogCancelClick.bind(this);
        }
      }
      );
  }

  private confirmCancelDialogOkClick() {
    if (this.gridCancelledConfirmed && this.gridCancelledConfirmed.isEdit) {
      this.gridCancelledConfirmed.closeEdit();
    }
  }

  private confirmCancelDialogCancelClick() {
    this.gridCancelledConfirmed = null;
  }

  private gridActionIs(args: any, actions: string[] | string): boolean {
    let ok: boolean = false;

    if (Array.isArray(actions)) {
      ok = (actions.includes(args.requestType));
    }
    else {
      ok = (actions.includes(args.requestType));
    }
    return ok;
  }

  // Grid row double clicked: Edit row
  public postingGridRecordDoubleClick(args: any) {

    //    (<any>this.voucherGrid.contextMenuModule).element.ej2_instances[0].openMenu(null, null, (<any>event).pageY, (<any>event).pageX, event);
  }

  // Voucher grid toolbar
  public voucherGridToolbarClick(args: ClickEventArgs) {
    switch (args.item.id) {
      case 'attachmentsShowHide':
        this.attachmentsShowHide()
        break;
    }
  }

  // Shortcuts
  public handleKeyDownEvent(event: KeyboardEvent) {
    //console.log(event);
    if (event.code == 'Escape') {
      if (this.attachmentsIsActive) {
        this.attachmentsShowHide()
        event.preventDefault();
      }
    }
    else if (event.altKey == true && !event.ctrlKey && !event.shiftKey) {
      switch (event.code) {
        case 'KeyV':
          this.attachmentsShowHide();
          break;
        case 'KeyS':
          this.saveAndClose('SaveOnly');
          break;
        case 'KeyG':
          this.saveAndClose('PendingApproval');
          break;
        case 'KeyO':
          if (this.attachmentsIsActive) {
            this.fileViewerTab.openAttachInNewTab(false);
          }
          break;
        case 'NumpadAdd':
          if (this.attachmentsIsActive) {
            this.fileViewerTab.pdfZoomIn();
            event.preventDefault();
          }
          break;
        case 'NumpadSubtract':
          if (this.attachmentsIsActive) {
            this.fileViewerTab.pdfZoomOut();
            event.preventDefault();
          }
          break;
        case 'PageUp':
          if (this.attachmentsIsActive) {
            this.attachmentsPagePrev();
            event.preventDefault();
          }
          break;
        case 'PageDown':
          if (this.attachmentsIsActive) {
            this.attachmentsPageNext();
            event.preventDefault();
          }
          break;
      }
    }
    else if (event.altKey && !event.ctrlKey && event.shiftKey) {
      switch (event.code) {
        case 'PageUp': // Alt+Shift+PgUp
          if (this.postingGrid && this.postingGrid.isEdit) {
            this.postingGrid.endEdit();
          }
          this.gridNavigationCmd = GridNavigationCmd.VoucherLast;
          this.gridStartSelectRow();
          break;
        case 'PageDown': // Alt+Shift+PgDn
          if (this.voucherGrid && this.voucherGrid.isEdit) {
            this.voucherGrid.endEdit();
          }
          this.gridNavigationCmd = GridNavigationCmd.PostingFirst;
          this.gridStartSelectRow();
          break;
        case 'NumpadAdd': // Alt +
          this.splitterResize(100);
          event.preventDefault();
          break;
        case 'NumpadSubtract': // Alt -
          this.splitterResize(-100);
          event.preventDefault();
          break;
      }
    }
  }

  /*
    private gridKeyDownHandler(grid: GridComponent, e: any) {
      if (e.ctrlKey && e.keyCode == 13) {
        console.log("set grid focus");
        if (grid == this.postingGrid && this.voucherGrid) {
          if (grid.isEdit) {
            grid.endEdit();
          }
          this.voucherGrid.focusModule.focus();
        }
        else if (grid == this.voucherGrid && this.postingGrid) {
          if (grid.isEdit) {
            grid.endEdit();
          }
          this.postingGrid.element.focus();
        }
      }
  */

  // Grid context menu click
  public voucherGridContextMenuClick(args: ContextMenuClickEventArgs) {
    //console.log("Context menu id: "+args.item.id);
    switch (args.item.id) {
      case 'dataBind':
        this.detailsVmGetPromise(this.callerMsgId);
        break;
      case 'gridColumnGroup':
        var columnName = <string>args.column.field;
        if (columnName) {
          this.voucherGrid.groupColumn(columnName);
          this.voucherGrid.groupSettings.showDropArea = true;
        }
        break;
      case 'gridColumnHide':
        var columnName = <string>args.column.field;
        if (columnName) {
          this.voucherGrid.hideColumns(columnName, 'field');
        }
        break;
      case 'gridColumnsChoose':
        this.voucherGrid.openColumnChooser();
        break;
      case 'gridExcelExport':
        this.voucherGrid.excelExport(<ExcelExportProperties>{ fileName: this.routerShortUrl() + '.xlsx' });
        break;
      case 'setStatusPending':
        this.setApprovalStatusOnGridContextMenuArgs(args, VoucherApprovalStatusEnum.Pending);
        break;
      case 'setStatusOnHold':
        this.setApprovalStatusOnGridContextMenuArgs(args, VoucherApprovalStatusEnum.OnHold);
        break;
      case 'setStatusApproved':
        this.setApprovalStatusOnGridContextMenuArgs(args, VoucherApprovalStatusEnum.Approved);
        break;
      case 'setStatusAccounted':
        this.setApprovalStatusOnGridContextMenuArgs(args, VoucherApprovalStatusEnum.Accounted);
        break;
      case 'setStatusPosted':
        this.setApprovalStatusOnGridContextMenuArgs(args, VoucherApprovalStatusEnum.Posted);
        break;
    }
  }

  public messageGridRowSelected(args: any) {
  }

  public messageGridContextMenuClick(args: ContextMenuClickEventArgs) {
    //console.log("Context menu id: "+args.item.id);
    switch (args.item.id) {
      case 'dataBind':
        this.detailsVmDataBind();
        break;
      case 'gridColumnHide':
        var columnName = <string>args.column.field;
        if (columnName) {
          this.messageGrid.hideColumns(columnName, 'field');
        }
        break;
      case 'gridColumnsChoose':
        this.messageGrid.openColumnChooser();
        break;
      case 'gridExcelExport':
        this.messageGrid.excelExport(<ExcelExportProperties>{ fileName: this.routerShortUrl() + '.xlsx' });
        break;
    }
  }


  private voucherGridContextMenuArgsToDto(args: ContextMenuClickEventArgs | ContextMenuOpenEventArgs): any {
    var dto: any;
    if (this.voucherGrid && this.voucherGrid.isEdit) {
      dto = this.gridEditDataGet(this.voucherGrid);
    }
    else {
      dto = (args.rowInfo != null ? args.rowInfo.rowData : null);
    }
    return dto;
  }

  private setApprovalStatusOnGridContextMenuArgs(args: ContextMenuClickEventArgs, status: VoucherApprovalStatusEnum) {
    if (this.voucherGrid.isEdit) {
      this.voucherApprovalStatusSetDeferred = status;
      this.voucherGrid.endEdit();
    }
    else {
      var voucherDto: VoucherDto = this.voucherGridContextMenuArgsToDto(args);
      if (voucherDto) {
        this.setApprovalStatusPromise(voucherDto.id, status).then((voucherDtoUpdated) => {
          if (voucherDtoUpdated.msgId) {
            this.detailsVmMap.delete(voucherDtoUpdated.msgId);
            this.detailsVmGetPromise(voucherDtoUpdated.msgId, voucherDtoUpdated.id);
          }
        }
        );
      }
    }
  }

  public voucherGridRecordDoubleClick(args: any) {
    //(<any>this.voucherGrid.contextMenuModule).element.ej2_instances[0].openMenu(null, null, (<any>event).pageY, (<any>event).pageX, event);
  }

  public voucherGridContextMenuOpen(args: ContextMenuOpenEventArgs) {
    if (args != null) {
      var approvalStatus: VoucherApprovalStatusEnum = null;
      var voucherDto: VoucherDto = this.voucherGridContextMenuArgsToDto(args);
      approvalStatus = (voucherDto != null ? voucherDto.status : null);
      this.voucherService.EnableContextMenuByApprovalStatus(this.voucherGrid, this.voucherGridContextMenuItems, approvalStatus);
    }
  }

  /*
  private voucherSetApprovalStatusAll(status: VoucherApprovalStatusEnum): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      voucherDto : VoucherDto = this.voucherDtoList?.length > 0 ? voucherDtoList[0] : null;
      if (voucherDto != null) {
        if ((this.voucherService.IsStatusPendingOrCreated(voucherDto.status) && (status == VoucherApprovalStatusEnum.OnHold || status == VoucherApprovalStatusEnum.Approved)) ||
          (voucherDto.status == VoucherApprovalStatusEnum.OnHold && (status == VoucherApprovalStatusEnum.Pending || status == VoucherApprovalStatusEnum.Approved))) {
          this.voucherApiSetApprovalStatusPromise(voucherDto, status);
        }
        else if (i == this.voucherDtoList.length) {
          this.close();
        }
      }
    });
  }
    */

  private setApprovalStatusPromise(voucherId: number, approvalStatus: VoucherApprovalStatusEnum): Promise<VoucherDto> {
    return new Promise<VoucherDto>((resolve, reject) => {
      if (voucherId == null) {
        var error = "Manglende bilag";
        this.error(["Kan opdatere godkendelsesstatus", error]);
        reject(error);
      }
      else {
        if (this.messageDto.status == VoucherMessageStatusEnum.Inbox) {
          this.setMessageStatusPromise(VoucherMessageStatusEnum.Voucher).then((detailsVmUpdated) => {
            this.apiVoucherSetApprovalStatusPromise([voucherId], approvalStatus).then((voucherDtoUpdated) => { resolve(voucherDtoUpdated); });
          });
        }
        else {
          this.apiVoucherSetApprovalStatusPromise([voucherId], approvalStatus).then((voucherDtoUpdated) => { resolve(voucherDtoUpdated); });
        }
      }
    });
  }

  private apiVoucherSetApprovalStatusPromise(voucherIds: number[], approvalStatus: VoucherApprovalStatusEnum): Promise<VoucherDto> {
    return new Promise<VoucherDto>((resolve, reject) => {
      if (voucherIds == null || voucherIds.length == 0) {
        var error = "Manglende bilag";
        this.error(["Kan opdatere godkendelsesstatus", error]);
        reject(error);
      }
      else {
        this.client.setApprovalStatus(approvalStatus, voucherIds).subscribe({
          next: (voucherDto) => {
            resolve(voucherDto);
          },
          error: (error) => {
            this.error(["Kan opdatere godkendelsesstatus", error]);
            reject(error);
          }
        });
      }
    });
  }

  private setMessageStatusPromise(newMessageStatus: VoucherMessageStatusEnum): Promise<DetailsVm> {
    return new Promise<DetailsVm>((resolve, reject) => {
      if (this.detailsVm?.voucherMessageViewDto == null) {
        reject("Ingen meddelelse angivet");
      }
      else {
        if (this.detailsVm.voucherMessageViewDto.status == newMessageStatus) {
          resolve(this.detailsVm);
        }
        else {
          this.apiMessageSetStatusPromise(this.detailsVm.voucherMessageViewDto.id, newMessageStatus).then(
            (messageDtoUpdated) => {
              this.detailsVmGetPromise(messageDtoUpdated.id).then((detailsVm) => {
                resolve(this.detailsVm);
              });
            });
        }
      }
    });
  }


  private apiMessageSetStatusPromise(msgId: number, status: VoucherMessageStatusEnum): Promise<MessageDto> {
    return new Promise<MessageDto>((resolve, reject) => {
      this.messageClient.setStatus(msgId, status, false).subscribe({
        next: (messageDtoUpdated: MessageDto) => {
          resolve(messageDtoUpdated);
        },
        error: (error) => {
          this.dialogService.snackBar("Kunne ikke opdatere meddelelsesstatus", error);
          console.error(error);
        }
      });
    });
  }

  // Posting grid events
  public postingGridDataBound(args: any) {
    if (!this.postingGridIsDataBound) {
      this.postingGridIsDataBound = true;
      this.gridStartSelectRow();
    }
    //this.postingDtoListMoveInsertedToEnd();
  }

  /*
  private postingDtoListMoveInsertedToEnd() {
    if (this.postingDtoList && this.postingDtoList.length > 1) {
      if (!(this.postingDtoList[0] instanceof PostingDto)) {
        let move = this.postingDtoList[0];
        this.postingDtoList.splice(0, 1)
        this.postingDtoList.push(move);
      }
    }
  }
  */

  public postingGridQueryCellInfo(args: any): void {
    VoucherHelper.postingGridQueryCellInfo(args);
  }

  public postingGridActionBegin(args: any) {
    //console.log("Posting grid action begin: " + args.requestType);
    if (this.gridActionIs(args, 'save')) {
      args.index = this.postingDtoList.length; // Add to end
      this.postingSave(args.data, args.rowIndex ? args.rowIndex : args.index);
    }
    if (this.gridActionIs(args, 'delete')) {
      this.postingDelete(args.data);
    }
    if (this.gridActionIs(args, ['beginEdit', 'add'])) {
      if (this.voucherGrid?.isEdit) {
        // delay start edit on this row
        this.gridNavigationCmd = GridNavigationCmd.PostingCurrent;
        this.postingGridRequestEditIdx = args.rowIndex; // start edit on this row
        args.cancel = this.voucherGridEndEditIfTouched();
      }
    }
    if (this.gridActionIs(args, 'add')) {
      if (!this.postingInitFromVoucher(args.data)) {
        args.cancel = true;
        this.dialogService.infoPrompt('Mangler valgt bilag');
      }
    }
    if (this.gridActionIs(args, 'cancel')) {
      args.cancel = this.cancelNeedConfirm(this.postingGrid, args);
    }
    if (this.gridActionIs(args, 'refresh')) {
      //console.log("refresh");
    }
  }

  private voucherGridEndEditIfTouched(): boolean {
    var save: boolean = this.voucherGridEditTouched()
    if (save) {
      this.voucherGrid.endEdit();
    }
    else {
      this.voucherGrid.closeEdit();
    }
    return save;
  }

  private voucherGridEditTouched(): boolean {
    return true;
  }

  public voucherGridInitControls() {
    var i: HTMLInputElement = this.voucherGridEditFormInputElementGetByColumnName('amountCur');
    if (i) {
      (<any>i).ej2_instances[0].showSpinButton = false;
    }
  }

  public postingGridActionComplete(args: any) {

    if ((args.requestType === 'refresh')) {
      this.gridStartSelectRow();
    }
    if (this.gridActionIs(args, ['beginEdit', 'add'])) {
      this.postingDtoEdit = args.rowData;
      this.postingGridInitControls();
      this.postingGridUpdateDesign(args.rowData);
      this.voucherCanApproveByPostingEditCalc(args.rowData);

    }
    if (this.gridActionIs(args, 'cancel')) {
      this.gridNavigationCmd = GridNavigationCmd.PostingCurrent;
      this.postingGridRequestEditIdx = args.rowIndex;
      this.gridStartSelectRow();
    }
    if (this.gridActionIs(args, ['cancel', 'save'])) {
      this.postingAmountCurEditValue = null;
      this.postingDtoEdit = null;
      this.dataBindCalcStatus();
      this.postingGrid.aggregateModule.refresh(null);
    }

    //console.log("Posting grid Action complete: " + args.requestType);
  }

  private postingGridLastRow(voucher: VoucherDto): PostingDto {
    let posting: PostingDto = null;
    if (this.postingDtoList) {
      this.postingDtoList.forEach(row => { if (row.voucherId == voucher.id && (!posting || row.lineNum > posting.lineNum)) { posting = row; } });
    }
    return posting;
  }

  // Posting grid toolbar
  public postingGridToolbarClick(args: ClickEventArgs) {
    switch (args.item.id) {
      case 'postingAdjustAmounts':
        this.postingAdjustAmounts();
        break;
    }
  }

  public postingGridContextMenuClick(args: ContextMenuClickEventArgs) {

    switch (args.item.id) {
      case 'gridColumnGroup':
        var columnName = <string>args.column.field;
        if (columnName) {
          this.postingGrid.groupColumn(columnName);
          this.postingGrid.groupSettings.showDropArea = true;
        }
        break;
      case 'gridColumnUnGroup':
        this.postingGrid.ungroupColumn('voucherId');
        this.postingGrid.groupSettings.showDropArea = false;
        break;
      case 'gridColumnHide':
        var columnName = args.column.field;
        if (columnName) {
          this.postingGrid.hideColumns(columnName, 'field');
        }
        break;
      case 'gridColumnsChoose':
        this.postingGrid.openColumnChooser();
        break;
      case 'gridExcelExport':
        this.postingGrid.excelExport(<ExcelExportProperties>{ fileName: this.routerShortUrl() + '.xlsx' });
        break;
    }
  }

  // Currency editor setup
  private currencyEditCellSetup() {
    this.currencyEditCell = {
      create: () => {
        this.currencyDdlElement = document.createElement('input');
        return this.currencyDdlElement;
      },
      read: () => {
        return this.currencyDdl.value;
      },
      destroy: () => {
        if (this.currencyDdl) {
          this.currencyDdl.destroy();
          this.currencyDdl = null;
        }
      },
      write: (args: { rowData: Object, column: Column }) => {
        this.currencyDdl = new CerDropDownList({
          fields: { text: 'iso', value: 'id' },
          placeholder: 'Vælg valuta',
          allowFiltering: true,
          dataSource: new DataManager(this.currencies),
          change: (args: ChangeEventArgs) => { this.currencyIdChanged(args); },
        });
        var voucherDto = <VoucherDto>args.rowData;
        this.currencyDdl.value = voucherDto.currency;
        this.currencyDdl.appendTo(this.currencyDdlElement);
      }
    };
  }

  // Approver editor setup
  private approverEditCellSetup() {
    this.approverEditCell = {
      create: () => {
        this.approverDdlElement = document.createElement('input');
        return this.approverDdlElement;
      },
      read: () => {
        return this.approverDdl.value;
      },
      destroy: () => {
        if (this.approverDdl) {
          this.approverDdl.destroy();
          this.approverDdl = null;
        }
      },
      write: (args: { rowData: Object, column: Column }) => {
        this.approverDdl = new CerDropDownList({
          fields: { text: 'shortName', value: 'id' },
          placeholder: 'Vælg godkender',
          allowFiltering: true,
          dataSource: this.usersDM,
          //change: (args: ChangeEventArgs) => { this.approverIdChanged(args); },
        });
        var voucherDto = <VoucherDto>args.rowData;
        this.approverDdl.value = voucherDto.approver;
        this.approverDdl.appendTo(this.approverDdlElement);
      }
    };
  }

  // Posting type editor setup
  private postingTypeEditCellSetup() {
    this.postingTypeEditCell = {
      create: () => {
        this.postingTypeDdlElement = document.createElement('input');
        return this.postingTypeDdlElement;
      },
      read: () => {
        return this.postingTypeDdl.value;
      },
      destroy: () => {
        if (this.postingTypeDdl) {
          this.postingTypeDdl.destroy();
          this.postingTypeDdl = null;
        }
      },
      write: (args: { rowData: Object, column: Column }) => {
        this.postingTypeDdl = new CerDropDownList({
          fields: { text: 'name', value: 'id' },
          placeholder: 'Vælg type',
          dataSource: new DataManager(this.postingTypes),
          allowFiltering: true,
          change: (args: ChangeEventArgs) => { this.postingTypeChanged(args); },
        });
        var data = <PostingDto>args.rowData;
        this.postingTypeDdl.value = data.postingType;
        this.postingTypeDdl.appendTo(this.postingTypeDdlElement);
      }
    };
  }

  // Project cost type editor setup
  private projectCostTypeEditCellSetup() {
    this.projectCostTypeEditCell = {
      create: () => {
        this.projectCostTypeDdlElement = document.createElement('input');
        return this.projectCostTypeDdlElement;
      },
      read: () => {
        return this.projectCostTypeDdl.value;
      },
      destroy: () => {
        if (this.projectCostTypeDdl) {
          this.projectCostTypeDdl.destroy();
          this.projectCostTypeDdl = null;
        }
      },
      write: (args: { rowData: Object, column: Column }) => {
        this.projectCostTypeDdl = new CerDropDownList({
          fields: { text: 'description', value: 'num' },
          popupHeight: '300px',
          popupWidth: '300px',
          placeholder: 'Vælg art',
          //headerTemplate: '<table><tr><th style="width: 60px;">Art</th><th>Navn</th></tr></table>',
          //itemTemplate: '<table><tbody><tr><td style="width: 60px;">${num}</td><td>${name}</td></tr></tbody></table>',
          filterType: 'StartsWith',
          columns: [{ field: 'num', headerText: 'Art', width: '20%', searchOperator: 'StartsWith', allowFiltering: true },
          { field: 'name', headerText: 'Navn', width: '80%', searchOperator: 'Contains', allowFiltering: true }],
          allowFiltering: true,
          dataSource: new DataManager(this.projectCostTypesFiltered),
          showClearButton: true,
          change: (args: ChangeEventArgs) => { this.projectCostTypeChanged(args); },
        });
        var data = <PostingDto>args.rowData;
        this.projectCostTypeDdl.value = data.projectCostType;
        this.projectCostTypeDdl.appendTo(this.projectCostTypeDdlElement);

        var ddl: CerDropDownList = this.projectCostTypeDdl;
        /*var reOpen: boolean = false;
        if (!ddl.value && false) {
          ddl.showPopup();
          reOpen = true;
        }
        else {*/
        EventHandler.add(this.projectCostTypeDdlElement.parentElement, 'keypress', function (evt: any) {
          if (ddl && !ddl.value && !ddl.checkIsPopupOpen) {
            ddl.showPopup();
            //reOpen = true;
          }
        });
        //}
      }
    };
  }

  // Tax code editor setup
  private taxCodeEditCellSetup() {
    this.taxCodeEditCell = {
      create: () => {
        this.taxCodeDdlElement = document.createElement('input');
        return this.taxCodeDdlElement;
      },
      read: () => {
        return this.taxCodeDdl.value;
      },
      destroy: () => {
        if (this.taxCodeDdl) {
          this.taxCodeDdl.destroy();
          this.taxCodeDdl = null;
        }
      },
      write: (args: { rowData: Object, column: Column }) => {
        this.taxCodeDdl = new CerDropDownList({
          fields: { text: 'num', value: 'num' },
          popupHeight: '300px',
          popupWidth: '300px',
          placeholder: 'Vælg momskode',
          //headerTemplate: '<table><tr><th style="width: 60px;">Momskode</th><th>Navn</th></tr></table>',
          //itemTemplate: '<table><tbody><tr><td style="width: 60px;">${num}</td><td>${name}</td></tr></tbody></table>',
          columns: [{ field: 'num', headerText: 'Kode', width: '30%', searchOperator: 'StartsWith', allowFiltering: true },
          { field: 'name', headerText: 'Navn', width: '70%', searchOperator: 'StartsWith', allowFiltering: true }],
          filterType: 'StartsWith',
          allowFiltering: true,
          autoBuildQuery: true,
          dataSource: this.taxCodesDM,
          showClearButton: true,
          change: (args: ChangeEventArgs) => { this.taxCodeChanged(args); },
        });
        var data = <PostingDto>args.rowData;
        this.taxCodeDdl.value = data.taxCode;
        this.taxCodeDdl.appendTo(this.taxCodeDdlElement);

        /*
        var ddl: CerDropDownList = this.taxCodeDdl;
        EventHandler.add(this.taxCodeDdlElement.parentElement, 'keypress', function (evt: any) {
          if (ddl && !ddl.value && !ddl.checkIsPopupOpen) {
            ddl.showPopup();
            //reOpen = true;
          }
        });
        //}
        */
      }
    };
  }

  public taxCodeChanged(args: any) {
    if (args.itemData) {
      var data = this.gridEditDataGet(this.postingGrid);
    }
  }

  public postingGridInitControls() {
    var i: HTMLInputElement = this.postingGridEditFormInputElementGetByColumnName('amountCur');
    if (i) {
      this.postingAmountCurEditValue = null;
      (<any>i).ej2_instances[0].showSpinButton = false;
      i.onchange = (args) => this.postingAmountCur_OnChanged(i, args);
      i.onblur = (args) => this.postingAmountCur_OnChanged(i, args);
    }
  }

  private postingAmountCur_OnChanged(i: HTMLInputElement, args: any) {
    var editData = this.gridEditDataGet(this.postingGrid);
    if (editData) {
      var numTextBox: NumericTextBox = (<any>i).ej2_instances[0];
      editData.amountCur = numTextBox.value;
      this.postingAmountCurEditValue = editData.amountCur;
      this.postingValidateRoyalty(editData);
      this.voucherCanApproveByPostingEditCalc(editData);
    }
  }

  public postingGridUpdateDesign(data: PostingDto) {
    let project: boolean = this.voucherService.isPostingTypeProject(data.postingType);

    this.postingGridEditFormInputElementDisable('projectCostType', !project);
    this.postingGridEditFormInputElementDisable('projectLedgerNum', !project);

    if (this.projectCostTypeDdlElement && this.projectCostTypeDdl) {
      this.projectCostTypeDdlElement.disabled = !project;
      this.projectCostTypeDdl.enabled = project;
      this.projectCostTypeDdl.placeholder = project ? 'Vælg art' : '';
    }
    if (this.projectLedgerNumDdlElement && this.projectLedgerNumDdl) {
      this.projectLedgerNumDdlElement.disabled = !project;
      this.projectLedgerNumDdl.enabled = project;
      this.projectLedgerNumDdl.placeholder = project ? 'Vælg finanskonto' : '';
    }
  }

  public postingTypeChanged(args: any) {
    if (args.itemData) {
      var data: PostingDto = this.gridEditDataGet(this.postingGrid);
      data.postingType = args.itemData.id;
      data.postingTypeName = args.itemData.description;
      this.postingAccountClear(data);
      this.postingGridUpdateDesign(data);
      this.accountNumDdlSetup(data);
    }
  }

  public postingValidateRoyalty(editData: any) {
    if (editData.amountCur && editData.postingType == <number>VoucherPostingTypeEnum.ProjectRoyalty) {
      //  this.showRoyaltyInfo();
    }
  }

  private showRoyaltyInfo() {

    var vendorAccountNum = "ttt";
    this.dialogService.confirmPrompt(
      "Royalty faktura for kreditor " + vendorAccountNum,
      "Royaltyfaktura")
      .then(ok => { });
  }

  public postingAccountClear(dto: PostingDto) {
    dto.accountNum = "";
    dto.accountDescription = null;
    dto.projectCostType = null;
    dto.projectLedgerNum = null
    dto.taxCode = null;
    dto.vendorBankAccount = '';

    this.postingGridEditFormInputElementSetValue('accountNum', dto.accountNum);
    this.postingGridEditFormInputElementSetValue('accountDescription', dto.accountDescription);
    this.postingGridEditFormInputElementSetValue('projectCostType', dto.projectCostType);
    this.postingGridEditFormInputElementSetValue('projectLedgerNum', dto.projectLedgerNum);
    this.postingGridEditFormInputElementSetValue('taxCode', dto.taxCode, dto.taxCode);
    this.postingGridEditFormInputElementSetValue('vendorBankAccount', dto.vendorBankAccount);
    this.voucherCanApproveByPostingEditCalc();
  }

  public projectCostTypeChanged(args: any) {
    if (args.itemData) {
      var data = null;
      if (this.voucherGrid.isEdit) {
        data = this.gridEditDataGet(this.voucherGrid);
        this.setProjectPostingDataProforma(null, data);
      }
      else {
        data = this.gridEditDataGet(this.postingGrid);
        data.projectLedgerNum = '';
        this.postingGridEditFormInputElementSetValue('projectLedgerNum', data.projectLedgerNum);
        //this.postingGridUpdateDesign(data);
        //if (this.isExpenseVoucher) {
        this.projectLedgerNumDdlSetup(data);
        //}
      }
    }
    this.voucherCanApproveByPostingEditCalc();
  }

  // Project ledger num editor setup
  private projectLedgerNumEditCellSetup() {
    // Account num dropdown
    this.projectLedgerNumEditCell = {
      create: () => {
        this.projectLedgerNumDdlElement = document.createElement('input');
        return this.projectLedgerNumDdlElement;
      },
      read: () => {
        return this.projectLedgerNumDdl.value;
      },
      destroy: () => {
        if (this.projectLedgerNumDdl) {
          this.projectLedgerNumDdl.destroy();
          this.projectLedgerNumDdl = null;
        }
      },
      write: (args: { rowData: Object, column: Column }) => {
        this.projectLedgerNumDdlSetup(<PostingDto>args.rowData);
      }
    };
  }

  // Project ledger num  editor setup
  private projectLedgerNumDdlSetup(dto: PostingDto) {
    let col: Column = this.postingGrid.getColumnByField('projectLedgerNum');
    if (col /*&& col.allowEditing*/ && col.visible) {
      var isCreate: boolean = (this.projectLedgerNumDdl == null);

      if (isCreate) {
        // Project ledger num dropdown create with params
        this.projectLedgerNumDdl = new CerDropDownList({
          fields: { text: 'num', value: 'num' },
          autoBuildQuery: true,
          ignoreAccent: true,
          popupWidth: '500px',
          popupHeight: '300px',
          showClearButton: true,
          placeholder: 'Vælg finanskonto',
          allowFiltering: true,
          cssClass: 'e-multi-column',
          columns: [{ field: 'num', headerText: 'Konto', width: '20%', searchOperator: 'StartsWith', allowFiltering: true },
          { field: 'description', headerText: 'Navn', width: '80%', searchOperator: 'Contains', allowFiltering: true }],
          change: (args: ChangeEventArgs) => { this.projectLedgerNumChanged(args); },
        });
        this.projectLedgerNumDdl.enabled = col.allowEditing;
      }

      if (this.isExpenseVoucher) {
        if (isCreate) {
          this.projectLedgerNumDdl.dataSource = new DataManager(this.ledgerAccounts);
          this.projectLedgerNumDdl.query = new Query();
        }
      }
      else {
        // VendorVoucher: Needs research based on cost type
        this.projectLedgerNumDdl.dataSource = new DataManager(this.projectLedgerAccounts);
        if (dto?.projectCostType) {
          this.projectLedgerNumDdl.query = new Query().where('projCostType', 'equal', dto.projectCostType);
        } else {
          this.projectLedgerNumDdl.query = new Query();
        }
      }
      this.projectLedgerNumDdl.value = dto?.projectLedgerNum;

      if (isCreate) {
        this.projectLedgerNumDdl.appendTo(this.projectLedgerNumDdlElement);
        var ddl: CerDropDownList = this.projectLedgerNumDdl;
        var reOpen: boolean = false;
        if (!ddl.value && false) {
          ddl.showPopup();
          reOpen = true;
        }
        else {
          EventHandler.add(this.projectLedgerNumDdlElement.parentElement, 'focus', function (evt: any) {
            if (ddl && !ddl.value && !reOpen) {
              ddl.showPopup();
              reOpen = true;

            }
          });
        }
      }
    }
  }

  public projectLedgerNumChanged(args: any) {
    var data: PostingDto = this.gridEditDataGet(this.postingGrid);
    data.taxCode = args.itemData?.taxCode ?? null;
    if (data.taxCode == '') {
      data.taxCode = null;
    }
    this.
      postingGridEditFormInputElementSetValue('taxCode', data.taxCode, data.taxCode);
    this.voucherCanApproveByPostingEditCalc();
  }

  // Account num editor setup
  private accountNumEditCellSetup() {
    // Account num dropdown
    this.accountNumEditCell = {
      create: () => {
        this.accountNumDdlElement = document.createElement('input');
        return this.accountNumDdlElement;
      },
      read: () => {
        return this.accountNumDdl.value;
      },
      destroy: () => {
        this.accountNumDdl.destroy();
        this.accountNumDdl = null;
      },
      write: (args: { rowData: Object, column: Column }) => {
        this.accountNumDdlSetup(<PostingDto>args.rowData);
      }
    };
  }

  public voucherVendorAccountNumEditCellSetup() {
    // Voucher Vendor Account num dropdown
    if (this.userIsAdmin || this.voucherIsStatusCreated) {
      this.voucherVendorAccountNumEditCell = {
        create: () => {
          this.voucherVendorAccountNumDdlElement = document.createElement('input');
          return this.voucherVendorAccountNumDdlElement;
        },
        read: () => {
          return this.voucherVendorAccountNumDdl.value;
        },
        destroy: () => {
          this.voucherVendorAccountNumDdl.destroy();
          this.voucherVendorAccountNumDdl = null;
        },
        write: (args: { rowData: Object, column: Column }) => {
          var isCreate: boolean = (!this.voucherVendorAccountNumDdl);
          if (isCreate) {
            this.voucherVendorAccountNumDdl = this.accountNumDdlCreate(VoucherPostingTypeEnum.Vendor);
          }
          this.voucherVendorAccountNumDdl.placeholder = 'Vælg kreditor';
          this.voucherVendorAccountNumDdl.dataSource = new DataManager(this.getLookupDefaultListForAccountNum(VoucherPostingTypeEnum.Vendor));
          var voucherDto: VoucherDto = <VoucherDto>args.rowData;
          if (<VoucherPostingTypeEnum>voucherDto?.balancePostingType == VoucherPostingTypeEnum.Vendor) {
            this.voucherVendorAccountNumDdl.value = voucherDto.balanceAccountNum;
          }
          if (isCreate) {
            this.voucherVendorAccountNumDdl.appendTo(this.voucherVendorAccountNumDdlElement);
            var ddl: CerDropDownList = this.voucherVendorAccountNumDdl;
            //var reOpen: boolean = false;
            //if (ddl.value) {
            //ddl.checkShowPopup();
            //reOpen = true;
            //}
            //else {
            if (ddl.hasContainerFocus() && !this.avoidFocusOnVendor) {
              ddl.checkShowPopup();
            }
            //ddl.disableAutoShow = this.avoidFocusOnVendor;
            //            if (this.voucherVendorAccountNumDdlElement.foc)
            EventHandler.add(this.voucherVendorAccountNumDdlElement, 'focus', function (evt: any) {
              ddl.checkShowPopup();
            });
            //}
          }
        }
      }
    }
  }

  public voucherProjectNumEditCellSetup() {
    // Voucher Project num dropdown
    this.voucherProjectNumEditCell = {
      create: () => {
        this.voucherProjectNumDdlElement = document.createElement('input');
        return this.voucherProjectNumDdlElement;
      },
      read: () => {
        return this.voucherProjectNumDdl.value;
      },
      destroy: () => {
        if (this.voucherProjectNumDdl) {
          this.voucherProjectNumDdl.destroy();
          this.voucherProjectNumDdl = null;
        }
      },
      write: (args: { rowData: Object, column: Column }) => {
        var isCreate: boolean = (!this.voucherProjectNumDdl);
        if (isCreate) {
          this.voucherProjectNumDdl = this.accountNumDdlCreate(VoucherPostingTypeEnum.Project);
        }
        this.voucherProjectNumDdl.placeholder = 'Vælg projekt';
        this.voucherProjectNumDdl.dataSource = new DataManager(this.getLookupDefaultListForAccountNum(VoucherPostingTypeEnum.Project));
        var voucherDto: VoucherDto = <VoucherDto>args.rowData;
        if (this.isPostingTypeProject(voucherDto?.costPostingType)) {
          this.voucherProjectNumDdl.value = voucherDto.costAccountNum;
        }
        if (isCreate) {
          this.voucherProjectNumDdl.appendTo(this.voucherProjectNumDdlElement);
          //var ddl: snDropDownListSearch = this.voucherProjectNumDdl;
          //var reOpen: boolean = false;
          //if (!ddl.value && false) {
          //ddl.checkShowPopup();
          //  ddl.showPopup();
          //  reOpen = true;
          //}
          //else {
          //  EventHandler.add(this.voucherProjectNumDdlElement.parentElement, 'focus', function (evt) {
          //    if (!ddl.value && !reOpen) {
          //      ddl.showPopup();
          //      reOpen = true;
          //    }
          //  });
          //}
        }
      }
    };
  }

  public accountNumDdlCreate(postingType: VoucherPostingTypeEnum): CerDropDownList {
    // Voucher account num dropdown create with common params
    var ddl: CerDropDownList = new CerDropDownList({
      allowFiltering: true,
      fields: { text: 'num', value: 'num' },
      autoBuildQuery: false,
      columns: [{ field: 'num', headerText: 'Nummer', filterType: 'StartsWith' }, { field: 'description', headerText: 'Navn', filterType: 'Contains' }],
      popupWidth: '500px',
      popupHeight: '300px',
      showClearButton: true,
      //headerTemplate: '<div><span style="padding-left: 30px; display: inline-block; width: 100px; padding-right: 10px;">Nummer</span><span style="padding-left: 14px;">Navn</span></div>',
      //itemTemplate: '<div><span style="display: inline-block; width: 100px; padding-right: 10px;">${num}</span><span style="width: 200px;">${description}</span></div>',
    });

    ddl.actionComplete = (args: object) => { this.accountNumActionComplete(args, postingType); };
    ddl.actionFailure = (args: object) => { this.accountNumActionFailure(args, postingType); };
    ddl.change = (args: ChangeEventArgs) => { this.accountNumChanged(args, postingType); };
    ddl.filtering = (args: FilteringEventArgs) => { this.accountNumFilter(args, postingType, ddl); };
    return ddl;
  }

  public accountNumDdlSetup(data: PostingDto) {
    var isCreate: boolean = (!this.accountNumDdl);

    if (isCreate) {
      // Account num dropdown create with common params
      this.accountNumDdl = this.accountNumDdlCreate(null);
    }

    switch (<VoucherPostingTypeEnum>data.postingType) {
      case VoucherPostingTypeEnum.Vendor:
        // Vendor dropdown
        this.accountNumEditDdlCurrentTableName = 'vendor';
        this.accountNumDdl.placeholder = 'Vælg kreditor';
        this.accountnNumFilterDefaultListData = this.vendorsDefault;
        break;
      case VoucherPostingTypeEnum.Project:
        // Project dropdown
        this.accountNumEditDdlCurrentTableName = 'project';
        this.accountNumDdl.placeholder = 'Vælg projekt';
        this.accountnNumFilterDefaultListData = this.projectsDefault;
        break;
      case VoucherPostingTypeEnum.ProjectRoyalty:
        // Project royalty dropdown
        this.accountNumEditDdlCurrentTableName = 'project';
        this.accountNumDdl.placeholder = 'Vælg projekt';
        this.accountnNumFilterDefaultListData = this.projectsDefault;
        break;
      case VoucherPostingTypeEnum.Ledger:
        // Ledger account dropdown
        this.accountNumEditDdlCurrentTableName = 'financeLedgerAccount';
        this.accountNumDdl.placeholder = 'Vælg finanskonto';
        this.accountnNumFilterDefaultListData = this.ledgerAccounts;
        break;
      case VoucherPostingTypeEnum.Debtor:
        // Debtor dropdown
        this.accountNumEditDdlCurrentTableName = 'debtor';
        this.accountNumDdl.placeholder = 'Vælg debitor';
        this.accountnNumFilterDefaultListData = this.debtorsDefault;
        break;
      case VoucherPostingTypeEnum.Bank:
        // Debtor dropdown
        this.accountNumEditDdlCurrentTableName = 'bankAccount';
        this.accountNumDdl.placeholder = 'Vælg bankkonto';
        this.accountnNumFilterDefaultListData = this.bankAccounts;
        break;
    }

    this.accountNumDdl.dataSource = new DataManager(this.accountnNumFilterDefaultListData);

    this.accountNumDdl.value = data.accountNum;
    //    this.accountNumDdl.query = new Query();

    if (isCreate) {
      this.accountNumDdl.appendTo(this.accountNumDdlElement);
      var ddl: CerDropDownList = this.accountNumDdl;
      var reOpen: boolean = false;
      if (!ddl.value && false) {
        ddl.showPopup();
        reOpen = true;
      }
      else {
        EventHandler.add(this.accountNumDdlElement.parentElement, 'focus', function (evt: any) {
          if (!ddl.value && !reOpen) {
            ddl.showPopup();
            reOpen = true;
          }
        });
      }
    }
  }

  // Filter account num dropdown
  public accountNumFilter(args: FilteringEventArgs, postingType: VoucherPostingTypeEnum, ddl: CerDropDownList) {
    args.preventDefaultAction = true;
    //console.log('start:' + args.text);
/*    if (ddl && ddl.searchForced) {
      this.accountNumFilterExecute(args, postingType);
    }
    else*/ if (args.baseEventArgs && args.baseEventArgs instanceof ClipboardEvent) {
      this.accountNumFilterExecute(args, postingType);
    }
    else {
      this.accountNumFilterDebounce(args, postingType);
    }
  }

  public accountNumFilterDebounce = debounce((args: FilteringEventArgs, fixedType: VoucherPostingTypeEnum) => {
    if (!this.accountNumFilterIsExecuting || args.text == '') {
      //console.log('detail filter debounced:' + args.text);
      this.accountNumFilterExecute(args, fixedType);
    }
    else {
      console.log('detail filter queued:' + args.text);
      this.accountNumFilterArgsQueued = args;
    }
  }, 1000);


  public accountNumFilterExecute(args: FilteringEventArgs, postingType: VoucherPostingTypeEnum, equal: boolean = false) {
    this.accountNumFilterIsExecuting = true;
    this.accountNumFilterArgsQueued = null;
    console.log("detail filter " + args.text + (!equal ? " (contains)" : " (equal)"));
    var dataSource: DataManager = null;
    var query: Query = null;
    if (args.text == '') {
      var listData: object[] = [];
      listData = Object.assign(listData, this.getLookupDefaultListForAccountNum(postingType));
      this.accountNumListAddDefaultFromDto(listData, postingType, true);
      dataSource = new DataManager(this.accountnNumFilterDefaultListData);
    }
    else {
      dataSource = new DataManager({
        url: '/api', adaptor: new WebApiAdaptor,
        headers: [{ Authorization: 'Bearer ' + this.authToken }]
      });
      query = new Query().from(this.getLookupTableNameForAccountNum(postingType)).take(50);

      if (equal) {
        query = query.where(
          new Predicate('$key', 'eq', args.text, true));
      }
      else {
        query = query.where(
          new Predicate('$txt', 'contains', args.text, true));
      }
    }
    if (query) {
      (query as any).searchTextInfo = args.text;
    }
    args.updateData(dataSource, query);
  }

  private getLookupDefaultListForAccountNum(postingType: VoucherPostingTypeEnum): object[] {
    var table: object[];

    switch (postingType) {
      case VoucherPostingTypeEnum.Vendor:
        table = this.vendorsDefault;
        break;
      case VoucherPostingTypeEnum.Project:
        table = this.projectsDefault;
        break;
      default:
        table = this.accountnNumFilterDefaultListData;
    }
    return table;
  }

  private getLookupTableNameForAccountNum(postingType: VoucherPostingTypeEnum): string {
    var tableName: string = '';

    switch (postingType) {
      case VoucherPostingTypeEnum.Vendor:
        tableName = 'vendor';
        break;
      case VoucherPostingTypeEnum.Project:
        tableName = 'project';
        break;
      default:
        tableName = this.accountNumEditDdlCurrentTableName;
    }
    return tableName;
  }

  private postingGridEditFormElementGet(): HTMLFormElement {
    return this.postingGrid.editModule.formObj.element;
  }

  private postingGridEditDataGet(): PostingDto {
    return <PostingDto>this.postingGrid.editModule.getCurrentEditedData(this.postingGridEditFormElementGet(), {});
  }

  private postingGridEditFormInputElementGetByColumnName(columnName: string): HTMLInputElement {
    return <HTMLInputElement>this.postingGridEditFormElementGet().querySelector("#PostingGrid" + columnName);
  }

  private postingGridEditFormInputElementDisable(columnName: string, value: boolean) {
    var i: HTMLInputElement = this.postingGridEditFormInputElementGetByColumnName(columnName);
    if (i) {
      i.disabled = value;
    }
  }

  private postingGridEditFormInputElementSetValue(columnName: string, value: string, id: any = null) {
    var i: HTMLInputElement = this.postingGridEditFormInputElementGetByColumnName(columnName);
    if (i) {
      i.value = value;
      if (i) {
        this.setDropDownListId(i, id);
      }
    }
  }

  private voucherGridEditFormInputElementGetByColumnName(columnName: string): HTMLInputElement {
    return <HTMLInputElement>this.gridEditFormElementGet(this.voucherGrid).querySelector("#VoucherGrid" + columnName);
  }

  private voucherGridEditFormInputElementDisable(columnName: string, value: boolean) {
    var i: HTMLInputElement = this.voucherGridEditFormInputElementGetByColumnName(columnName);
    if (i) {
      i.disabled = value;
    }
  }

  private voucherGridEditFormInputElementSetValue(columnName: string, value: string, id: any = null) {
    var i: HTMLInputElement = this.voucherGridEditFormInputElementGetByColumnName(columnName);
    if (i) {
      i.value = value;
      if (id) {
        this.setDropDownListId(i, id);
      }
    }
  }

  private setDropDownListId(i: HTMLInputElement, id: any) {
    var ej2: any = i;
    if (ej2.ej2_instances) {
      var ej2 = ej2.ej2_instances[0];
      if (ej2 instanceof DropDownList) {
        var ddl: DropDownList = <DropDownList>ej2;
        ddl.value = id;
      }
    }
  }

  // Account num drop down data returned to list
  private accountNumListAddDefaultFromDto(listData: any, fixedType: VoucherPostingTypeEnum, addToStart: boolean = false) {
    switch (fixedType) {
      case VoucherPostingTypeEnum.Vendor:
        var voucherData: VoucherDto = this.gridEditDataGet(this.voucherGrid);

        if (voucherData?.balanceAccountNum && this.isPostingTypeVendor(voucherData.balancePostingType)) {
          if (listData.filter((r: any) => r.num == voucherData.balanceAccountNum).length == 0) {
            var vendorDefault: VendorDto[] = this.vendorsDefault.filter(v => v.num == voucherData.balanceAccountNum);
            if (vendorDefault.length >= 1) {
              var vAdd: VendorDto = Object.assign({}, vendorDefault[0]);
              if (addToStart) {
                listData.unshift(vAdd);
              }
              else {
                listData.push(vAdd);
              }
            }
          }
        }
        break;
      case VoucherPostingTypeEnum.Project:
        var voucherData: VoucherDto = this.gridEditDataGet(this.voucherGrid);
        if (voucherData?.costAccountNum && this.isPostingTypeProject(voucherData.costPostingType)) {
          if (listData.filter((r: any) => r.num == voucherData.costAccountNum).length == 0) {
            var projectDefault: ProjectDto[] = this.projectsDefault.filter(v => v.num == voucherData.costAccountNum);
            if (projectDefault.length >= 1) {
              var pAdd: ProjectDto = Object.assign({}, projectDefault[0]);
              if (addToStart) {
                listData.unshift(pAdd);
              }
              else {
                listData.push(pAdd);
              }
            }
          }
        }
        break;
      default:
        this.accountNumListAddDefaultFromPostingDto(listData, addToStart);
    }
  }

  private accountNumListAddDefaultFromPostingDto(listData: any, addToStart: boolean = false) {
    var postingData: PostingDto = this.postingGridEditDataGet();
    if (postingData && postingData.accountNum) {
      if (listData.filter((r: any) => r.num == postingData.accountNum).length == 0) {
        var postingType: VoucherPostingTypeEnum = this.voucherService.postingTypeEnum(postingData.postingType);
        switch (postingType) {
          case VoucherPostingTypeEnum.Vendor:
            var v = new VendorDto();
            v.num = postingData.accountNum;
            v.description = postingData.accountDescription;
            if (addToStart) {
              listData.unshift(v);
            }
            else {
              listData.push(v);
            }
            break;
          case VoucherPostingTypeEnum.Project,
            VoucherPostingTypeEnum.ProjectRoyalty:
            var p = new ProjectDto();
            p.num = postingData.accountNum;
            p.description = postingData.accountDescription;
            if (addToStart) {
              listData.unshift(p);
            }
            else {
              listData.push(p);
            }
            break;
          case VoucherPostingTypeEnum.Ledger:
            var f = new FinanceLedgerAccountDto();
            f.num = postingData.accountNum;
            f.description = postingData.accountDescription;
            if (addToStart) {
              listData.unshift(f);
            }
            else {
              listData.push(f);
            }
            break;
          case VoucherPostingTypeEnum.Debtor:
            var d = new DebtorDto();
            d.num = postingData.accountNum;
            d.description = postingData.accountDescription;
            if (addToStart) {
              listData.unshift(d);
            }
            else {
              listData.push(d);
            }
            break;
        }
      }
    }
  }

  private accountNumActionComplete(e: any, postingType: VoucherPostingTypeEnum): void {
    this.accountNumListAddDefaultFromDto(e.result, postingType);
    if (this.accountNumFilterArgsQueued) {
      this.accountNumFilterExecute(this.accountNumFilterArgsQueued, postingType);
      this.accountNumFilterArgsQueued = null;
    }
    else {
      this.accountNumFilterIsExecuting = false;
    }
  }

  // Account num drop down data returned to list
  public accountNumActionFailure(e: any, postingType: VoucherPostingTypeEnum): void {
    console.log("Account dropdown failed");
    console.log(e);
    this.accountNumFilterIsExecuting = false;
  }


  public accountNumChanged(args: any, postingTypeFixed: VoucherPostingTypeEnum) {
    // Reset account num DDL state
    this.accountNumFilterArgsQueued = null;
    this.accountNumFilterIsExecuting = false;

    if (args.itemData && args.element) {
      let data = args.itemData;
      if (data) {
        switch (postingTypeFixed) {
          case VoucherPostingTypeEnum.Vendor:
            if (!this.vendorsDefault.find(d => d.num == data.num)) {
              var vAdd: VendorDto = Object.assign({}, data);
              this.vendorsDefault.push(vAdd);
            }
            this.accountNumChangeSyncVoucherGrid(data.nameCurrency, data.currency, null, postingTypeFixed, true);
            this.setVendorPostingDataProforma(data);
            this.setFromName(data);
            break;
          case VoucherPostingTypeEnum.Project:
            if (this.projectsDefault.filter(d => d.num == data.num).length == 0) {
              var pAdd: ProjectDto = Object.assign({}, data);
              this.projectsDefault.push(pAdd);
            }
            var voucherDto = this.accountNumChangeSyncVoucherGrid(data.description, data.currency, data.responsible, postingTypeFixed, true);
            this.setProjectPostingDataProforma(data);
            break;
          default:
            var dto: PostingDto = this.postingGridEditDataGet();
            var responsible: string = null;
            var valid: boolean = true;
            if (dto) {
              switch (dto.postingType) {
                case VoucherPostingTypeEnum.Vendor:
                case VoucherPostingTypeEnum.Debtor:
                  if (data.currency && !this.validateFieldCurrency(data.currency, this.getSelectedVoucher())) {
                    this.postingAccountClear(dto);
                    args.value = '';
                    valid = false;
                  }
                  this.setFromName(data);
                  break;
                case VoucherPostingTypeEnum.Ledger:
                  if (!this.userIsAdmin) {
                    this.error("Finans anvendes kun af økonomi brugerroller");
                    args.value = dto.accountNum;
                    valid = false;
                  }
                  break;
                case VoucherPostingTypeEnum.Project:
                  responsible = data.responsible;
                  break;
              }
            }
            if (valid) {
              var force: boolean = false;
              if (this.isPostingTypeVendor(dto.postingType)) {
                dto.accountDescription = data.nameCurrency;
                dto.vendorBankAccount = data.bankAccount;
                dto.taxCode = data.taxCode;
                if (dto.taxCode == '') {
                  dto.taxCode = null;
                }
                force = true;
              }
              else {
                dto.accountDescription = data.description;
                if (this.isPostingTypeLedger(dto.postingType)) {
                  dto.taxCode = data.taxCode;
                  if (dto.taxCode == '') {
                    dto.taxCode = null;
                  }
                }
                //this.accountNumChangeSyncVoucherGrid(data.description, null, responsible, dto.postingType, force);
              }

              this.postingGridEditFormInputElementSetValue('vendorBankAccount', dto.vendorBankAccount ?? "");
              this.postingGridEditFormInputElementSetValue('accountDescription', dto.accountDescription ?? "");
              this.postingGridEditFormInputElementSetValue('taxCode', dto.taxCode, dto.taxCode);

            }
        }
      }
    }
    this.voucherCanApproveByPostingEditCalc();
  }

  private accountNumChangeSyncVoucherGrid(description: string, currencyISO: string, responsible: string, postingType: VoucherPostingTypeEnum, force: boolean) {
    var voucherDto: VoucherDto = this.gridEditDataGet(this.voucherGrid);

    if (this.voucherService.isPostingTypeBalance(postingType)) {
      if (force || (!voucherDto.balancePostingType) || (voucherDto.balancePostingType === <number>postingType)) {
        voucherDto.balanceAccountDescription = description;
        this.voucherGridEditFormInputElementSetValue('balanceAccountDescription', voucherDto.balanceAccountDescription);
        if (currencyISO) {
          this.setVoucherFieldCurrencyISO(voucherDto, currencyISO);
        }
      }
    }
    else {
      if (force || (!voucherDto.costPostingType) || (voucherDto.costPostingType === <number>postingType)) {
        voucherDto.costAccountDescription = description;
        this.voucherGridEditFormInputElementSetValue('costAccountDescription', voucherDto.costAccountDescription);
        if (responsible) {
          var user: UserDto = this.users.find(u => u.shortName == responsible);
          if (user) {
            voucherDto.approverName = user.name;
            voucherDto.approverShortName = user.shortName;
            voucherDto.approver = user.id;
            this.voucherGridEditFormInputElementSetValue('approverName', user.name);
            this.voucherGridEditFormInputElementSetValue('approverShortName', user.shortName);
            this.voucherGridEditFormInputElementSetValue('approver', user.name, user.id);
          }
        }
      }
    }
    return voucherDto;
  }

  private setProjectPostingDataProforma(project: ProjectDto, voucher: VoucherDto = null) {
    if (this.postingDtoList) {
      for (var i: number = 0; i < this.postingDtoList.length; i++) {
        var row: PostingDto = this.postingDtoList[i];
        if (row.postingType == <number>VoucherPostingTypeEnum.Project ||
          row.postingType == <number>VoucherPostingTypeEnum.ProjectRoyalty) {
          if (this.projectPostingProformaDtoOrg == null) {
            this.projectPostingProformaDtoOrg = new PostingDto();
            Object.assign(this.projectPostingProformaDtoOrg, row);
          }
          if (project) {
            row.accountNum = project.num;
            row.accountDescription = project.description;
          }
          if (voucher) {
            row.projectCostType = voucher.projectCostType;
          }
          this.postingGrid.setRowData(row.id, row);
          break;
        }
      }
    }
  }

  private resetProjectPostingProforma() {
    if (this.projectPostingProformaDtoOrg && this.postingDtoList) {
      for (var i: number = 0; i <= this.postingDtoList.length; i++) {
        var row: PostingDto = this.postingDtoList[i];
        if (row.postingType == <number>VoucherPostingTypeEnum.Project) {
          Object.assign(row, this.projectPostingProformaDtoOrg);
          this.postingGrid.setRowData(row.id, row);
          this.projectPostingProformaDtoOrg = null;
          break;
        }
      }
    }
  }

  private setVendorPostingDataProforma(vendor: VendorDto) {
    if (this.postingDtoList) {
      for (var i: number = 0; i <= this.postingDtoList.length; i++) {
        var row: PostingDto = this.postingDtoList[i];
        if (this.isPostingTypeVendor(row.postingType)) {
          if (this.vendorPostingProformaDtoOrg == null) {
            this.vendorPostingProformaDtoOrg = new PostingDto();
            Object.assign(this.vendorPostingProformaDtoOrg, row);
          }
          row.accountNum = vendor.num;
          row.accountDescription = vendor.nameCurrency;
          row.taxCode = vendor.taxCode;
          row.vendorBankAccount = vendor.bankAccount;
          this.postingGrid.setRowData(row.id, row);
          break;
        }
      }
    }
  }

  private resetVendorPostingProforma() {
    if (this.vendorPostingProformaDtoOrg && this.postingDtoList) {
      for (var i: number = 0; i <= this.postingDtoList.length; i++) {
        var row: PostingDto = this.postingDtoList[i];
        if (row.postingType == <number>VoucherPostingTypeEnum.Vendor) {
          Object.assign(row, this.vendorPostingProformaDtoOrg);
          this.postingGrid.setRowData(row.id, row);
          this.vendorPostingProformaDtoOrg = null;
          this.setFromName();
          break;
        }
      }
    }
  }

  private currencyIdChanged(args: any) {
    if (args.itemData) {
      var voucherDto: VoucherDto = this.gridEditDataGet(this.voucherGrid);
      this.setVoucherFieldCurrencyISO(voucherDto, args.itemData.iso);
    }
  }

  /*
  private approverChanged(args: any) {
    if (args.itemData) {
      var voucherDto: VoucherDto = this.gridEditDataGet(this.voucherGrid);
      voucherDto.approver = args.itemData.id;
    }
  }
  */

  private setVoucherFieldCurrencyISO(voucherDto: VoucherDto, currencyISO: string) {
    if (currencyISO) {
      currencyISO = currencyISO.toUpperCase();
      var currency: CurrencyDto = this.currencies.find(c => c.iso == currencyISO);
      if (!currency) {
        var currencies: CurrencyDto[] = this.currencies.filter(c => c.iso.startsWith(currencyISO));
        if (currencies.length == 1) {
          currency = currencies[0];
        }
        else if (currencies.length == 0) {
          currencies = this.currencies.filter(c => c.iso.includes(currencyISO));
          if (currencies.length == 1) {
            currency = currencies[0];
          }
        }
      }
      if (currency) {
        voucherDto.currency = currency.id;
        voucherDto.currencyISO = currency.iso;
        this.voucherGridEditFormInputElementSetValue('currency', currency.iso, currency.id);
      }
      else {
        this.setVoucherFieldCurrencyId(voucherDto, voucherDto.currency);
      }
    }
  }

  private setVoucherFieldCurrencyId(voucherDto: VoucherDto, currencyId: number) {
    if (currencyId) {
      var currency: CurrencyDto = this.currencies.find(c => c.id == currencyId);
      if (!currency && voucherDto.currency) {
        currency = this.currencies.find(c => c.id == voucherDto.currency);
      }
      voucherDto.currency = currency ? currency.id : null;
      voucherDto.currencyISO = currency ? currency.iso : null;
      voucherDto.currencyName = currency ? currency.name : null;
      this.voucherGridEditFormInputElementSetValue('currency', voucherDto.currencyISO, voucherDto.currency);
    }
  }

  private validateFieldCurrency(currency: string, voucherDto: VoucherDto): boolean {
    var ok: boolean = true;
    if (voucherDto.currencyISO != currency) {
      this.dialogService.infoPrompt("Valuta på kreditor '" + currency + "' matcher ikke valuta på bilag '" + voucherDto.currencyISO + "'");
      ok = false;
    }
    return ok;
  }

  // Misc ----------------------------------
  private getSelectedVoucher(): VoucherDto {
    var dto: VoucherDto = null;

    // Use first selected row
    var firstRow = this.voucherGrid.getSelectedRows()[0];
    // If none selected use first row
    if (!firstRow && this.voucherGrid.getDataRows().length > 0) {
      firstRow = this.voucherGrid.getDataRows()[0];
    }
    if (firstRow) {
      var gridRow = this.voucherGrid.getRowObjectFromUID(firstRow.getAttribute('data-uid'));
      if (gridRow) {
        dto = <VoucherDto>gridRow.data;
      }
    }
    return dto;
  }

  private getSelectedPosting(): PostingDto {
    var dto: PostingDto = null;

    // Use first selected row
    var firstRow = this.postingGrid.getSelectedRows()[0];
    // If none selected use first row
    if (!firstRow && this.postingGrid.getDataRows().length > 0) {
      firstRow = this.postingGrid.getDataRows()[0];
    }
    if (firstRow) {
      var gridRow = this.postingGrid.getRowObjectFromUID(firstRow.getAttribute('data-uid'));
      if (gridRow) {
        dto = <PostingDto>gridRow.data;
      }
    }
    return dto;
  }

  private splitterResize(addPx: number) {
    if (this.attachmentsIsActive) {
      var size: string = this.splitterObj.paneSettings[1].size;
      var sizeNum: number = Number(size.substring(0, size.length - 2)) + addPx;
      size = sizeNum + 'px';
      this.splitterObj.paneSettings[1].size = size;
      if (this.fileViewerTab) {
        this.fileViewerTab.resize();
      }
    }
  }

  public onSplitterResizing(args: any) {
    if (this.fileViewerTab) {
      this.fileViewerTab.resize();
    }
  }

  private attachmentsShowHide() {
    if (this.splitterObj != null) {
      if (this.attachmentsIsActive && this.splitterObj.paneSettings[1].collapsed) {
        this.splitterObj.expand(1);
      }
      else {
        this.attachmentsIsActive = !this.attachmentsIsActive;
        if (this.attachmentsIsActive) {
          this.splitterObj.expand(1);
        }
        else {
          this.splitterObj.collapse(1)
        }
      }
    }
    else {
      this.attachmentsIsActive = !this.attachmentsIsActive;
    }
    if (this.attachmentsIsActive && this.detailsVm == null && this.messageGrid.getRows().length > 0) {
      this.messageGrid.selectRow(0);
    }
  }

  public attachmentsPageNext() {
    if (this.attachmentsIsActive && this.detailsVm && this.detailsVm.voucherMessageAttachmentDtoList) {
      if (this.attachmentSelectedIdxDefault + 1 < this.detailsVm.voucherMessageAttachmentDtoList.length) {
        this.attachmentSelectedIdxDefault++;
      }
    }
  }
  public attachmentsPagePrev() {
    if (this.attachmentsIsActive && this.detailsVm && this.detailsVm.voucherMessageAttachmentDtoList) {
      if (this.attachmentSelectedIdxDefault > 0) {
        this.attachmentSelectedIdxDefault--;
      }
    }
  }

  public onAttachmentUploadChange(event: any) {
    if (event == 'Close') {
      this.attachmentsShowHide();
    }
    else {
      if (this.detailsVm != null) {
        var id: number = this.detailsVm.voucherMessageViewDto.id
        this.detailsVmMap.delete(id);
        this.detailsVmDataBind();
      }
    }
  }

  public onAttachmentCheckBoxApply(checkedFileNames: string[]) {
    var id: number = this.detailsVm?.voucherMessageViewDto?.id;
    if (id != null) {
      var callback = () => {
        this.detailsVmMap.delete(id);
        this.detailsVmDataBind();
      }
      VoucherAttachmentService.messageSplitByAttachment(id, checkedFileNames, callback, this.messageClient, this.dialogService, this.ui);
    }
  }

  public onAttachmentTabSelectedIndexChanged(tabActiveIdx: number) {
    this.attachmentSelectedIdxDefault = tabActiveIdx;
  }

  // Save mode
  protected buttonDisabledClass: string = '';
  protected saveAndClose(saveAndCloseMode: 'Approved' | 'PendingApproval' | 'OnHold' | 'NewApprover' | 'SaveOnly') {
    if (this.buttonDisabledClass.length > 0 && saveAndCloseMode != 'SaveOnly') {
      return;
    }
    this.buttonDisabledClass = "button-disabled-class";
    this.saveAndCloseMode = saveAndCloseMode;
    this.saveAndCloseBeginPromise().then(ok => {
      this.buttonDisabledClass = "";
      if (ok) {
        this.close();
      }
    });
  }

  private saveAndCloseMode: 'Approved' | 'PendingApproval' | 'OnHold' | 'NewApprover' | 'SaveOnly' = null;
  protected saveAndCloseContinue() {
    if (this.saveAndCloseMode != null) {
      this.saveAndCloseCompletePromise().then(ok => {
        this.buttonDisabledClass = "";
        if (ok) {
          this.close();
        }
      })
        .finally(() => {
          this.buttonDisabledClass = "";
        });
    }
  }

  protected async saveAndCloseBeginPromise(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (!this.canEdit) {
        resolve(false);
      }
      else {
        if (this.postingGrid && this.postingGrid.isEdit) {
          this.postingGrid.endEdit()
          reject(); // Begin promise, use complete promise by grid save 
        }
        else if (this.voucherGrid && this.voucherGrid.isEdit) {
          this.voucherGrid.endEdit();
          reject(); // Begin promise, use complete promise by grid save 
        }
        else {
          this.saveAndCloseCompletePromise().then((ok) => { resolve(ok); });
        }
      }
    });
  }

  protected async saveAndCloseCompletePromise(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      var saveMode = this.saveAndCloseMode;
      this.saveAndCloseMode = null;

      if (this.detailsVm?.voucherViewDtoList == null || this.detailsVm.voucherViewDtoList.length == 0) {
        resolve(false);
      }
      var voucherDto: VoucherDto = this.detailsVm.voucherViewDtoList[0];
      switch (saveMode) {
        case 'SaveOnly':
          resolve(true);
          break;
        case 'Approved':
          if (this.voucherCanApprove && voucherDto != null) {
            this.setApprovalStatusPromise(voucherDto.id, VoucherApprovalStatusEnum.Approved).finally(() => {
              resolve(true);
            });
          }
          else {
            resolve(false);
          }
          break;
        case 'PendingApproval':
          if (this.voucherCanPending && voucherDto != null) {
            if (voucherDto.approver == null) {
              this.setNewApprover(voucherDto).then(detailsVm => {
                this.setApprovalStatusPromise(voucherDto.id, VoucherApprovalStatusEnum.Pending).then(detailsVm => {
                  resolve(true);
                }).catch(() => { resolve(false); });
              }).catch(() => { resolve(false); });
            }
            else {
              this.setApprovalStatusPromise(voucherDto.id, VoucherApprovalStatusEnum.Pending).then(detailsVm => {
                resolve(true);
              })
                .catch(() => { resolve(false); });
            }
          }
          else {
            resolve(false);
          }
          break;
        case 'NewApprover':
          if (this.canNewApprover && voucherDto != null) {
            this.setNewApprover(voucherDto).then(detailsVm => {
              this.setApprovalStatusPromise(voucherDto.id, VoucherApprovalStatusEnum.Pending).then(detailsVm => {
                resolve(true);
              });
            })
              .catch(() => { resolve(false); });
          }
          else {
            resolve(false);
          }
          break;
        case 'OnHold':
          if (this.voucherCanHold && voucherDto != null) {
            this.setApprovalStatusPromise(voucherDto.id, VoucherApprovalStatusEnum.OnHold).then(detailsVm => {
              resolve(true);
            });
          }
          else {
            resolve(false);
          }
          break;
      }
    });
  }

  protected close() {
    this.dialogRef.close({ needRefresh: true });
  }

  private setNewApprover(voucherDto: VoucherDto): Promise<DetailsVm> {
    return new Promise<DetailsVm>((resolve, reject) => {
      this.getNewApprover(voucherDto).then(newApprover => {
        if (newApprover != null) {
          if (voucherDto.approver != newApprover) {
            voucherDto.approver = newApprover;
            this.apiVoucherPutPromise(voucherDto).then(detailsVm => {
              resolve(detailsVm);
            });
          }
          else {
            resolve(this.detailsVm);
          }
        }
        else {
          reject();
        }
      })
        .catch(() => {
          reject();
        });
    });
  }

  private getNewApprover(voucherDto: VoucherDto): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      var inputs: DialogInput[] = [
        {
          label: 'Godkender', id: "userId", placeholder: 'Angiv godkender', valueNumber: voucherDto?.approver,
          type: 'DropDownList', dataSource: this.baseDataVm.users, fields: { text: 'shortName', value: 'id' }, element: null
        }
      ];
      this.ui.inputPrompt('', 'Angiv godkender', inputs).then(
        result => {
          if (result.ok) {
            resolve(result.dialogInputs[0].ej2.value);
          }
          else {
            reject();
          }
        }
      );
    });
  }

  // Grid navigation -->


  private postingGridRefresh(rowIdx: number) {
    if (this.postingGrid && this.postingGrid.headerModule) {
      if (rowIdx >= 0) {
        if (this.gridNavigationCmd != GridNavigationCmd.Default) {
          this.gridNavigationCmd = GridNavigationCmd.PostingCurrent;
          this.postingGridRequestEditIdx = rowIdx;
        }
      }
      this.postingGrid.refresh();
    }
  }

  private gridStartSelectRow() {
    if (this.canEdit && this.voucherGrid && this.postingGrid && this.voucherGridIsDataBound && this.postingGridIsDataBound) {
      this.gridEndEdit();
      if (this.gridNavigationCmd == GridNavigationCmd.Default) {
        this.voucherGridStartEditDefaultCalc();
      }
      if (this.gridNavigationCmd == GridNavigationCmd.Default) {
        this.postingGridStartEditDefaultCalc();
      }
      if (this.gridNavigationCmd == GridNavigationCmd.Default) {
        this.gridNavigationCmd = GridNavigationCmd.None;
      }
      //console.log('StartEdit: ' + this.gridNavigationCmd + ' V:' + this.voucherGridRequestEditIdx + ' P:' + this.postingGridRequestEditIdx);

      switch (this.gridNavigationCmd) {
        case GridNavigationCmd.VoucherCurrent:
          this.voucherGridStartEdit();
          break;
        case GridNavigationCmd.VoucherFirst:
          this.voucherGridRequestEditIdx = 0;
          this.voucherGridStartEdit();
          break;
        case GridNavigationCmd.VoucherNext:
          // this.postingGridMoveNext();
          this.voucherGridStartEdit();
          break;
        case GridNavigationCmd.VoucherPrevious:
          //this.postingGridMovePrevious();
          this.voucherGridStartEdit();
          break;
        case GridNavigationCmd.VoucherLast:
          this.voucherGridRequestEditIdx = this.voucherDtoList.length - 1;

          this.voucherGridStartEdit();
          break;
        case GridNavigationCmd.PostingCurrent:
          this.postingGridStartEdit();
          break;
        case GridNavigationCmd.PostingNext:
          // this.postingGridMoveNext();
          this.postingGridStartEdit();
          break;
        case GridNavigationCmd.PostingPrevious:
          //this.postingGridMovePrevious();
          this.postingGridStartEdit();
          break;
        case GridNavigationCmd.PostingFirst:
          this.postingGridRequestEditIdx = 0;
          this.postingGridStartEdit();
          break;
        case GridNavigationCmd.PostingLast:
          this.postingGridRequestEditIdx = this.postingDtoList.length - 1;
          this.postingGridStartEdit();
          break;
      }
    }
  }

  private voucherGridStartEditDefaultCalc() {
    if ((this.userIsAdmin || this.voucherIsStatusCreated) && this.voucherGrid && this.voucherGrid.editSettings.allowEditing) {
      var rows: any = this.voucherGrid.dataSource;
      var idx: number = -1;
      var r: number = 0;
      if (rows) {
        rows.forEach((row: any) => { if (idx == -1 && !this.voucherService.voucherIsComplete(<VoucherDto>row, this.userIsAdmin, this.isExpenseVoucher)) { idx = r }; r++; });
        if (idx >= 0) {
          this.voucherGridRequestEditIdx = idx;
          this.gridNavigationCmd = GridNavigationCmd.VoucherCurrent;
          this.gridTryEdit = true;
        }
      }
    }
  }

  private postingGridStartEditDefaultCalc() {
    if (this.postingGrid && this.postingGrid.editSettings.allowEditing) {
      var rows: any = this.postingGrid.dataSource;
      var idx: number = -1;
      var r: number = 0;
      if (rows) {
        rows.forEach((row: any) => { if (idx == -1 && !this.voucherService.postingIsComplete(<PostingDto>row, this.userIsAdmin)) { idx = r }; r++; });
        if (idx >= 0) {
          this.postingGridRequestEditIdx = idx;
          this.gridNavigationCmd = GridNavigationCmd.PostingCurrent;
          this.gridTryEdit = true;
        }
      }
    }
  }

  private postingGridFirstUncompleteRowIdx(): number {
    var rowIdx: number = -1;
    var i: number = 0;

    for (var postingDto of <PostingDto[]>this.postingGrid.currentViewData) {
      var complete: boolean =
        (postingDto.accountNum && postingDto.accountNum.length &&
          postingDto.amountCur && postingDto.amountCur != 0);

      if (<VoucherPostingTypeEnum>postingDto.postingType == VoucherPostingTypeEnum.Project ||
        <VoucherPostingTypeEnum>postingDto.postingType == VoucherPostingTypeEnum.ProjectRoyalty) {
        if (!postingDto.projectCostType || postingDto.projectCostType.length == 0 ||
          (this.userIsAdmin && (!postingDto.projectLedgerNum || postingDto.projectLedgerNum.length == 0))) {
          complete = false;
        }
      }
      if (!complete) {
        rowIdx = i;
        break;
      }
      i++;
    }

    return rowIdx;
  }

  private voucherGridCalcMoveDown() {
    if (this.voucherGrid.getSelectedRows().length >= 1) {
      var idx: number = 0; //.voucherGrid.getSelectedRows()[0];

    }
  }

  private gridEndEdit() {
    if (this.voucherGrid && this.voucherGrid.isEdit) {
      this.voucherGrid.endEdit();
    }
    if (this.postingGrid && this.postingGrid.isEdit) {
      this.postingGrid.endEdit();
    }
  }

  private voucherGridStartEdit() {
    var idx: number = this.voucherGridRequestEditIdx;
    this.voucherGridRequestEditIdx = -1;
    if (this.voucherGrid) {
      if (idx > -1 && idx < this.voucherGrid.getRows().length) {
        this.voucherGrid.selectRow(idx);
      }
      else if (this.voucherGrid.getSelectedRows().length > 0 && !this.voucherGrid.isEdit && this.voucherGrid.editSettings.allowEditing && this.gridTryEdit) {
        this.gridTryEdit = false;
        this.voucherGrid.startEdit();
        this.voucherGrid.focusModule.focus();
      }
    }
  }

  private postingGridStartEdit(forceEdit: boolean = false) {
    var idx: number = this.postingGridRequestEditIdx;
    this.postingGridRequestEditIdx = -1;
    if (this.postingGrid) {
      if (idx > -1 && idx < this.postingGrid.getRows().length) {
        this.postingGrid.selectRow(idx); // Calls recursive using onRowSelected
      }
      else if (this.postingGrid.getSelectedRows().length > 0 && !this.postingGrid.isEdit && this.postingGrid.editSettings.allowEditing) {
        this.postingGrid.startEdit();
        this.postingGrid.focusModule.focus();
      }
    }
  }

  // Grid navigation <--
  // Grid edit complete
  /*
    /*
    private voucherGridMove(isDown: boolean) {
      var rowIdx: number = this.voucherGrid.selectedRowIndex;
      if (isDown) {
        var maxIdx: number = this.postingGrid.getRows().length - 1;
        if (rowIdx < maxIdx) {
          this.postingGrid.selectedRowIndex = rowIdx + 1;
          this.postingGrid.startEdit();
        }
        else {
          maxIdx = this.postingGrid.getRows().length - 1;
          if (maxIdx >= 0) {
            this.voucherGrid.selectedRowIndex = 0;
            this.voucherGrid.startEdit();
          }
        }
      }
      else if (this.voucherGrid.selectedRowIndex > 0) {
        this.voucherGrid.selectedRowIndex = this.voucherGrid.selectedRowIndex - 1;
      }
    }

  voucherGridChange(args) {
    console.log(args);
    let gridElement = document.getElementsByClassName('e-grid')[1] as any;
    if (gridElement != null) {
      let gridObj = gridElement.ej2_instances[0]; //get gridobj
      var selRow0 = <Element>gridObj.getSelectedRows()[0];
      if (selRow0) {
        var gridRow = this.voucherGrid.getRowObjectFromUID(selRow0.getAttribute('data-uid'));
        // refresh related data based
        //var kDto = <HovedkontraktViewDto> gridRow.data;
        //var fDto = <ForfatterDto> args.itemData;
        //kDto.agent = fDto.id;
        //kDto.agentId = fDto.forfatterId;
        //kDto.agentNavn = fDto.navn;
      }
    }
  }
  */

  /*
if (this.voucherGridRequestEditIdx != -1) {
  this.voucherGridStartEdit();
}
else if (this.postingGridRequestEditIdx != -1) {
  this.postingGridStartEdit());
}
*/

  //    this.messageGrid.refresh();

  //this.voucherGrid.refresh();
  //if (this.voucherDtoList.length > 0) {
  //this.voucherGrid.selectRow(0);
  // this.voucherGrid.beginEdit();
  //

  //this.postingGrid.refresh();

} // End component




function convertDateToUTC(date: Date) {
  if (date != null) {
    date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
  }
  return date;
}

import { Component, ViewChild } from '@angular/core';

import { FieldMetadata, FieldWidthEnum, ViewMetadata } from 'src/cer/cer-data/cer-data.service';
import { CerFormPaneVisible } from 'src/cer/cer-form/cer-form-panes.service';
import { CerGridComponent } from 'src/cer/cer-grid/cer-grid.component';
import { UiCommand } from 'src/cer/cer-grid/cer-grid-command.service';

@Component({
  selector: 'app-project-node',
  templateUrl: './project-node.component.html',
  styleUrls: ['./project-node.component.css']
})

export class ProjectNodeComponent {
  // View
  public viewMetadata: ViewMetadata = {
    name: 'project-node', dataApiName: 'ProjectNode', text: 'Projekt struktur',
    primaryKey: 'id', titleFields: ['name'], dialogEdit: true, allowEdit: true, allowCreate: true, allowDelete: true, baseFields: ['history']
  };
  // Fields
  public fieldMetadata: FieldMetadata[] =
    [
      { name: 'id', text: 'Id', tooltipText: 'Unik nøgle for skabelonen', allowEdit: false, visible: false, widthType: FieldWidthEnum.XS },
      { name: 'name', text: 'Navn', tooltipText: 'Projektnavn', widthType: FieldWidthEnum.M, required: true },
      { name: 'level', text: 'Niveau', tooltipText: 'Projektniveau', widthType: FieldWidthEnum.M, foreignKeyTableName: 'ProjectLevel', required: true, orderBy: 'Ascending' },
      { name: 'taskStatus', text: 'Opgavestatus', tooltipText: 'Status omkring opgaveløsning', widthType: FieldWidthEnum.M, foreignKeyTableName: 'ProjectTaskStatus', required: true },
      { name: 'parentId', text: 'Overordnet struktur', tooltipText: 'Overordnet projektstruktur', widthType: FieldWidthEnum.M, foreignKeyTableName: 'ProjectNode' },
      { name: 'responsible', text: 'Ansvarlig', tooltipText: 'Ansvarlig medarbejder', widthType: FieldWidthEnum.M, foreignKeyTableName: 'User', required: true }
    ];

  detailGrid: CerGridComponent;
  @ViewChild('detailGrid', { static: false }) set gridContentDetail(content: CerGridComponent) {
    if (content && !this.detailGrid) {
      this.detailGrid = content;
    }
  }

  // Detail view
  public detailViewMetadata: ViewMetadata = {
    name: 'project-task', dataApiName: 'ProjectTask', text: 'Opgaver',
    primaryKey: 'id', parentPrimaryKey: 'id', parentForeignKey: 'projectNode',
    titleFields: ['name'], dialogEdit: true, allowEdit: true, allowCreate: true, allowDelete: true, baseFields: ['history']
  };
  // Detail fields
  public detailFieldMetadata: FieldMetadata[] =
    [
      { name: 'id', text: 'Id', tooltipText: 'Unik nøgle for skabelonen', allowEdit: false, visible: false, widthType: FieldWidthEnum.XS },
      { name: 'name', text: 'Navn', tooltipText: 'Overskrift', widthType: FieldWidthEnum.M, orderBy: 'Ascending' },
      { name: 'projectNode', text: 'Projekt', tooltipText: 'Overskrift', widthType: FieldWidthEnum.M, foreignKeyTableName: 'ProjectNode', visible: false },
      { name: 'type', text: 'Type', tooltipText: 'Opgavetype', widthType: FieldWidthEnum.M, foreignKeyTableName: 'ProjectTaskType' },
      { name: 'status', text: 'Status', tooltipText: 'Opgavestatus', widthType: FieldWidthEnum.M, foreignKeyTableName: 'ProjectTaskStatus' },
      { name: 'responsible', text: 'Ansvarlig', tooltipText: 'Ansvarlig medarbejder', widthType: FieldWidthEnum.M, foreignKeyTableName: 'User' },
      { name: 'description', text: 'Beskrivelse', tooltipText: 'Beskrivelse', widthType: FieldWidthEnum.M, visible: false }

    ];

  public detail: CerFormPaneVisible = 'show';
  public detailWidth: string = '30%';
  public detailToolbarCommands: UiCommand[];
  public detailContextMenuCommands: UiCommand[];
}

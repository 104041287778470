import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

import { DataManager } from '@syncfusion/ej2-data';

import { FieldMetadata, FieldWidthEnum, ViewMetadata } from 'src/cer/cer-data/cer-data.service';
import { CerFormComponent } from 'src/cer/cer-form/cer-form.component';
import { CerGridComponent } from 'src/cer/cer-grid/cer-grid.component';
import { UiActionTypeEnum, UiCommandEvent, UiCommandSourceEnum } from 'src/cer/cer-grid/cer-grid-command.service';
import { ProjectTaskDocComponent } from '../project-task-doc/project-task-doc.component';

@Component({
  selector: 'project-task',
  templateUrl: './project-task.component.html',
  styleUrls: ['project-task.component.css']
})
export class ProjectTaskComponent implements OnInit {

  grid: CerGridComponent;
  @ViewChild('grid', { static: false }) set gridContent(content: CerGridComponent) {
    if (content && !this.grid) {
      this.grid = content;
      this.grid.commmand.subscribe(e => this.onCommand(e));
    }
  }

  form: CerFormComponent;
  @ViewChild('form', { static: false }) set formContent(content: CerFormComponent) {
    if (content && !this.form) {
      this.form = content;
      this.form.commmand.subscribe(e => this.onCommand(e));
    }
  }

  // View
  public viewMetadata: ViewMetadata = {
    name: 'project-task', dataApiName: 'ProjectTask', text: 'Projekt opgaver',
    primaryKey: 'id', titleFields: ['name'], dialogEdit: true, allowEdit: true, allowCreate: true, allowDelete: true, baseFields: ['history']
  };
  // Fields
  public fieldMetadata: FieldMetadata[] =
    [
      { name: 'id', text: 'Id', tooltipText: 'Unik nøgle for skabelonen', allowEdit: false, visible: false, widthType: FieldWidthEnum.XS },
      { name: 'name', text: 'Navn', tooltipText: 'Overskrift', widthType: FieldWidthEnum.M, orderBy: 'Ascending' },
      { name: 'projectNode', text: 'Projekt', tooltipText: 'Overskrift', widthType: FieldWidthEnum.M, foreignKeyTableName: 'ProjectNode' },
      { name: 'type', text: 'Type', tooltipText: 'Opgavetype', widthType: FieldWidthEnum.M, foreignKeyTableName: 'ProjectTaskType' },
      { name: 'status', text: 'Status', tooltipText: 'Opgavestatus', widthType: FieldWidthEnum.M, foreignKeyTableName: 'ProjectTaskStatus' },
      { name: 'responsible', text: 'Ansvarlig', tooltipText: 'Ansvarlig medarbejder', widthType: FieldWidthEnum.M, foreignKeyTableName: 'User' },
      { name: 'description', text: 'Beskrivelse', tooltipText: 'Beskrivelse', widthType: FieldWidthEnum.M, visible: false }
    ];

  public description: string = '';

  constructor(private dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  // Commands
  public onCommand(event: UiCommandEvent) {
    switch (event.source) {
      case UiCommandSourceEnum.ActionBegin:
        break;
      case UiCommandSourceEnum.ActionComplete:
        switch (event.actionType) {
          case UiActionTypeEnum.RowSelect:
            this.description = event.args.data.description;
            break;
        }
        break;
    }
  }

  public editDescription() {
    var data: any = this.grid.rowSelectedData();
    this.grid.disable();

    var config = <MatDialogConfig>{ width: '800px', height: '600px', maxWidth: '95vw', data: { html: this.description } };
    var dialogRef: MatDialogRef<ProjectTaskDocComponent> = this.dialog.open(ProjectTaskDocComponent, config);
    dialogRef.afterClosed().subscribe(result => {
      this.grid.enable();
      if (result.dataChanged) {
        this.description = result.html;
        data.description = result.html;
        this.grid.grid.setCellValue(data.id, 'description', result.html);
        var dm: DataManager = <DataManager>this.grid.grid.dataSource;
        dm.update(data.id, data);
      }
    });
  }
}

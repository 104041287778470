<cer-form #form [panes]="{'right': { visible: 'show' }}">
  <ng-template #mainTemplate>
    <cer-grid
      #grid
      id="grid"
      height="100%"
      [viewMetadata]="viewMetadata"
      [fieldMetadata]="fieldMetadata"
      dataRelationRole="parent"
    ></cer-grid>
  </ng-template>
  <ng-template #rightTemplate>
    <cer-grid
      #detailGrid
      id="detailGrid"
      [viewMetadata]="detailViewMetadata"
      [fieldMetadata]="detailFieldMetadata"
      mode="simpleToolbar"
      autoFocus="disabled"
      selectionMode="row"
      dataRelationRole="child"
    ></cer-grid>
  </ng-template>
</cer-form>

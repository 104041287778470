import { FieldFormatEnum } from 'src/cer/cer-data/cer-data.service';
import { CerAppFormModelAdapter } from '../../../platform/models/cer-app-form-model-adapter';

export class SalesSettlementTypeFormAdapter extends CerAppFormModelAdapter {

  override init() {

    this.viewMetadata = {
      name: 'salg-afregningstyper', dataApiName: 'SalesSettlementType', text: 'Salg afregningstyper',
      primaryKey: 'num', baseFields: ['id', 'num', 'name', 'history']
    };

    this.fieldMetadata = [
      { name: 'createInvoice', text: 'Dan faktura', format: FieldFormatEnum.CheckBox, defaultValue: true },
      { name: 'capturePayment', text: 'Capture betaling', format: FieldFormatEnum.CheckBox, defaultValue: false }
    ];
   
  }
}

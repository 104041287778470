import { BankAccountSourceSyncCommand, BankAccountTransactionDto, BankSettlementStatusEnum, BankTransactionBatchStatusSetCommand, BankTransactionSourceBatchClient } from '../../api';
import { FieldFormatEnum, FieldWidthEnum } from '../../../cer/cer-data/cer-data.service';
import { CerAppFormModelAdapter } from '../../../platform/models/cer-app-form-model-adapter';
import { UiCommand, UiKeyboardShortcut, UiCommandEvent } from '../../../cer/cer-grid/cer-grid-command.service';

export class BankAccountTransactionBatchFormAdapter extends CerAppFormModelAdapter {

  private uiCmdSyncBatch: UiCommand = { id: 'SyncBatch', text: 'Synkroniser kilder', tooltipText: 'Synkroniser transaktioner fra alle kilder (ALT+S)', iconCss: 'e-icons-org e-repeat', target: ".e-content", needSelection: false };
  private uiCmdSetOpen: UiCommand = { id: 'SetOpen', text: 'Åbn', tooltipText: 'Åbn transaktioner for den markerede (ALT+O)', iconCss: 'e-icons.org e-play', target: ".e-content", needSelection: true };
  private uiCmdSetDraft: UiCommand = { id: 'SetDraft', text: 'Kladde', tooltipText: 'Transaktioner har status Kladde for den markerede (ALT+K)', iconCss: 'e-icons.org e-pause', target: ".e-content", needSelection: true };
  private uiCmdSyncBatchShortcut: UiKeyboardShortcut = { cmd: this.uiCmdSyncBatch, code: 'KeyS', shift: false, ctrl: false, alt: true };
  private uiCmdSetOpenShortcut: UiKeyboardShortcut = { cmd: this.uiCmdSetOpen, code: 'KeyO', shift: false, ctrl: false, alt: true };
  private uiCmdSetDraftShortcut: UiKeyboardShortcut = { cmd: this.uiCmdSetDraft, code: 'KeyK', shift: false, ctrl: false, alt: true };

  constructor() {
    super();

    this.viewMetadata = {
      name: 'bank-transaktion-indlæsning', dataApiName: 'BankAccountTransactionSourceBatch', text: 'Indlæsninger',
      primaryKey: 'id', allowEdit: false, allowCreate: false, allowDelete: true, baseFields: ['id', 'history']
    };

    this.fieldMetadata =
      [
        { name: 'batchDate', text: 'Dato', format: FieldFormatEnum.DateShort, orderBy: 'Descending', orderByPriority: 1 },
        { name: 'bankAccount', text: 'Bankkonto', foreignKeyTableName: "BankAccount", widthType: FieldWidthEnum.XXS, orderBy: 'Ascending', orderByPriority: 2 },
        { name: 'source', text: 'Kilde', foreignKeyTableName: "BankAccountTransactionSource", widthType: FieldWidthEnum.XXS, filterValue: [2, 11, 12, 13], filterOperator: 'in', filterOnClient: true },
        { name: 'sourceId', text: 'Dokument', format: FieldFormatEnum.Integer, widthType: FieldWidthEnum.XXS, visible: false },
        { name: 'name', text: 'Navn', visible: false },
        { name: 'startBalance', text: 'Startsaldo', format: FieldFormatEnum.Amount },
        { name: 'endBalance', text: 'Slutsaldo', format: FieldFormatEnum.Amount },
        { name: 'status', text: 'Status', foreignKeyTableName: "BankAccountTransactionStatus", widthType: FieldWidthEnum.XXS }
      ];

    this.toolbarCommands = [this.uiCmdSyncBatch, this.uiCmdSetOpen, this.uiCmdSetDraft];
    this.contextMenuCommands = [this.uiCmdSetOpen, this.uiCmdSetDraft];
    this.keyboardShortcuts = [this.uiCmdSyncBatchShortcut, this.uiCmdSetOpenShortcut, this.uiCmdSetDraftShortcut];

    this.detailViewMetadata = {
      name: 'bank-transaktion', dataApiName: 'BankAccountTransaction', text: 'Poster',
      primaryKey: 'id', parentPrimaryKey: 'id', parentForeignKey: 'sourceBatch', baseFields: ['id', 'history']
    };

    this.detailFieldMetadata =
      [
        { name: 'id', text: 'Id', tooltipText: 'Unik nøgle for posten', visible: false, orderBy: 'Descending' },
        { name: 'sourceBatch', text: 'Batch', format: FieldFormatEnum.Integer, visible: false },
        { name: 'bankAccount', text: 'Bankkonto', foreignKeyTableName: "BankAccount", widthType: FieldWidthEnum.S, visible: false },
        { name: 'source', text: 'Kilde', foreignKeyTableName: "BankAccountTransactionSource", visible: false },
        { name: 'bankTransId', text: 'Bank trans id', widthType: FieldWidthEnum.XS, visible: false },
        { name: 'bankTransType', text: 'Bank trans type', widthType: FieldWidthEnum.XS, visible: false },
        { name: 'transDate', text: 'Dato', format: FieldFormatEnum.DateShort, widthType: FieldWidthEnum.S, orderBy: 'Descending' },
        { name: 'txt', text: 'Tekst', widthType: FieldWidthEnum.XXL },
        { name: 'authReference', text: "Nota Indløser", widthType: FieldWidthEnum.XXS },
        { name: 'bankCreditCard', text: "Kreditkort", widthType: FieldWidthEnum.XS, foreignKeyTableName: 'BankCreditCard', visible: false },
        { name: 'user', text: 'Medarbejder', widthType: FieldWidthEnum.XXS, foreignKeyTableName: 'User', foreignKeyValue: 'shortName' },
        { name: 'amount', text: 'Beløb', format: FieldFormatEnum.Amount, widthType: FieldWidthEnum.L, footerType: 'sum' },
        { name: 'balance', text: 'Saldo', format: FieldFormatEnum.Amount, widthType: FieldWidthEnum.L },
        { name: 'currency', text: 'Valuta', foreignKeyTableName: 'Currency', foreignKeyValue: 'iso', widthType: FieldWidthEnum.XXS },
        { name: 'status', text: 'Status', foreignKeyTableName: "BankAccountTransactionStatus", widthType: FieldWidthEnum.XXS }
      ];

    this.rightPaneWidthPct = 60;
  }

  override onCommand(event: UiCommandEvent) {
    switch (event.commandId) {
      case this.uiCmdSyncBatch.id:
        this.syncBatch();
        break;
      case this.uiCmdSetOpen.id:
        this.setStatus(event, BankSettlementStatusEnum.Open);
        break;
      case this.uiCmdSetDraft.id:
        this.setStatus(event, BankSettlementStatusEnum.Draft);
        break;
    }
  }

  override onDetailCommand(event: UiCommandEvent) {
  }

  private syncBatch() {
    var cmd = new BankAccountSourceSyncCommand({ syncGenericSources: true });
    this.syncBatchApi(cmd);
  }

  private syncBatchApi(cmd: BankAccountSourceSyncCommand) {
    this.routeService.isLoading$.next(true);
    var snackBarRef = this.dialogService.snackBar('Synkroniserer kilder', 'Vent venligst...', undefined);
    //var = new BankTransactionSourceBatchClient());
    var batchClient: BankTransactionSourceBatchClient = this.injector.get(BankTransactionSourceBatchClient);
    batchClient.syncBatch(cmd).subscribe({
      next: (result) => {
        snackBarRef?.dismiss();
        this.dialogService.snackBar(result + ' bevægelser hentet');
        this.grid.research();
        this.routeService.isLoading$.next(false);
      },
      error: (error) => {
        snackBarRef?.dismiss();
        this.routeService.isLoading$.next(false);
        this.appStateService.handleApiError(error);
        console.error(error)
      }
    });
  }

  private setStatus(event: UiCommandEvent, status: BankSettlementStatusEnum) {
    var rowData : any  = event.args?.rowData;
    if (rowData == null) {
      rowData = event.args?.rowInfo?.rowData;
    }
    if (rowData == null) {
      rowData = this.grid.rowSelectedData();
    }
    var id: number = rowData?.id;
    if (id != null) {
      var idList: number[] = [id];
      var cmd = new BankTransactionBatchStatusSetCommand({ batchIdList: idList, status: status });
      this.setStatusApi(cmd);
    }
  }

  private setStatusApi(cmd: BankTransactionBatchStatusSetCommand) {
    this.routeService.isLoading$.next(true);
    var  batchClient: BankTransactionSourceBatchClient = this.injector.get(BankTransactionSourceBatchClient);
    batchClient.setStatus(cmd).subscribe({
      next: (count) => {
        if (count > 0) {
          this.grid.research();
        }
        else {
          this.dialogService.snackBar('Ingen indlæsning er opdateret');
        }
        this.routeService.isLoading$.next(false);
      },
      error: (error) => {
        this.routeService.isLoading$.next(false);
        this.appStateService.handleApiError(error);
        console.error(error)
      }
    });
  }

}

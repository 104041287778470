import { CustomSummaryType, ICellFormatter, QueryCellInfoEventArgs } from '@syncfusion/ej2-angular-grids';
import { Injectable, OnDestroy } from '@angular/core';
import { DataManager, Query, Predicate, ReturnOption, WebApiAdaptor } from '@syncfusion/ej2-data';

import { BehaviorSubject, Subscription } from 'rxjs';
import { AppStateService, DataTableCache } from '../../app-core/app-state/app-state.service';

export enum FieldFormatEnum {
  Text = 1,
  TextRight = 2,
  TextArea = 3,
  Number = 10,
  Amount = 11,
  Integer = 12,
  DateShort = 20,
  DateLong = 21,
  CheckBox = 30,
  Email = 40,
  Phone = 41,
  Url = 42,
  Chat = 50,
  Chip = 60,
  Custom = 200
}

export enum FieldWidthEnum {
  XXXS = 40,
  XXXPS = 50,
  XXS = 60,
  XS = 70,
  S = 80,
  M = 90,
  L = 100,
  XL = 110,
  XXL = 120,
  XXXL = 130,
  XXXXL = 200
}

export type BaseFieldType = 'id' | 'num' | 'name' | 'sorting' | 'history';
export type DataSourceType = 'datamanager' | 'webapi' | 'custom';
export type PredicateOperator = "in" | "contains" | "equal" | "notequal" | "lessthanorequal" | "greaterthanorequal" | "lessthan" | "greaterthan" | 'startswith';

export class ViewMetadata {
  public name?: string;
  public dataSource?: DataSourceType = 'datamanager';
  public data?: object[];
  public dataApiName?: string;
  public dataApiParams?: string[];
  public numberSeq?: string;
  public primaryKey?: string | string[] = 'id';
  public parentPrimaryKey?: string;
  public parentForeignKey?: string;
  public text?: string;
  public textSingular?: string;
  public textPlural?: string;
  public titleFields?: string[];
  public dialogEdit?: boolean;
  public dialogWidth?: string;
  public allowEdit?: boolean;
  public allowCreate?: boolean;
  public allowDelete?: boolean;
  public allowSorting?: boolean;
  public allowFiltering?: boolean;
  public confirmDelete?: boolean;
  public baseFields?: BaseFieldType[];
  public insertOverride?: any;
}

export class TabMetadata {
  public idx: number;
  public name: string;
  public text: string;
  public icon?: string;
}

export class FieldGroupMetadata {
  public idx?: number;
  public name: string;
  public text: string;
  public tabIdx?: number;
  public width?: number;
  public fields: string[];
}

export class FieldEditChangingArgs {
  cancel: boolean
  field: string;
  fieldMeta: FieldMetadata;
  dataPrevious: any;
  data: any;
  valuePrevious: any;
  value: any;
}

export class FieldEditChangedArgs {
  field: string;
  fieldMeta: FieldMetadata;
  data: any;
  itemData?: any
  value: any;
  previousValue: any;
  args?: any;
}

export class FieldMetadata {
  public name: string;
  public field?: string;
  public text: string;
  public tooltipText?: string;
  public required?: boolean;
  public validationRules?: object;
  public allowEdit?: boolean;
  public allowEditOnCreate?: boolean;
  public allowFiltering?: boolean;
  public allowSearching?: boolean;
  public allowColumnChooser?: boolean;
  public allowClear?: boolean;
  public defaultValue?: any;
  public isIdentity?: boolean;
  public visible?: boolean;
  public visibleEdit?: boolean;
  public visibleAdd?: boolean;
  public width?: string;
  public widthType?: FieldWidthEnum;
  public maxWidth?: string;
  public maxWidthType?: FieldWidthEnum;
  public format?: FieldFormatEnum | [FieldFormatEnum];
  public formatClass?: string;
  public formatter?: ICellFormatter;
  public formatCustom?: string;
  public cellAttributes?: { [x: string]: Object; };
  public template?: string;
  public headerTemplate?: string;
  public editTemplate?: string;
  public foreignKeyData?: any[];
  public foreignKeyTableName?: string;
  public foreignKeyTableFilterName?: string;
  public foreignKeyTableCache?: 'dynamic' | 'nocache';
  public foreignKeyTableParams?: string[];
  public foreignKeyFromNavigation?: boolean;
  public foreignKeyFromNavigationName?: string;
  public foreignKeySubject?: BehaviorSubject<Object[]>;
  public foreignKeyField?: string;
  public foreignKeyValue?: string;
  public foreignKeyColumns?: string[];
  public foreignKeyTitles?: string[];
  public foreignKeySearchOnEmptyFocus?: boolean;
  public foreignKeySearchFields?: string[];
  public foreignKeySearchOperators?: PredicateOperator[];
  public foreignKeySortFields?: string[];
  public foreignKeyAllowFiltering?: boolean;
  public foreignKeyFilterFields?: string[];
  public foreignKeyFilterOperators?: PredicateOperator[];
  public foreignKeyFilterValues?: any[];

  public orderBy?: "none" | "Ascending" | "Descending";
  public orderByPriority?: number;
  public groupBy?: boolean;
  public filterOperator?: PredicateOperator;
  public filterValue?: any;
  public filterOnClient?: boolean;
  public footerType?: "sum" | "Custom";
  public foooterFunction?: CustomSummaryType;
  public footerTemplate?: string;
  public onEditChanging?: (e: FieldEditChangingArgs) => void;
  public onEditChanged?: (e: FieldEditChangedArgs) => void;
  public onQueryCellInfo?: (e: QueryCellInfoEventArgs) => void;
  // State fields when edit is active
  public currentRowVisible?: boolean;
  public currentRowAllowEdit?: boolean;
}

export class FieldMetaForeignKey {

  static dataIsStatic(field: FieldMetadata): boolean {
    return /*(field.foreignKeySubject) || */ (field.foreignKeyTableName?.length > 0 && field.foreignKeyTableCache != 'dynamic');
  }

  static lookupValue(field: FieldMetadata, value: any): any {
    if (field && value) {
      var fkData: any[] = FieldMetaForeignKey.dataGet(field);
      if (fkData?.length > 0) {
        var keyField = FieldMetaForeignKey.keyFieldGet(field);
        var valueField = FieldMetaForeignKey.valueFieldGet(field);
        if (keyField?.length > 0 && valueField?.length > 0) {
          var row: any = fkData.find(row => { return row[keyField] == value });
          if (row) {
            var fkValue: any = row[valueField];
            return fkValue;
          }
        }
      }
    }
    return null;
  }

  static keyFieldGet(field: FieldMetadata): string {
    return field.foreignKeyField ?? 'id';
  }

  static valueFieldGet(field: FieldMetadata): string {
    return field.foreignKeyValue ?? 'name';
  }

  static navigationNameGet(field: FieldMetadata): string {
    return field.foreignKeyFromNavigationName ?? this.name + 'Navigation';
  }

  static dataGet(field: FieldMetadata): any[] {
    return field.foreignKeyData;
  }

  static dataSet(field: FieldMetadata, fkData: any[]) {
    field.foreignKeyData = fkData;
    if (field.foreignKeySubject) {
      field.foreignKeySubject.next(fkData);
    }
  }

  static dataMergeFromNavigationFields(fields: FieldMetadata[], relatedData: any[]) {
    if (relatedData?.length > 0) {
      fields.filter(field => field.foreignKeyFromNavigation === true)
        .forEach(field => FieldMetaForeignKey.dataMergeFromNavigationField(field, relatedData));
    }
  }

  static dataMergeFromNavigationField(field: FieldMetadata, relatedData: any[]) {
    if (relatedData?.length > 0) {
      var navigationName: string = FieldMetaForeignKey.navigationNameGet(field);
      var key: string = FieldMetaForeignKey.keyFieldGet(field);
      if (navigationName?.length > 0 && key?.length > 0) {
        relatedData.forEach(relatedRow => FieldMetaForeignKey.dataMergeRow(field, relatedRow[navigationName], key));
      }
    }
  }

  static dataMergeRow(field: FieldMetadata, row: any, key: string) {
    if (field && row) {
      if (!field.foreignKeyData) {
        field.foreignKeyData = [row];
      }
      else {
        var keyValue: any = row[key];
        if (keyValue) {
          var index = field.foreignKeyData.findIndex(item => item[key] == keyValue);
          if (index == -1) {
            field.foreignKeyData.unshift(row);
          }
          else {
            field.foreignKeyData[index] = row;
          }
        }
      }
    }
  }
}

@Injectable()
export class CerDataService implements OnDestroy {

  // Subscriptions
  private subscriptionManager: Subscription = new Subscription();

  // Metadata
  public viewMetadata: ViewMetadata;
  public fieldMetadata: FieldMetadata[];

  // Foriegn key data load
  private fkDataCountPending: number = 0;
  private fkDataQueueCompleted: boolean = false;
  public fkDataLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public fieldMetadataId: FieldMetadata =
    { name: 'id', text: 'Id', tooltipText: 'Internt id for posten', allowEdit: false, allowEditOnCreate: false, format: FieldFormatEnum.Integer, visible: false, widthType: FieldWidthEnum.S };
  public fieldMetadataNum: FieldMetadata =
    { name: 'num', text: 'Kode', tooltipText: 'Kode for posten', allowEdit: false, allowEditOnCreate: true, required: true, format: FieldFormatEnum.Text, visible: true, widthType: FieldWidthEnum.XS, orderBy: 'Ascending', orderByPriority: 99 };
  public fieldMetadataName: FieldMetadata =
    { name: 'name', text: 'Navn', tooltipText: 'Navn for posten', allowEdit: true, required: true, format: FieldFormatEnum.Text, visible: true, widthType: FieldWidthEnum.M };
  public fieldMetadataSorting: FieldMetadata =
    { name: 'sorting', text: 'Sortering', tooltipText: 'Standard sortering', allowEdit: true, required: true, format: FieldFormatEnum.Integer, visible: true, widthType: FieldWidthEnum.S, orderBy: 'Ascending', orderByPriority: 90 };
  public fieldMetadataHistory: FieldMetadata[] = [
    { name: 'created', text: 'Oprettet', tooltipText: 'Oprettet dato', allowEdit: false, format: FieldFormatEnum.DateLong, visible: false },
    { name: 'modified', text: 'Ændret', tooltipText: 'Ændret dato', allowEdit: false, format: FieldFormatEnum.DateLong, visible: false },
    { name: 'createdBy', text: 'Oprettet af', tooltipText: 'Oprettet af brugernavn', foreignKeyTableName: 'User', foreignKeyValue: "shortName", allowEdit: false, widthType: FieldWidthEnum.S, visible: false },
    { name: 'modifiedBy', text: 'Ændret af', tooltipText: 'Ændret af brugernavn', foreignKeyTableName: 'User', foreignKeyValue: "shortName", allowEdit: false, widthType: FieldWidthEnum.S, visible: false }
  ];

  // Authentication
  public authToken: string;

  // Data
  public dataSource: DataSourceType = 'custom';
  public webApiClient: any;
  public dataManager: DataManager;
  public query: Query;
  public dataManagerUrl: string = '/api/data';
  public data$: BehaviorSubject<Object[]> = new BehaviorSubject<Object[]>(null);
  public parentKeyValue: number = null;

  // Debug
  private debugMode: boolean = false;

  constructor(private appStateService: AppStateService) {
  }

  ngOnDestroy(): void {
    this.subscriptionManager.unsubscribe();
  }

  public setupWebApiClient(client: object) {
    this.dataSource = 'webapi';
    this.webApiClient = client;
  }

  public viewMetadataSetup(viewMetadata: ViewMetadata) {
    if (viewMetadata) {
      this.viewMetadata = viewMetadata;
    }
  }

  public hasBaseFieldId(): boolean {
    return (true || this.viewMetadata?.baseFields?.includes('id'));
  }

  public hasBaseFieldNum(): boolean {
    return (this.viewMetadata?.baseFields?.includes('num'));
  }

  public hasBaseFieldName(): boolean {
    return (this.viewMetadata?.baseFields?.includes('name'));
  }

  public hasBaseFieldSorting(): boolean {
    return (this.viewMetadata?.baseFields?.includes('sorting'));
  }

  public hasBaseFieldHistory(): boolean {
    return (this.viewMetadata?.baseFields?.includes('history'));
  }

  public fieldMetadataSetup(fieldMetadata: FieldMetadata[]) {

    var noTitleFields = ((this.viewMetadata.titleFields?.length ?? 0) == 0);
    if (this.hasBaseFieldName()) {
      fieldMetadata = this.addBaseFields(fieldMetadata, [this.fieldMetadataName]);
      if (noTitleFields) {
        this.viewMetadata.titleFields = (this.viewMetadata.titleFields ?? []);
        this.viewMetadata.titleFields.push('name');
      }
    }
    if (this.hasBaseFieldNum()) {
      if (this.viewMetadata.textSingular != null) {
        var fieldMetadataNumText: FieldMetadata = { ...this.fieldMetadataNum };
        fieldMetadataNumText.text = this.viewMetadata.textSingular;
        fieldMetadataNumText.tooltipText = this.viewMetadata.textSingular + ' unik kode';
        fieldMetadata = this.addBaseFields(fieldMetadata, [fieldMetadataNumText]);
      }
      else {
        fieldMetadata = this.addBaseFields(fieldMetadata, [this.fieldMetadataNum]);
      }
      if (noTitleFields) {
        this.viewMetadata.titleFields = (this.viewMetadata.titleFields ?? []);
        this.viewMetadata.titleFields.push('num');
      }

    }
    if (this.hasBaseFieldId()) {
      fieldMetadata = this.addBaseFields(fieldMetadata, [this.fieldMetadataId]);
    }

    if (this.hasBaseFieldSorting()) {
      fieldMetadata = this.addBaseFields(fieldMetadata, [this.fieldMetadataSorting], 'end');
    }


    if (this.hasBaseFieldHistory()) {
      fieldMetadata = this.addBaseFields(fieldMetadata, this.fieldMetadataHistory, 'end');
    }

    this.fieldMetadata = fieldMetadata;

    if (this.fieldMetadata) {
      // Check if need to await foriegn key data
      this.fieldMetadata.forEach(f => { if (FieldMetaForeignKey.dataIsStatic(f)) { this.fkDataCountPending += 1; } });
    }
  }

  private addBaseFields(fieldMetadata: FieldMetadata[], baseFieldMetadata: FieldMetadata[], position: 'start' | 'end' = 'start') {
    for (var baseField of baseFieldMetadata) {
      var found: boolean = false;
      if (baseField.name) {
        fieldMetadata.filter(f => f.name == baseField.name).forEach(
          f => {
            //var visible = f.visible;
            //f = baseField;
            //f.visible = visible;
            found = true;
          }
        );
      }
      if (!found) {
        if (position == 'start') {
          fieldMetadata = [baseField].concat(fieldMetadata);
        }
        else {
          fieldMetadata = fieldMetadata.concat(baseField);
        }
      }
    }
    return fieldMetadata;
  }

  public setupPost() {
    this.fkDataQueueCompleted = true;
    this.fkDataLoadedNotify();
  }

  public fkDataPending() {
    this.fkDataCountPending++;
  }

  public fkDataLoaded() {
    this.fkDataCountPending--;
    this.fkDataLoadedNotify();
  }

  private fkDataLoadedNotify() {
    if (this.fkDataQueueCompleted && this.fkDataCountPending == 0) {
      this.fkDataLoaded$.next(true);
    }
  }

  public fkDataManagerSetup(fieldMetadata: FieldMetadata): DataManager {
    var dataManager: DataManager = null;
    // true: Small static tables are fetched locally.
    // false: For larger dynamic datasets the datamanager queries remote data as needed.

    if (fieldMetadata && fieldMetadata.foreignKeyTableName && fieldMetadata.foreignKeyTableName.length > 0) {
      var isStatic: boolean = (fieldMetadata.foreignKeyTableCache != 'dynamic');
      var isCached: boolean = (isStatic) && (fieldMetadata.foreignKeyTableCache != 'nocache' && !fieldMetadata.foreignKeyTableParams);
      var cache: DataTableCache = null;

      var createWebApiQuery: boolean = true;

      if (isCached) {
        // Small static tables are fetched locally (can can be cached (not implemented yet))
        cache = this.appStateService.getDataTableCache(fieldMetadata.foreignKeyTableName, fieldMetadata.foreignKeyTableFilterName);
        if (cache.loaded || cache.pending) {
          createWebApiQuery = false;
          dataManager = new DataManager(cache.data);
          FieldMetaForeignKey.dataSet(fieldMetadata, cache.data);
          this.fkDataLoaded();
        }
        else {
          cache.pending = true;
        }
      }
      if (createWebApiQuery) {
        dataManager = this.fkDataManagerWebApi(fieldMetadata, cache);
      }
    }
    return dataManager;
  }

  public fkDataManagerWebApi(fieldMetadata: FieldMetadata, cache: DataTableCache): DataManager {
    var dataManager: DataManager = new DataManager({
      url: this.dataManagerUrl + '/' + fieldMetadata.foreignKeyTableName,
      adaptor: new WebApiAdaptor,
      headers: [
        { Authorization: 'Bearer ' + this.authToken },
        { 'ValidationErrorsRespond200': 'true' },
        { ValidationErrorsRespond200: 'true' }
      ]
    });
    if (cache) {
      var dataManagerExec: DataManager = dataManager;
      var fkData: Object[] = !cache.loaded ? cache.data : new Array<Object>();
      fieldMetadata.foreignKeyData = fkData;
      dataManager = new DataManager(fkData);

      var query = new Query();
      if (fieldMetadata.foreignKeyTableParams) {
        fieldMetadata.foreignKeyTableParams.forEach(p => {
          var parms: string[] = p.split('=');
          query.addParams(parms[0], parms.length > 1 ? parms[1] : 'true')
        });
      }
      var predicates: Predicate = this.fkFilterPredicates(fieldMetadata);
      if (predicates) {
        query.where(predicates);
      }
      if (fieldMetadata.foreignKeySortFields) {
        query.sortBy(fieldMetadata.foreignKeySortFields);
      }
      dataManagerExec.executeQuery(query).then(
        e => {
          var returnOption: ReturnOption = e;
          if (returnOption && returnOption.result) {
            if ((<any>returnOption.result).errors) {
              this.appStateService.handleApiError({ error: returnOption.result });
              return;
            }

            (<Object[]>returnOption.result).forEach(
              row => fkData.push(row)
            );
            FieldMetaForeignKey.dataSet(fieldMetadata, fkData);
            if (cache) {
              cache.pending = false;
              cache.loaded = true;
            }
          }
          this.fkDataLoaded();
        });
    }
    return dataManager;
  }

  public fkQuery(fieldMetadata: FieldMetadata): Query {
    var query: Query = new Query();
    var predicates: Predicate = this.fkFilterPredicates(fieldMetadata);
    if (predicates) {
      query.where(predicates);
    }
    return query;
  }

  public fkFilterPredicates(fieldMetadata: FieldMetadata): Predicate {
    var predicate: Predicate;
    var filterFieldCount = fieldMetadata.foreignKeyFilterFields?.length;
    if (filterFieldCount > 0) {
      var filterValueCount = fieldMetadata.foreignKeyFilterValues?.length;
      if (filterFieldCount == filterValueCount) {
        var filterOperatorCount = fieldMetadata.foreignKeyFilterOperators?.length ?? 0;
        if (filterOperatorCount == 0) {
          fieldMetadata.foreignKeyFilterOperators = new Array(filterFieldCount).fill('equal');
        }
        if (filterOperatorCount == 1 && filterFieldCount != filterOperatorCount) {
          fieldMetadata.foreignKeyFilterOperators = new Array(filterFieldCount).fill(fieldMetadata.foreignKeyFilterOperators[0]);
        }
        for (let i = 0; i < filterFieldCount; i++) {
          var predicateFilter: Predicate = new Predicate(fieldMetadata.foreignKeyFilterFields[i], fieldMetadata.foreignKeyFilterOperators[i], fieldMetadata.foreignKeyFilterValues[i]);
          predicate = (predicate) ? new Predicate(predicate, 'and', predicateFilter) : predicateFilter;
        }
      }
    }
    return predicate;
  }

  public viewTextSingular(genericTextFallback: boolean): string {
    var text: string = genericTextFallback ? "Post" : "";

    if (this.viewMetadata) {
      if (this.viewMetadata.textSingular) {
        text = this.viewMetadata.textSingular;
      }
      else if (this.viewMetadata.text) {
        text = this.viewMetadata.text;
      }
      else {
        text = this.viewMetadata.name;
      }
    }

    return text;
  }

  public viewDataTitle(rowData: any): string {
    var dataTitle: string = '';

    if (this.viewMetadata) {
      if (this.viewMetadata.titleFields?.length > 0) {
        this.viewMetadata.titleFields.forEach(fieldName => {
          var value = this.fieldValueGetDisplayValue(rowData, fieldName);
          if (value) {
            dataTitle += (dataTitle.length > 0 ? ', ' : '') + value;
          }
        });
      }
      else if (this.viewMetadata.primaryKey) {
        if (Array.isArray(this.viewMetadata.primaryKey)) {
          this.viewMetadata.primaryKey.forEach(fieldName => {
            var value = this.fieldValueGetDisplayValue(rowData, fieldName);
            if (value) {
              dataTitle += (dataTitle.length > 0 ? ', ' : '') + value;
            }
          });
        }
        else {
          dataTitle = this.fieldValueGetDisplayValue(rowData, <string>this.viewMetadata.primaryKey);
        }
      }
    }
    return dataTitle;
  }

  public fieldValueGetDisplayValue(row: any, fieldName: string): string {
    var displayValue: string = '';
    if (row && fieldName?.length > 0) {
      var fieldValue: any = row[fieldName];
      if (fieldValue) {
        displayValue = fieldValue;
        var fieldMetadata: FieldMetadata = this.fieldMetadata.find(f => { return f.name == fieldName });
        if (fieldMetadata) {
          displayValue = FieldMetaForeignKey.lookupValue(fieldMetadata, fieldValue);
        }
        if (!displayValue) {
          displayValue = fieldValue.toString();
        }
      }
    }
    return displayValue;
  }

  /*
  protected fieldValueGetString(data: any, fieldName: string): string {
    var value: string = '';
    if (data && fieldName?.length > 0 && data[fieldName]) {
      value = data[fieldName];
      if (value) {
        value = this.fieldValueGetDisplayValue(fieldName, value);
      }
    }
    return value;
  }
  */

  public dataManagerSetup() {
    if (this.viewMetadata && (this.viewMetadata.dataSource == null || this.viewMetadata.dataSource == 'datamanager')) {
      this.dataSource = 'datamanager';

      if (this.viewMetadata.dataApiName && this.viewMetadata.dataApiName.length > 0) {
        this.dataManagerSetupApi()
      }
      else if (this.viewMetadata.data) {
        this.dataManagerSetupData()
      }
      if (this.viewMetadata.name) {
        //this.query.fromTable = this.viewMetadata.apiName;
        this.query = new Query();

        if (this.viewMetadata.dataApiParams) {
          this.viewMetadata.dataApiParams.forEach(p => {
            var parms: string[] = p.split('=');
            this.query.addParams(parms[0], parms.length > 1 ? parms[1] : 'true')
          });
        }

        if (this.fieldMetadata) {
          this.fieldMetadata.filter(f => (f.filterOperator && f.filterValue !== undefined && f.allowFiltering !== false && f.filterOnClient !== true))
            .forEach(f => this.queryWhereAdd(f.name, f.filterOperator, f.filterValue))
        }
      }
    }
    else {
      this.dataSource = this.viewMetadata?.dataSource ?? 'custom';
    }
  }

  protected dataManagerSetupApi() {
    var apiTableName: string = this.viewMetadata.dataApiName;
    this.dataManager = new DataManager({
      url: this.dataManagerUrl + '/' + apiTableName,
      adaptor: new WebApiAdaptor,
      headers: [
        { Authorization: 'Bearer ' + this.authToken }
      ]
    });
    if (this.viewMetadata.insertOverride) {
      this.dataManager.insert = this.viewMetadata.insertOverride;
    }
  }

  private getRowsTotal(): number {
    return 100;
  }

  private dataManagerSetupData() {
    this.dataManager = new DataManager(this.viewMetadata.data);
  }

  public refreshFromParentData(data: any): boolean {
    var needRefresh = false;
    if (this.viewMetadata && this.viewMetadata.parentForeignKey && this.viewMetadata.parentPrimaryKey) {
      var parentKeyValue: any = null;
      if (data) {
        parentKeyValue = <any>data[this.viewMetadata.parentPrimaryKey];
      }
      if (parentKeyValue == null) {
        parentKeyValue = -1;
      }

      //     if (parentKeyValue != this.parentKeyValue || !this.parentKeyValue) { // TODO: Optimize: Needed cause refresh on parent
      this.debug("Parent id = " + parentKeyValue);
      if (parentKeyValue) {
        this.parentKeyValue = parentKeyValue;
        var whereFieldName: string = this.viewMetadata.parentForeignKey
        if (whereFieldName.startsWith("$")) {
          this.apiParamAdd(whereFieldName, parentKeyValue);
        }
        else {
          var whereOperator: string = 'equal';
          this.queryWhereAdd(whereFieldName, whereOperator, parentKeyValue);
        }
        needRefresh = true;
      }
    }
    return needRefresh;
  }

  protected debug(s: string) {
    if (this.debugMode) {
      console.log(s);
    }
  }

  public setDebugMode(active: boolean) {
    if (active || this.debugMode) {

      this.apiParamAdd("$debug", active);
    }
    this.debugMode = active;
  }

  protected apiParamAdd(key: string, value: any) {
    if (this.query) {
      if (key !== null && key.length > 0) {
        var found: boolean = false;
        this.query.params.forEach(p => {
          if (p.key == key) {
            p.value = value;
            found = true;
          }
        });
        if (!found) {
          this.query.addParams(key, value);
        }
      }
    }

  }

  public queryWhereAdd(fieldName: string, operator: string, filterValue: any) {
    this.query.queries = this.query.queries
      .filter(p => !this.isQueryField(p, 'onWhere', fieldName, operator));  // Remove existing query predicate
    if (operator == 'in') {
      if (filterValue && filterValue.length > 0) {
        var predicate: Predicate = new Predicate(fieldName, 'equal', filterValue[0]);
        for (var i = 1; i < filterValue.length; i++) {  
          predicate = predicate.or(new Predicate(fieldName, 'equal', filterValue[i]));
        }
        this.query.where(predicate);
      }
    }
    else {
      this.query.where(fieldName, operator, filterValue);
    }
  }

  private isQueryField(query: any, fn: any, fieldName: any, operator: any): boolean {
    var found: boolean = false;

    if (query.fn == fn) {
      if (query.fieldName == fieldName) {
        found = true;
      }
      else if (query.e && query.e.field == fieldName && query.e.operator == operator) {
        found = true;
      }
    }
    return found;
  }

  // Future
  private dataGet() {
    switch (this.dataSource) {
      case 'webapi':
        if (this.webApiClient) {
          this.webApiClient.get().subscribe((data: any) => this.data$.next(data));
        }
        break;
      case 'datamanager':
        if (this.dataManager) {
          //          this.dataManager.executeQuery(new Query().then(data => this.dataPublish(data));
        }
        break;
      case 'custom':
        break;
    }
  }

  // Future
  private research() {
    this.dataGet();
  }

}

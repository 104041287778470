<div style="height: 100%; width: 100%; display: flex; flex-direction: column">
  <div
    style="
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    "
  >
    <ejs-richtexteditor
      id="defaultRTE"
      style="max-height: 100%; height: 100%; width: 100%; overflow: hidden"
      height="100%"
      width="100%"
      [toolbarSettings]="tools"
      placeholder="Angiv tekst"
      (created)="create($event)"
      (actionBegin)="actionBegin($event)"
    >
      <ng-template #valueTemplate></ng-template>
    </ejs-richtexteditor>
  </div>
  <div style="flex-grow: 0">
    <mat-dialog-actions>
      <button
        mat-button
        mat-raised-button
        color="primary"
        (click)="saveAndclose()"
      >
        <i class="material-icons">done</i>Gem og luk
      </button>
      <button
        mat-button
        mat-raised-button
        (click)="close(false)"
        title="Annuller"
      >
        <i class="material-icons">clear</i>Annuller
      </button>
    </mat-dialog-actions>
  </div>
</div>

import { Injectable, OnDestroy } from '@angular/core';
import { ApplicationInsights, IExceptionTelemetry, DistributedTracingModes } from '@microsoft/applicationinsights-web';
import { AppUser, UserSessionService } from '../../platform/app-user/user-session-service';
import { CerAppMetaService } from 'src/cer-app/cer-app-meta/cer-app-meta.service';
import { CerAppRouteService, } from 'src/cer-app/cer-app-route/cer-app-route.service';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CerAppApplicationInsightsService implements OnDestroy {
  private subscriptionManager$ = new Subscription();
  private appInsights: ApplicationInsights = null;

  private applicationName: string = '';
  private userId: number = null;
  private userName: string = ''

  constructor(private routeService: CerAppRouteService, private metaService: CerAppMetaService, userSessionService: UserSessionService) {

    this.subscriptionManager$.add(
      userSessionService.user$.subscribe(user => this.setupAppInsightsFromUserSettings(user))
    );

    this.subscriptionManager$.add(
      this.metaService.title$.subscribe(applicationName => this.applicationName = applicationName)
    );
  }

  private subscribeActiveUserRoute() {
    this.subscriptionManager$.add(
      this.routeService.activeUserRoute$.subscribe(route => this.logPageView(route.routeUrl, route.routeUrl))
    );
  }

  public ngOnDestroy(): void {
    this.subscriptionManager$.unsubscribe();
  }

  private setupAppInsightsFromUserSettings(appUser: AppUser) {
    if (appUser) {
      this.userId = appUser.dto?.id;
      this.userName = appUser.dto?.shortName;

      if (!this.appInsights) {
        const settings = appUser.vm.settings.settings;
        if (settings) {
          const connStr: string = settings['AppInsightsConnectionString'];
          if (connStr && connStr.length > 0) {
            this.startAppInsights(connStr, appUser.sessionUser.access_token);
          }
        }
      }
      else {

      }
    }
  }

  private startAppInsights(connectionString: string, oidcUserId: string) {

    this.appInsights = new ApplicationInsights({
      config: {
        connectionString: connectionString,
        enableCorsCorrelation: true
      }
    });

    this.appInsights.loadAppInsights();
    this.appInsights.setAuthenticatedUserContext(oidcUserId);
    this.appInsights.addTelemetryInitializer((envelope : any) => {
      var item = envelope.baseData;
      item.properties = item.properties || {};
      item.properties["ApplicationPlatform"] = "Browser";
      item.properties["ApplicationName"] = this.applicationName;
      item.properties["UserId"] = this.userId;
      item.properties["UserName"] = this.userName;
    });
    this.subscribeActiveUserRoute();
  }

  private clearUserId() {
    if (this.appInsights) {
      this.appInsights.clearAuthenticatedUserContext();
    }
  }

  logPageView(name?: string, uri?: string) {
    if (this.appInsights) {
      this.appInsights.trackPageView({ name, uri });
    }
    else {
      console.log((uri ?? '') + ' ' + (name ?? ''));
    }
  }

  logException(error: Error) {
    if (this.appInsights) {
      let exception: IExceptionTelemetry = {
        exception: error
      };
      this.appInsights.trackException(exception);
    }
    else {
      console.error(error);
    }
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    if (this.appInsights) {
      this.appInsights.trackTrace({ message: message }, properties);
    }
    else {
      console.log(message);
    }
  }
}
